import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import userService from '../../../../services/userService';
import useApiCall from '../../../../hooks/useApiCall';

interface UseEditContactInterface {
  getUserData: () => void;
}

interface EditContactInterface {
  name?: string;
  cellphone?: string;
  email?: string;
}

export default function useEditContact({
  getUserData,
}: UseEditContactInterface) {
  const [editContactCardModalShow, setEditContactCardModalShow] = useState(false);
  const [isEditting, setIsEditting] = useState(false);

  const { apiCall } = useApiCall();

  const editContact = useCallback(async ({
    name, cellphone, email,
  }: EditContactInterface) => {
    await apiCall({
      apiToCall: userService.editContactDetails,
      reqBody: JSON.stringify({
        contato_nome: name,
        contato_celular: cellphone,
        contato_email: email,
      }),
      onStartLoad: () => setIsEditting(true),
      onEndLoad: () => setIsEditting(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível editar os dados do contato');
          setIsEditting(false);
          return;
        }
        setEditContactCardModalShow(false);
        getUserData();
        toast.success('Dados do contato atualizados com sucesso!');
      },
      catchMessage: 'Não foi possível editar os dados do contato'
    });
  }, [apiCall, getUserData]);

  return {
    isEditting,
    editContactCardModalShow,
    setEditContactCardModalShow,
    editContact,
  };
}
