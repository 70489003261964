/* eslint-disable max-len */
import { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import { Container, Info } from './styles';
import FilterRadioButton from '../../components/FilterRadioButtons';
import kiddleLogo from '../../assets/images/icons/kiddleLogo.svg';

export default function Files() {
  const [selectedMaterial, setSelectedMaterial] = useState('manual');
  return (
    <>
      <Sidebar active="Files" />
      <Transitions>
        <Container>
          <h1>Documentos auxiliares</h1>

          <div className="selectionButtons">
            <FilterRadioButton
              sameWidth
              onClick={() => setSelectedMaterial('manual')}
              selected={selectedMaterial === 'manual'}
            >
              Manual dos parceiros
            </FilterRadioButton>
            <FilterRadioButton
              sameWidth
              onClick={() => setSelectedMaterial('kiddleData')}
              selected={selectedMaterial === 'kiddleData'}
            >
              Dados Gerais e de cobrança
            </FilterRadioButton>
          </div>

          {selectedMaterial === 'manual' && (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1, type: 'tween', stiffness: 10000 }}
            >
              <Info>
                <img src={kiddleLogo} alt="" style={{ width: 200 }} />
                <div>
                  <span>
                  Neste manual você encontrará orientações fundamentais para iniciar e gerenciar suas atividades conosco. Obrigado por fazer parte do nosso Mundo, nós esperamos que esse material o suporte, mas caso tenham dúvidas adicionais e/ou sugestões nosso canal sempre estará aberto a vocês.
                  </span>

                  <Link to="/files/Manual Parceiros KP 2023.pdf" target="_blank" download>
                  Baixar Material (.pdf)
                  </Link>
                </div>
              </Info>
            </motion.div>
          )}

          {selectedMaterial === 'kiddleData' && (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1, type: 'tween', stiffness: 10000 }}
            >
              <Info>
                <img src={kiddleLogo} alt="" style={{ width: 200 }} />
                <div>
                  <span>
                  Baixe este documento para ter acesso aos nossos dados gerais e bancários que podem ser utilizados para realizar eventuais pagamentos, e checar dados como nosso CNPJ, razão social, e endereço!
                  </span>

                  <Link to="/files/Dados Cobrança KP.pdf" target="_blank" download>
                  Baixar Material (.pdf)
                  </Link>
                </div>
              </Info>
            </motion.div>
          )}

        </Container>
      </Transitions>
    </>
  );
}
