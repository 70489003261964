import { ChangeEvent, useState } from 'react';
import isEmailValid from '../../../../utils/isEmailValid';
import useErrors from '../../../../hooks/useErrors';
import formatPhone from '../../../../utils/formatPhone';
import { ContactDetailsType } from '../../types';

export default function useContactDetailsCard(contactDetails: ContactDetailsType) {
  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const [contactName, setContactName] = useState(contactDetails.name);
  const [contactCellphone, setContactCellphone] = useState(contactDetails?.phone);
  const [contactEmail, setContactEmail] = useState(contactDetails.email);
  const isReadyToEdit = !!((contactName && contactCellphone && contactEmail) && errors.length === 0);

  function handleContactNameChange(event: ChangeEvent<HTMLInputElement>) {
    setContactName(event.target.value);

    if (!event.target.value) {
      setError({ field: 'contactName', message: 'Nome é obrigatório!' });
    } else {
      removeError('contactName');
    }
  }

  function handleContactCellphoneChange(event: ChangeEvent<HTMLInputElement>) {
    setContactCellphone(formatPhone(event.target.value));

    if (event.target.value.length < 14) {
      setError({ field: 'contactCellphone', message: 'Celular inválido!' });
    } else {
      removeError('contactCellphone');
    }
  }

  function handleContactEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setContactEmail(event.target.value);

    if (!isEmailValid(event.target.value)) {
      setError({ field: 'contactEmail', message: 'E-mail inválido!' });
    } else {
      removeError('contactEmail');
    }
  }

  return {
    getErrorMessageByFieldName,
    contactName,
    handleContactNameChange,
    contactCellphone,
    handleContactCellphoneChange,
    contactEmail,
    handleContactEmailChange,
    isReadyToEdit,
  };
}
