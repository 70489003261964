import NoData from '../../../../../components/NoData';
import { Container, FileContainer } from '../../styles';

interface PicturesCardInterface {
  imagePath: string;
}

export default function PicturesCard({ imagePath }: PicturesCardInterface) {
  return (
    <>
      {imagePath && (
        <FileContainer>
          <div className="card-title">
            Foto
          </div>
          <img src={imagePath} alt="activity thumbnail" className="file" />
        </FileContainer>
      )}

      {!imagePath && (
        <Container>
          <div className="card-title">
            Foto
          </div>
          <NoData
            icon="emptyBox"
            label="Não há nenhuma foto"
          />
        </Container>
      )}
    </>
  );
}
