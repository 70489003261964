import useEditContact from './functions/useEditContact';
import useLoadRegistrationData from './functions/useLoadRegistrationData';

export default function useRegistrationData() {
  const {
    isLoadLoading,
    doesApiHasError,
    userData,
    getUserData,
  } = useLoadRegistrationData();

  const {
    isEditting,
    editContactCardModalShow,
    setEditContactCardModalShow,
    editContact,
  } = useEditContact({
    getUserData,
  });

  const isLoading = isLoadLoading || isEditting;

  function handleTryAgain() {
    getUserData();
  }

  return {
    isLoading,
    doesApiHasError,
    userData,
    handleTryAgain,
    editContactCardModalShow,
    setEditContactCardModalShow,
    editContact,
    getUserData
  };
}
