import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface IGivenClassesService {
  id?: string;
  reqBody?: ReqBodyType;
  month?: number;
  year?: number;
  token: string;
}

class GivenClassesService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getGivenClassesByPeriod = async ({
    token,
    month,
    year,
  }: IGivenClassesService) => {
    return this.httpClient.get({
      path: `/given-classes?month=${month}&year=${year}`,
      token,
    });
  };

  downloadPdfRelatoryByPartner = async ({
    token,
    month,
    year,
  }: IGivenClassesService) => {
    return this.httpClient.get({
      path: `/given-classes/relatory?month=${month}&year=${year}`,
      token,
    });
  };

  createGivenClass = async ({
    token,
    reqBody,
  }: IGivenClassesService) => {
    return this.httpClient.post({
      token,
      path: '/given-classes',
      reqBody,
    });
  };
}

export default new GivenClassesService();
