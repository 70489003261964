import MyModal from '../../../../../../components/Modal';
import { Dispatch, SetStateAction } from 'react';
import formatDate from '../../../../../../utils/formatDate';

interface RehabilitateModalInterface {
  rehabilitateDateModalShow: boolean;
  selectedRehabilitateDate: string;
  setRehabilitateDateModalShow: Dispatch<SetStateAction<boolean>>;
  rehabilitateDate: () => void;
}

export default function RehabilitateModal({
  rehabilitateDateModalShow,
  selectedRehabilitateDate,
  setRehabilitateDateModalShow,
  rehabilitateDate,
}: RehabilitateModalInterface) {
  let message;
  let title;

  if (selectedRehabilitateDate.includes('_')) {
    const [date, time] = selectedRehabilitateDate.split('_');
    const formattedDate = formatDate(date);
    message = `Tem certeza que deseja reabilitar o horário ${time} no dia ${formattedDate}?`;
    title = `Reabilitar horário ${time} no dia ${formattedDate}`;
  } else {
    const formattedDate = formatDate(selectedRehabilitateDate);
    message = `Tem certeza que deseja reabilitar as aulas do dia ${formattedDate}?`;
    title = `Reabilitar dia ${formattedDate}`;
  }
  return (
    <MyModal
      show={rehabilitateDateModalShow}
      title={title}
      modalBody={message}
      closeButtonLabel="Fechar"
      actionButtonLabel="Reabilitar"
      onClose={() => setRehabilitateDateModalShow(false)}
      onAction={() => rehabilitateDate()}
      type="action"
    />
  );
}
