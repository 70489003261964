import { PostType } from '../../types';
import Content from './components/Content';
import Header from './components/Header';
import { Container } from './styles';

interface IPost {
  post: PostType;
  handleOpenCommentsModal: () => void;
  handleOpenEditModal: () => void;
  handleOpenDeleteModal: () => void;
}

export default function Post({ post, handleOpenCommentsModal, handleOpenEditModal, handleOpenDeleteModal }: IPost) {
  const { text, file, comments, fileType } = post;
  return (
    <Container>
      <Header
        user={post.user}
        createdAt={post.createdAt}
        commentsQty={post.comments?.length ?? 0}
        handleOpenCommentsModal={() => handleOpenCommentsModal()}
        handleOpenEditModal={() => handleOpenEditModal()}
        handleOpenDeleteModal={() => handleOpenDeleteModal()}
      />

      <Content text={text} file={file} fileType={fileType} comments={comments} />
    </Container>
  );
}
