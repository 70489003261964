import { useState, useCallback, ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import useErrors from '../../../../../hooks/useErrors';
import activitiesService from '../../../../../services/activitiesService';
import { useAppContext } from '../../../../../contexts/auth';

interface UseGeneralDataCardInterface {
  activityId?: string;
  activityName?: string;
  reloadActivity: () => void;
  generalData?: {
    category: string,
    subcategory: string,
    kindOfClass: string,
    link: string,
    description: string,
    methodology: string,
    orientations: string,
  };
}

export default function useGeneralDataCard({
  activityId,
  activityName,
  reloadActivity,
  generalData,
}: UseGeneralDataCardInterface) {
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionModalShow, setDescriptionModalShow] = useState(false);
  const [description, setDescription] = useState(generalData!.description);

  const { token, signOut } = useAppContext();

  const {
    setError, removeError, getErrorMessageByFieldName,
  } = useErrors();

  const changeDesciption = useCallback(async () => {
    try {
      setIsLoading(true);
      const bodyEdittedDescription = await activitiesService.updateActivity(
        {
          activityId,
          reqBody: JSON.stringify({
            nome: activityName,
            descricao: description,
          }),
          token,
        },
      );
      if (bodyEdittedDescription === 'unauthorized') {
        signOut();
        return;
      }
      if (!bodyEdittedDescription.success) {
        toast.error('Não foi possível alterar a descrição');
        setIsLoading(false);
        return;
      }
      setDescriptionModalShow(false);
      await reloadActivity();
      toast.success('A alteração da descrição foi enviada para análise!');
    } catch (error) {
      toast.error(`Não foi possível alterar a descrição (${error})`);
    } finally {
      setIsLoading(false);
    }
  }, [activityId, activityName, description, token, reloadActivity, signOut]);

  function handleDescriptionChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setDescription(event.target.value);

    if (!event.target.value) {
      setError({ field: 'description', message: 'Insira uma descrição!' });
    } else {
      removeError('description');
    }
  }

  return {
    isLoading,
    setDescriptionModalShow,
    descriptionModalShow,
    getErrorMessageByFieldName,
    description,
    handleDescriptionChange,
    changeDesciption,
  };
}
