import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Container as StyledContainer } from '../../styles';
import MyModal from '../../../../../components/Modal';
import TimesList from './components/TimesList';
import NoData from '../../../../../components/NoData';
import DayCard from './components/DayCard';
import { TimetableFromApiType } from '../../../types/TimetableFromApiType';
import toPersistenceTimetable from '../../../utils/toPersistenceTimetable';

type ClassesInfoType = {
  startTime: string;
  endTime: string;
  vacanciesQty: number;
};

type DayInfoBeingShowType = {
  day?: string;
  dayEn?: string;
  list?: ClassesInfoType[];
}

interface TimetableCardInterface {
  timetable: TimetableFromApiType;
}

export default function TimetableCard({ timetable }: TimetableCardInterface) {
  const [dayTimesModalShow, setDayTimesModalShow] = useState(false);
  const [dayInfoBeingShow, setDayInfoBeingShow] = useState<DayInfoBeingShowType>({});

  const translatedTimetable = toPersistenceTimetable(timetable);

  const mondayClasses = translatedTimetable.monday;
  const quantityOfMondayClasses = mondayClasses.length;

  const tuesdayClasses = translatedTimetable.tuesday;
  const quantityOfTuesdayClasses = tuesdayClasses.length;

  const wednesdayClasses = translatedTimetable.wednesday;
  const quantityOfWednesdayClasses = wednesdayClasses.length;

  const thursdayClasses = translatedTimetable.thursday;
  const quantityOfThursdayClasses = thursdayClasses.length;

  const fridayClasses = translatedTimetable.friday;
  const quantityOfFridayClasses = fridayClasses.length;

  const saturdayClasses = translatedTimetable.saturday;
  const quantityOfSaturdayClasses = saturdayClasses.length;

  const sundayClasses = translatedTimetable.sunday;
  const quantityOfSundayClasses = sundayClasses.length;

  const hasClasses = (mondayClasses
    || tuesdayClasses
    || wednesdayClasses
    || thursdayClasses
    || fridayClasses
    || saturdayClasses
    || sundayClasses);

  return (
    <>
      <StyledContainer>
        <div className="card-title">
          Grade horária
        </div>
        <Container>
          <Row xs={1} md={2} lg={3}>
            {!!(quantityOfMondayClasses) && (
              <Col>
                <DayCard
                  day="Segunda-feira"
                  quantityOfClasses={quantityOfMondayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                  classes={mondayClasses}
                />
              </Col>
            )}
            {!!(quantityOfTuesdayClasses) && (
              <Col>
                <DayCard
                  day="Terça-feira"
                  quantityOfClasses={quantityOfTuesdayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                  classes={tuesdayClasses}
                />
              </Col>
            )}
            {!!(quantityOfWednesdayClasses) && (
              <Col>
                <DayCard
                  day="Quarta-feira"
                  quantityOfClasses={quantityOfWednesdayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                  classes={wednesdayClasses}
                />
              </Col>
            )}
            {!!(quantityOfThursdayClasses) && (
              <Col>
                <DayCard
                  day="Quinta-feira"
                  quantityOfClasses={quantityOfThursdayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                  classes={thursdayClasses}
                />
              </Col>
            )}
            {!!(quantityOfFridayClasses) && (
              <Col>
                <DayCard
                  day="Sexta-feira"
                  quantityOfClasses={quantityOfFridayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                  classes={fridayClasses}
                />
              </Col>
            )}
            {!!(quantityOfSaturdayClasses) && (
              <Col>
                <DayCard
                  day="Sábado"
                  quantityOfClasses={quantityOfSaturdayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                  classes={saturdayClasses}
                />
              </Col>
            )}
            {!!(quantityOfSundayClasses) && (
              <Col>
                <DayCard
                  day="Domingo"
                  quantityOfClasses={quantityOfSundayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                  classes={sundayClasses}
                />
              </Col>
            )}
            {!hasClasses && (
              <NoData
                icon="emptyBox"
                label={(
                  <>
                    Essa atividade não possui uma grade horária definida
                  </>
                )}
              />
            )}
          </Row>
        </Container>
      </StyledContainer>

      <MyModal
        show={dayTimesModalShow}
        onClose={() => setDayTimesModalShow(false)}
        title={`Horários de ${dayInfoBeingShow.day}`}
        modalBody={(
          <TimesList
            list={dayInfoBeingShow.list!}
          />
        )}
        closeButtonLabel="Fechar"
        size='lg'
      />
    </>
  );
}
