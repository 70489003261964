import { parse, startOfDay } from 'date-fns';
import { motion } from 'framer-motion';
import { Dispatch, SetStateAction } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import classLink from '../../../../assets/images/icons/classLink.svg';
import kidsIcon from '../../../../assets/images/icons/kids.svg';
import { IJoinClass, ScheduleKidsListBeingShowType, SchedulesType } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: SchedulesType | [];
  setKidsModalShow: Dispatch<SetStateAction<boolean>>;
  setScheduleKidsListBeingShow: Dispatch<SetStateAction<ScheduleKidsListBeingShowType>>;
  joinClass: (params: IJoinClass) => Promise<void>;
  dateBeingShown: string;
}

export default function List({
  filteredList,
  setKidsModalShow,
  setScheduleKidsListBeingShow,
  joinClass,
  dateBeingShown,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((appointment) => (
          <Col key={appointment.id}>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: false }}
              transition={{ delay: 0.1, type: 'tween', stiffness: 10000 }}
            >
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{appointment.atividade}</strong>
                    <small>{appointment.horario}</small>
                  </div>
                  <span>
                    {appointment.criancas.length}
                    {' '}
                    {appointment.criancas.length == 1 ? 'criança' : 'crianças'}
                  </span>
                </div>
                <div className="actions">
                  <button
                    type="button"
                    onClick={() => {
                      setKidsModalShow(true);
                      setScheduleKidsListBeingShow({
                        title: `${appointment.atividade} - ${appointment.horario}`,
                        list: appointment.criancas,
                      });
                    }}
                  >
                    <img src={kidsIcon} alt="kidsList" title="Visualizar lista de crianças" className="primaryColor" />
                  </button>
                  <button
                    type='button'
                    onClick={() => joinClass({
                      date: parse(dateBeingShown!, 'dd/MM/yyyy', startOfDay(new Date())),
                      activityId: appointment.atividadeId,
                      startTime: appointment.horario.split('-')[0],
                      endTime: appointment.horario.split('-')[1],
                      zoomUrl: appointment.link,
                    })}
                  >
                    <img src={classLink} alt="linkAulaZoom" title="Acessar aula" />
                  </button>
                </div>
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
