import { Container, Group } from '../../styles';
import edit from '../../../../assets/images/icons/edit.svg';
import useContactDetailsCard from './useContactDetailsCard';
import EditContactModal from './components/EditContactModal';
import { ContactDetailsType } from '../../types';
import { Dispatch, SetStateAction } from 'react';

interface EditContactInterface {
  name?: string,
  cellphone?: string,
  email?: string,
}

interface ContactDetailsCardInterface {
  contactDetails: ContactDetailsType;
  setEditContactCardModalShow: Dispatch<SetStateAction<boolean>>;
  editContactCardModalShow: boolean;
  editContact: ({
    name ,cellphone, email
  }: EditContactInterface) => void;
}

export default function ContactDetailsCard({
  contactDetails,
  setEditContactCardModalShow,
  editContactCardModalShow,
  editContact,
}: ContactDetailsCardInterface) {
  const {
    getErrorMessageByFieldName,
    contactName,
    handleContactNameChange,
    contactCellphone,
    handleContactCellphoneChange,
    contactEmail,
    handleContactEmailChange,
    isReadyToEdit,
  } = useContactDetailsCard(contactDetails);

  return (
    <>
      <Container>
        <div className="card-title">
          Dados de contato
          <img src={edit} alt="edit" onClick={() => setEditContactCardModalShow(true)} />
        </div>
        <Group>
          <div className="title">
            Nome
          </div>
          <span>
            {contactDetails.name || 'Não informado'}
          </span>
        </Group>
        <Group>
          <div className="title">
            Celular
          </div>
          <span>
            {contactDetails.cellphone || 'Não informado'}
          </span>
        </Group>
        <Group aside>
          <div className="title">
            E-mail
          </div>
          <span>
            {contactDetails.email || 'Não informado'}
          </span>
        </Group>
      </Container>

      <EditContactModal
        editContactCardModalShow={editContactCardModalShow}
        getErrorMessageByFieldName={getErrorMessageByFieldName}
        contactName={contactName}
        contactDetails={contactDetails}
        handleContactNameChange={handleContactNameChange}
        contactCellphone={contactCellphone}
        handleContactCellphoneChange={handleContactCellphoneChange}
        contactEmail={contactEmail}
        handleContactEmailChange={handleContactEmailChange}
        setEditContactCardModalShow={setEditContactCardModalShow}
        editContact={editContact}
        isReadyToEdit={isReadyToEdit}
      />
    </>
  );
}
