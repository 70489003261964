import formatDate from '../../../../../../utils/formatDate';
import { Card } from '../styles';

interface WaitingApprovementInterface {
  date: string;
  waitImage: string;
  imageTitle: string;
}

export default function WaitingApprovement({
  date,
  waitImage,
  imageTitle,
}: WaitingApprovementInterface) {
  return (
    <Card>
      <div className="info">
        <div className="card-title">
          <strong>{formatDate(date?.split('_')[0])}</strong>
          <small>
            {date?.includes('_') ? date?.split('_')[1] : 'Dia inteiro'}
          </small>
        </div>
      </div>
      <div className="actions">
        <button type="button">
          <img
            src={waitImage}
            alt="wait"
            title={imageTitle}
            className="primaryColor"
          />
        </button>
      </div>
    </Card>
  );
}
