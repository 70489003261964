import {
  Routes, Route, useLocation,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import LoginPage from '../pages/Login';
import ForgotPage from '../pages/ForgotPassword';
import NotFound from '../pages/NotFound';

function LoginRoute() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes
        location={location}
        key={location.pathname}
      >
        <Route path="/" element={<LoginPage />} />
        <Route path="/forgot" element={<ForgotPage />} />

        <Route path="/" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}

export default LoginRoute;
