import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../hooks/useApiCall';
import useErrors from '../../../../../hooks/useErrors';
import activitiesService from '../../../../../services/activitiesService';
import { DateTimeOption } from '../../types';

interface IUseAddModal {
  loadProgram: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setAddModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function useAddModal({
  loadProgram,
  setIsLoading,
  setAddModalShow
}: IUseAddModal) {
  const [specificProgram, setSpecificProgram] = useState('');
  const [dateTime, setDateTime] = useState<DateTimeOption>({ date: null, startTime: null, endTime: null, id: null });

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const { id } = useParams();

  const { apiCall } = useApiCall();

  function handleSpecificProgramChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setSpecificProgram(event.target.value);
    if (!event.target.value) {
      setError({ field: 'specificProgram', message: 'Descrição da programação é obrigatória!' });
    } else {
      removeError('specificProgram');
    }
  }

  const addProgram = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.createActivityPedagogicalProgram,
      onStartLoad: () => { setIsLoading(true); },
      reqBody: JSON.stringify({
        activityId: id,
        pedagogicalInfo: specificProgram,
        date: dateTime.date,
        startTime: dateTime.startTime,
        endTime: dateTime.endTime
      }),
      actionAfterResponse: (response) => {
        if (response.success) {
          toast.success('Programação pedagógica enviada para aprovação com sucesso!');
          setAddModalShow(false);
          setSpecificProgram('');
          setDateTime({ date: null, startTime: null, endTime: null, id: null });
          loadProgram();
          return;
        }
        toast.error('Não foi possível adicionar a programação pedagógica. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível adicionar a programação pedagógica. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    });
  }, [apiCall, dateTime.date, dateTime.endTime, dateTime.startTime, id, loadProgram, setAddModalShow, setIsLoading, specificProgram]);

  const isFormValid = !!specificProgram && !!dateTime.id && errors.length === 0;

  return {
    specificProgram,
    handleSpecificProgramChange,
    dateTime,
    setDateTime,
    getErrorMessageByFieldName,
    addProgram,
    isFormValid,
  };
}
