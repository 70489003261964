import { Dispatch, SetStateAction } from 'react';
import MyModal from '../../../../components/Modal';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import Textarea from '../../../../components/Textarea';
import { PostType } from '../../types';
import { Group, ModalContainer } from './styles';
import useEditPostModal from './useEditPostModal';

interface IEditPostModal {
  editPostModalShow: boolean;
  loadForum: () => Promise<void>;
  setIsLoading:  Dispatch<SetStateAction<boolean>>;
  handleCloseEditModal: () => void;
  post: PostType;
}

export default function EditPostModal({
  editPostModalShow,
  handleCloseEditModal,
  loadForum,
  setIsLoading,
  post,
}: IEditPostModal) {
  const {
    postText,
    handlePostTextChange,
    editPost,
  } = useEditPostModal({
    loadForum,
    setIsLoading,
    handleCloseEditModal,
    post,
    editPostModalShow,
  });

  return (
    <MyModal
      show={editPostModalShow}
      onClose={() => handleCloseEditModal()}
      title={'Editar texto da publicação'}
      modalBody={(
        <ModalContainer>
          <OpacityAnimation delay={0.1}>
            <Group>
              <div className="title">
              Texto da publicação
              </div>
              <Textarea
                value={postText}
                onChange={handlePostTextChange}
                placeholder="O que você deseja compartilhar com os outros professores e com o time Kiddlepass?"
              />
            </Group>
          </OpacityAnimation>
        </ModalContainer>
      )}
      closeButtonLabel="Fechar"
      // size="xl"
      type='action'
      actionButtonLabel="Confirmar"
      isActionButtonDisabled={!postText}
      onAction={() => editPost()}
    />
  );
}
