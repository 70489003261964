import { motion } from 'framer-motion';
import { Col, Container, Row } from 'react-bootstrap';

import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import BankDataCard from './components/BankDataCard';
import ContactDetailsCard from './components/ContactDetailsCard';
import Header from './components/header';
import RegistrationDataCard from './components/RegistrationDataCard';
import ResponsibleDataCard from './components/ResponsibleDataCard';
import SocialMediaCard from './components/SocialMediaCard';

import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import useRegistrationData from './useRegistrationData';

export default function RegistrationData() {
  const {
    isLoading,
    doesApiHasError,
    userData,
    handleTryAgain,
    setEditContactCardModalShow,
    editContactCardModalShow,
    editContact,
  } = useRegistrationData();

  const registrationShape = {
    name: userData.nome_fantasia,
    cnpj: userData.cnpj,
    razaoSocial: userData.razao_social,
    description: userData.descricao,
  };

  const bankDataShape = {
    bank: userData.banco_nome,
    agency: userData.banco_agencia,
    cc: userData.banco_conta,
    holderName: userData.banco_titular,
    cnpj: userData.banco_doc,
    tipoPix: userData.tipo_pix,
    chavePix: userData.pix,
  };

  const contactDetails = {
    cellphone: userData.contato_celular,
    name: userData.contato_nome,
    email: userData.contato_email,
  };

  const responsibleData = {
    role: userData.responsavel_cargo,
    name: userData.responsavel_nome,
    phone: userData.responsavel_telefone,
    email: userData.responsavel_email,
  };

  const socialMediaShape = {
    facebook: userData.facebook || 'Não informado',
    instagram: userData.instagram || 'Não informado',
    site: userData.site || 'Não informado',
    tiktok: userData.tiktok || 'Não informado',
  };

  return (
    <>
      <Sidebar active="RegistrationData" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {!isLoading && !doesApiHasError && (
          <>
            <Header googleEmail={userData.googleEmail} />
            <Container>
              <Row xs={1} md={1} lg={1}>
                <Col>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                  >
                    <RegistrationDataCard registrationData={registrationShape} />
                  </motion.div>
                </Col>
              </Row>
              <Row xs={1} md={2} lg={2}>
                <Col>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.6, type: 'tween', stiffness: 10000 }}
                  >
                    <BankDataCard bankData={bankDataShape} />
                  </motion.div>
                </Col>
                <Col>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.9, type: 'tween', stiffness: 10000 }}
                  >
                    <ContactDetailsCard
                      contactDetails={contactDetails}
                      setEditContactCardModalShow={setEditContactCardModalShow}
                      editContactCardModalShow={editContactCardModalShow}
                      editContact={editContact}
                    />
                  </motion.div>
                </Col>
                <Col>
                  <SocialMediaCard socialMediaData={socialMediaShape} />
                </Col>
                <Col>
                  <ResponsibleDataCard responsibleData={responsibleData} />
                </Col>
              </Row>
            </Container>
          </>
        )}

        {doesApiHasError && !isLoading && (
          <NoData
            icon="sad"
            label={(
              <>
              Ocorreu um erro ao obter os dados cadastrais.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
              .
              </>
            )}
          />
        )}
      </Transitions>
    </>
  );
}
