import { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useErrors from '../../../../../hooks/useErrors';
import activitiesService from '../../../../../services/activitiesService';
import onlyNumbers from '../../../../../utils/onlyNumbers';
import isAgeValid from '../../../../../utils/isAgeValid';
import { useAppContext } from '../../../../../contexts/auth';

interface UseRequirementsCardInterface {
  activityId: string;
  activityName: string;
  reloadActivity: () => void;
  isRecordedClass: boolean;
  requirementsData: {
    minAge: number;
    maxAge: number;
    companion: boolean;
    requiredMaterials: string;
    clothes: string;
    level: string;
    requiredKnowledge: string;
  };
}

export default function useRequirementsCard({
  activityId,
  activityName,
  reloadActivity,
  isRecordedClass,
  requirementsData,
}: UseRequirementsCardInterface) {
  const [isLoading, setIsLoading] = useState(false);
  const [ageModalShow, setAgeModalShow] = useState(false);
  const [minAge, setMinAge] = useState(requirementsData.minAge);
  const [maxAge, setMaxAge] = useState(requirementsData.maxAge);

  const { token, signOut } = useAppContext();

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const isReadyToSubmit = (!!minAge && !!maxAge) && errors.length === 0;

  const changeAge = useCallback(async () => {
    try {
      setIsLoading(true);
      const bodyEdittedAge = await activitiesService.updateActivity(
        {
          activityId,
          reqBody: JSON.stringify({
            nome: activityName,
            minima: minAge,
            maxima: maxAge,
          }),
          token,
        },
      );
      if (bodyEdittedAge === 'unauthorized') {
        signOut();
        return;
      }
      if (!bodyEdittedAge.success) {
        toast.error('Não foi possível alterar a faixa etária');
        setIsLoading(false);
        return;
      }
      setAgeModalShow(false);
      await reloadActivity();
      toast.success('A alteração da faixa etária foi enviada para análise!');
    } catch (error) {
      toast.error(`Não foi possível alterar a faixa etária (${error})`);
    } finally {
      setIsLoading(false);
    }
  }, [activityId, activityName, minAge, maxAge, token, reloadActivity, signOut]);

  function handleMinAgeChange(event: ChangeEvent<HTMLInputElement>) {
    setMinAge(Number(onlyNumbers(event.target.value)));

    if (
      (!event.target.value && !minAge)
      || (!isAgeValid(Number(event.target.value), isRecordedClass))
      || (Number(event.target.value) > Number(maxAge))
    ) {
      setError({ field: 'minAge', message: `Idade mínima deve ser entre ${isRecordedClass ? '0' : '3'} e 12 anos e ser menor que idade máxima!` });
    } else {
      removeError('minAge');
      if (isAgeValid(maxAge, isRecordedClass)) {
        removeError('maxAge');
      }
    }
  }

  function handleMaxAgeChange(event: ChangeEvent<HTMLInputElement>) {
    setMaxAge(Number(onlyNumbers(event.target.value)));

    if (
      (!event.target.value && !maxAge)
      || (!isAgeValid(Number(event.target.value), isRecordedClass))
      || (Number(event.target.value) < Number(minAge))
    ) {
      setError({ field: 'maxAge', message: `Idade máxima deve ser entre ${isRecordedClass ? '0' : '3'} e 12 anos e ser maior que idade mínima!` });
    } else {
      removeError('maxAge');
      if (isAgeValid(minAge, isRecordedClass)) {
        removeError('minAge');
      }
    }
  }

  return {
    isLoading,
    setAgeModalShow,
    ageModalShow,
    getErrorMessageByFieldName,
    minAge,
    handleMinAgeChange,
    maxAge,
    handleMaxAgeChange,
    changeAge,
    isReadyToSubmit,
  };
}
