import { motion } from 'framer-motion';
import { Container, Col, Row } from 'react-bootstrap';
import NoData from '../../../../../../../components/NoData';
import { Container as StyledContainer } from '../../styles';
import plus from '../../../../../../../assets/images/icons/plus.svg';

import { SecondaryButton } from '../../../../../../../components/SecondaryButton';
import { NoDataContainer } from '../../../../../../../components/NoData/styles';
import DayCard from './components/DayCard';
import AddTimeModal from './components/AddTimeModal';
import TimesModal from './components/TimesModal';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { ClassesInfoType, DayInfoBeingShowType } from '../../../../../types/TimetableTypes';
import { TimesInSomeDayType } from '../../../../../types/TimetableTranslatedType';

interface TimetableCardInterface {
  setAddTimetableModalShow: Dispatch<SetStateAction<boolean>>;
  quantityOfMondayClasses: number;
  setDayTimesModalShow:  Dispatch<SetStateAction<boolean>>;
  setDayInfoBeingShow: Dispatch<SetStateAction<DayInfoBeingShowType>>;
  mondayClasses: ClassesInfoType[];
  quantityOfTuesdayClasses: number;
  tuesdayClasses: ClassesInfoType[];
  quantityOfWednesdayClasses: number;
  wednesdayClasses: ClassesInfoType[];
  quantityOfThursdayClasses: number;
  thursdayClasses: ClassesInfoType[];
  quantityOfFridayClasses: number;
  fridayClasses: ClassesInfoType[];
  quantityOfSaturdayClasses: number;
  saturdayClasses: ClassesInfoType[];
  quantityOfSundayClasses: number;
  sundayClasses: ClassesInfoType[];
  addTimetableModalShow: boolean;
  addNewTimeAtTimetable: () => void;
  startTimeBeingAdded: string;
  endTimeBeingAdded: string;
  dayBeingAddedAtTimetable: {
    value: string;
    label: string;
  };
  timetableDaysOptions: {
    value: string;
    label: string;
  }[];
  setDayBeingAddedAtTimetable: Dispatch<SetStateAction<{
    value: string;
    label: string;
  }>>;
  handleStartTimeBeingAddedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  vacanciesQty: number | string;
  handleVacanciesQtyChange: (event: ChangeEvent<HTMLInputElement>) => void;
  dayTimesModalShow: boolean;
  dayInfoBeingShow: DayInfoBeingShowType | object;
  removeTimeAtTimetable: (time: TimesInSomeDayType, day: string) => void;
  hasClasses: boolean;
}

export default function TimetableCard({
  setAddTimetableModalShow,
  quantityOfMondayClasses,
  setDayTimesModalShow,
  setDayInfoBeingShow,
  mondayClasses,
  quantityOfTuesdayClasses,
  tuesdayClasses,
  quantityOfWednesdayClasses,
  wednesdayClasses,
  quantityOfThursdayClasses,
  thursdayClasses,
  quantityOfFridayClasses,
  fridayClasses,
  quantityOfSaturdayClasses,
  saturdayClasses,
  quantityOfSundayClasses,
  sundayClasses,
  addTimetableModalShow,
  addNewTimeAtTimetable,
  startTimeBeingAdded,
  endTimeBeingAdded,
  dayBeingAddedAtTimetable,
  timetableDaysOptions,
  setDayBeingAddedAtTimetable,
  handleStartTimeBeingAddedChange,
  vacanciesQty,
  handleVacanciesQtyChange,
  dayTimesModalShow,
  dayInfoBeingShow,
  removeTimeAtTimetable,
  hasClasses,
}: TimetableCardInterface) {
  return (
    <>
      <StyledContainer>
        <div className="card-title">
          <div>
            Grade horária *
          </div>
          {hasClasses && (
            <img
              src={plus}
              alt="add"
              className="primaryColor"
              title="Adicionar novo horário"
              onClick={() => setAddTimetableModalShow(true)}
            />
          )}
        </div>
        <Container>
          <Row xs={1} md={1} lg={2}>
            {!!quantityOfMondayClasses && (
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                >
                  <DayCard
                    day="Segunda-feira"
                    dayEn="monday"
                    quantityOfClasses={quantityOfMondayClasses}
                    classes={mondayClasses}
                    setDayTimesModalShow={setDayTimesModalShow}
                    setDayInfoBeingShow={setDayInfoBeingShow}
                  />
                </motion.div>
              </Col>
            )}
            {!!quantityOfTuesdayClasses && (
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                >
                  <DayCard
                    day="Terça-feira"
                    dayEn="tuesday"
                    quantityOfClasses={quantityOfTuesdayClasses}
                    classes={tuesdayClasses}
                    setDayTimesModalShow={setDayTimesModalShow}
                    setDayInfoBeingShow={setDayInfoBeingShow}
                  />
                </motion.div>
              </Col>
            )}
            {!!quantityOfWednesdayClasses && (
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                >
                  <DayCard
                    day="Quarta-feira"
                    dayEn="wednesday"
                    quantityOfClasses={quantityOfWednesdayClasses}
                    classes={wednesdayClasses}
                    setDayTimesModalShow={setDayTimesModalShow}
                    setDayInfoBeingShow={setDayInfoBeingShow}
                  />
                </motion.div>
              </Col>
            )}
            {!!quantityOfThursdayClasses && (
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                >
                  <DayCard
                    day="Quinta-feira"
                    dayEn="thursday"
                    quantityOfClasses={quantityOfThursdayClasses}
                    classes={thursdayClasses}
                    setDayTimesModalShow={setDayTimesModalShow}
                    setDayInfoBeingShow={setDayInfoBeingShow}
                  />
                </motion.div>
              </Col>
            )}
            {!!quantityOfFridayClasses && (
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                >
                  <DayCard
                    day="Sexta-feira"
                    dayEn="friday"
                    quantityOfClasses={quantityOfFridayClasses}
                    classes={fridayClasses}
                    setDayTimesModalShow={setDayTimesModalShow}
                    setDayInfoBeingShow={setDayInfoBeingShow}
                  />
                </motion.div>
              </Col>
            )}
            {!!quantityOfSaturdayClasses && (
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                >
                  <DayCard
                    day="Sábado"
                    dayEn="saturday"
                    quantityOfClasses={quantityOfSaturdayClasses}
                    classes={saturdayClasses}
                    setDayTimesModalShow={setDayTimesModalShow}
                    setDayInfoBeingShow={setDayInfoBeingShow}
                  />
                </motion.div>
              </Col>
            )}
            {!!quantityOfSundayClasses && (
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                >
                  <DayCard
                    day="Domingo"
                    dayEn="sunday"
                    quantityOfClasses={quantityOfSundayClasses}
                    classes={sundayClasses}
                    setDayTimesModalShow={setDayTimesModalShow}
                    setDayInfoBeingShow={setDayInfoBeingShow}
                  />
                </motion.div>
              </Col>
            )}

            {!hasClasses && (
              <NoDataContainer timetable>
                <SecondaryButton onClick={() => setAddTimetableModalShow(true)}>
                  Adicionar novo horário
                </SecondaryButton>
                <NoData
                  icon="emptyBox"
                  label={(
                    <>
                      Comece a montar sua grade horária clicando no botão
                      {' '}
                      <strong>Adicionar novo horário</strong>
                      {' '}
                      acima.
                      Se atente para não inserir horários que conflitem entre si.
                    </>
                  )}
                />
              </NoDataContainer>
            )}
          </Row>
        </Container>
      </StyledContainer>

      <AddTimeModal
        addTimetableModalShow={addTimetableModalShow}
        setAddTimetableModalShow={setAddTimetableModalShow}
        addNewTimeAtTimetable={addNewTimeAtTimetable}
        startTimeBeingAdded={startTimeBeingAdded}
        endTimeBeingAdded={endTimeBeingAdded}
        dayBeingAddedAtTimetable={dayBeingAddedAtTimetable}
        timetableDaysOptions={timetableDaysOptions}
        setDayBeingAddedAtTimetable={setDayBeingAddedAtTimetable}
        handleStartTimeBeingAddedChange={handleStartTimeBeingAddedChange}
        vacanciesQty={vacanciesQty}
        handleVacanciesQtyChange={handleVacanciesQtyChange}
      />

      <TimesModal
        dayTimesModalShow={dayTimesModalShow}
        setDayTimesModalShow={setDayTimesModalShow}
        dayInfoBeingShow={dayInfoBeingShow as DayInfoBeingShowType}
        removeTimeAtTimetable={removeTimeAtTimetable}
      />
    </>
  );
}
