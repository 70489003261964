import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import activitiesService from '../../../../../../../services/activitiesService';
import formatDateToUsa from '../../../../../../../utils/formatDateToUsa';
import useApiCall from '../../../../../../../hooks/useApiCall';

interface UseRehabilitateDateInterface {
  activityId: string;
  activityName: string;
  reloadActivity: () => void;
}

export default function useRehabilitateDate({
  activityId,
  activityName,
  reloadActivity,
}: UseRehabilitateDateInterface) {
  const [isRehabilitating, setIsRehabilitating] = useState(false);
  const [selectedRehabilitateDate, setRehabilitateDate] = useState('');
  const [rehabilitateDateModalShow, setRehabilitateDateModalShow] = useState(false);

  const [day, time] = selectedRehabilitateDate.includes('_') ? selectedRehabilitateDate.split('_') : [selectedRehabilitateDate];

  const { apiCall } = useApiCall();

  const rehabilitateDate = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.updateActivity,
      queryParams: { activityId },
      reqBody: JSON.stringify({
        id: activityId,
        nome: activityName,
        datasDesabilitadas: {
          data: time ? `${formatDateToUsa(day)}_${time}` : formatDateToUsa(day),
          acao: 'reabilitar',
        },
      }),
      onStartLoad: () => setIsRehabilitating(true),
      onEndLoad: () => setIsRehabilitating(false),
      actionAfterResponse: (response) => {
        if (!response.success) {
          toast.error('Não foi possível reabilitar a data');
          setIsRehabilitating(false);
          return;
        }
        setRehabilitateDateModalShow(false);
        reloadActivity();
        toast.success('A reabilitação da data foi enviada para análise!');
      },
      catchMessage: 'Não foi possível reabilitar a data',
    });
  }, [apiCall, activityId, activityName, time, day, reloadActivity]);

  return {
    isRehabilitating,
    setRehabilitateDate,
    setRehabilitateDateModalShow,
    selectedRehabilitateDate,
    rehabilitateDate,
    rehabilitateDateModalShow,
  };
}
