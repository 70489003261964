/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { addDays, addMonths, format, getDay, isAfter, isBefore, parse } from 'date-fns';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../hooks/useApiCall';
import activitiesService from '../../../../../services/activitiesService';
import getStringWeekDayByIndex from '../../../../../utils/getStringWeekDayByIndex';
import { DateTimeOption, ListApiResponse, MappedProgramType } from '../../types';

interface UseLoadProgramsI {
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

export default function useLoadPrograms({ setIsLoading }: UseLoadProgramsI) {
  const [programs, setPrograms] = useState<MappedProgramType[]>([]);
  const [activityName, setActivityName] = useState('');
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);
  const [dateTimeOptions, setDateTimeOptions] = useState<DateTimeOption[]>([]);

  const { apiCall } = useApiCall();
  const { id } = useParams();

  const loadPrograms = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getActivityPedagogicalPrograms,
      queryParams: { activityId: id },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      catchAction: () => setDoesListApiHasError(true),
      actionAfterResponse: (response: ListApiResponse) => {
        if (!response.success) {
          setDoesListApiHasError(true);
          toast.error('Não foi possível recuperar as programações pedagógicas dessa atividade. Por favor, tente novamente');
          return;
        }
        const { activity, programs } = response;


        const compareDateTimeOptions = (a: DateTimeOption, b: DateTimeOption): number => {
          // Converte as strings de data para objetos Date
          const dateA = parse(a.date!, 'd/M/yyyy', new Date());
          const dateB = parse(b.date!, 'd/M/yyyy', new Date());

          // Compara as datas
          const dateComparison = dateA.getTime() - dateB.getTime();

          // Se as datas são iguais, compara os startTime
          if (dateComparison === 0) {
            return a.startTime!.localeCompare(b.startTime!);
          }

          return dateComparison;
        };

        const programsMapped = programs.map((prg) => ({
          ...prg,
          specificProgram: prg.pedagogicalInfo,
          concat: `${prg.date}|${prg.startTime}|${prg.endTime}`
        }));
        programsMapped.sort(compareDateTimeOptions);
        setPrograms(programsMapped);

        setActivityName(activity.nome);

        const weekDaysOfActivity = activity.grade.filter((timetable) => {
          return timetable.horarios.length > 0;
        });
        console.log('weekDaysOfActivity', weekDaysOfActivity);

        const endDate = activity.recorrente ? addMonths(new Date(), 2) : new Date(activity.specificPeriod[1]);

        const dateTimeOptionsOfActivityInNext2Months: DateTimeOption[] = [];

        weekDaysOfActivity.forEach((weekDay) => {
          let currentDate = addDays(new Date(), 1);

          while (isAfter(endDate, currentDate)) {
            if (activity.recorrente) {
              const currentDateWeekDayStringAtLoop = getStringWeekDayByIndex(getDay(currentDate));

              if (currentDateWeekDayStringAtLoop === weekDay.dia) {
                const timesAtDay = weekDay.horarios;

                timesAtDay.forEach((time) => {
                  dateTimeOptionsOfActivityInNext2Months.push({
                    id: `${format(currentDate, 'd/M/yyyy')}|${time.horarioInicio}|${time.horarioTermino}`,
                    date: format(currentDate, 'd/M/yyyy'),
                    startTime: time.horarioInicio,
                    endTime: time.horarioTermino,
                  });
                });
              }
              currentDate = addDays(currentDate, 1);
            } else {
              const currentDateWeekDayStringAtLoop = getStringWeekDayByIndex(getDay(currentDate));
              const startDate = new Date(activity.specificPeriod[0]);

              if (currentDateWeekDayStringAtLoop === weekDay.dia && isBefore(startDate, currentDate)) {
                const timesAtDay = weekDay.horarios;

                timesAtDay.forEach((time) => {
                  dateTimeOptionsOfActivityInNext2Months.push({
                    id: `${format(currentDate, 'd/M/yyyy')}|${time.horarioInicio}|${time.horarioTermino}`,
                    date: format(currentDate, 'd/M/yyyy'),
                    startTime: time.horarioInicio,
                    endTime: time.horarioTermino,
                  });
                });
              }
              currentDate = addDays(currentDate, 1);
            }
          }
        });

        const removedDateTimesThatAlreadyHasProgram = dateTimeOptionsOfActivityInNext2Months.filter((dateTime) => {
          const allConcatsFromPrograms = programsMapped.map((prg) => prg.concat);

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return !allConcatsFromPrograms.includes(dateTime.id!);
        });

        removedDateTimesThatAlreadyHasProgram.sort(compareDateTimeOptions);
        setDateTimeOptions(removedDateTimesThatAlreadyHasProgram);
        setDoesListApiHasError(false);
      },
    });
  }, [apiCall, id, setIsLoading]);

  useEffect(() => {
    loadPrograms();
  }, [loadPrograms]);

  useEffect(() => {
    toast.info('Atenção, essa página é usada somente para enviar a programação pedagógica de suas aulas para a coordenação Kiddlepass. Nada informado aqui será mostrado no aplicativo. Para inserir uma orientação específica para uma aula, como materiais exclusivos da mesma, acesse o botão rosa, com o símbolo de um calendário e um + dentro, na página de atividades', { toastId: 'pedagogicalAlertOnLoad'});
  }, []);

  return {
    programs,
    doesListApiHasError,
    dateTimeOptions,
    loadPrograms,
    activityName,
  };
}
