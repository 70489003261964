import MyModal from '../../../../components/Modal';
import { Card } from './styles';

interface IWarningsModal {
  warningsModalShow: boolean;
  setWarningsModalShow: (value: boolean) => void;
  warnings: string[];
}

export default function WarningsModal({
  warningsModalShow,
  setWarningsModalShow,
  warnings,
}: IWarningsModal) {
  return (
    <MyModal
      show={warningsModalShow}
      title="Avisos"
      modalBody={(
        <>
          {warnings.map((warn, index) => (
            <Card key={index} justifyContent="flex-start">
              <div className="info">
                <span>{warn}</span>
              </div>
            </Card>
          ))}
        </>
      )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Confirmar"
      onClose={() => setWarningsModalShow(false)}
      type="info"
    />
  );
}
