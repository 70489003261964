import { Link } from 'react-router-dom';
import activity from '../../assets/images/icons/activity.svg';
import calendar from '../../assets/images/icons/calendar.svg';
import comments from '../../assets/images/icons/comments.svg';
import exit from '../../assets/images/icons/exit.svg';
import financial from '../../assets/images/icons/financial.svg';
import folder from '../../assets/images/icons/folder.svg';
import registrationData from '../../assets/images/icons/registrationData.svg';
import { ActiveItem, Container, MenuItem } from './styles';

import { useAppContext } from '../../contexts/auth';

interface NavbarInterface {
  active: string;
}

export default function Navbar({ active }: NavbarInterface) {
  const { signOut } = useAppContext();

  return (
    <Container>
      {active === 'RegistrationData'
        ? (
          <ActiveItem>
            <Link to="/">
              <MenuItem>
                <img src={registrationData} alt="registrationData" />
                Meu Cadastro
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/">
            <MenuItem>
              <img src={registrationData} alt="registrationData" />
              Meu Cadastro
            </MenuItem>
          </Link>
        )}


      {active === 'Forum'
        ? (
          <ActiveItem>
            <Link to="/forum">
              <MenuItem>
                <img src={comments} alt="forum" />
                Forum
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/forum">
            <MenuItem>
              <img src={comments} alt="forum" />
              Forum
            </MenuItem>
          </Link>
        )}

      {active === 'Activities'
        ? (
          <ActiveItem>
            <Link to="/activities">
              <MenuItem>
                <img src={activity} alt="activity" />
                Atividades
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/activities">
            <MenuItem>
              <img src={activity} alt="activity" />
              Atividades
            </MenuItem>
          </Link>
        )}

      {active === 'Schedules'
        ? (
          <ActiveItem>
            <Link to="/schedules">
              <MenuItem>
                <img src={calendar} alt="schedule" />
                Aulas/agendamentos
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/schedules">
            <MenuItem>
              <img src={calendar} alt="schedule" />
              Aulas/agendamentos
            </MenuItem>
          </Link>
        )}

      {active === 'Financial'
        ? (
          <ActiveItem>
            <Link to="/financial">
              <MenuItem>
                <img src={financial} alt="financial" />
                Financeiro
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/financial">
            <MenuItem>
              <img src={financial} alt="financial" />
              Financeiro
            </MenuItem>
          </Link>
        )}

      {active === 'Files'
        ? (
          <ActiveItem>
            <Link to="/files">
              <MenuItem>
                <img src={folder} alt="schedule" />
                Arquivos/materiais
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/files">
            <MenuItem>
              <img src={folder} alt="schedule" />
              Arquivos/materiais
            </MenuItem>
          </Link>
        )}

      <MenuItem onClick={() => signOut()}>
        <img src={exit} alt="exit" />
        Sair
      </MenuItem>

    </Container>
  );
}
