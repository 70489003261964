import { Dispatch, SetStateAction } from 'react';
import FileInput from '../../../../components/FileInput';
import FormGroup from '../../../../components/FormGroup';
import MyModal from '../../../../components/Modal';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import Textarea from '../../../../components/Textarea';
import { Group, ModalContainer } from './styles';
import useAddPostModal from './useAddPostModal';

interface IAddPostModal {
  addPostModalShow: boolean;
  setAddPostModalShow: Dispatch<SetStateAction<boolean>>;
  loadForum: () => Promise<void>;
  setIsLoading:  Dispatch<SetStateAction<boolean>>;
}

export default function AddPostModal({
  addPostModalShow,
  setAddPostModalShow,
  loadForum,
  setIsLoading,
}: IAddPostModal) {
  const {
    postText,
    fileName,
    getErrorMessageByFieldName,
    handlePostTextChange,
    handleFileUpload,
    removeFile,
    isFormValid,
    addPost,
  } = useAddPostModal({
    loadForum,
    setIsLoading,
    setAddPostModalShow,
  });

  return (
    <MyModal
      show={addPostModalShow}
      onClose={() => setAddPostModalShow(false)}
      title={'Adicionar nova publicação'}
      modalBody={(
        <ModalContainer>
          <OpacityAnimation delay={0.1}>
            <Group>
              <div className="title">
              Texto da publicação
              </div>
              <FormGroup error={getErrorMessageByFieldName('postText')}>
                <Textarea
                  value={postText}
                  onChange={handlePostTextChange}
                  placeholder="O que você deseja compartilhar com os outros professores e com o time Kiddlepass?"
                />
              </FormGroup>
            </Group>

            <Group>
              <FileInput
                onFileUpload={handleFileUpload}
                fileNameChoosed={fileName}
                acceptedFiles="video/*, image/*"
                hasSubmitAction={false}
                removeFile={removeFile}
              />
            </Group>
          </OpacityAnimation>
        </ModalContainer>
      )}
      closeButtonLabel="Fechar"
      // size="xl"
      type='action'
      actionButtonLabel="Adicionar"
      isActionButtonDisabled={!isFormValid}
      onAction={() => addPost()}
    />
  );
}
