import { motion } from 'framer-motion';
import { Dispatch, SetStateAction } from 'react';
import Select from 'react-select';
import { CustomStyle } from '../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../components/CustomSelectStyleDarkTheme';
import FilterRadioButton from '../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../components/FilterRadioButtonsContainer';
import { SecondaryButton } from '../../../../components/SecondaryButton';
import useThemeContext from '../../../../contexts/theme';
import { DatesType } from '../../types';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  appointmentsQuantity: number;
  singularLabel: string;
  pluralLabel: string;
  dateBeingShown: string;
  handleModalSpecificDateShow: () => void;
  handleDateChange: (date?: string) => void;
  dates: DatesType;
  selectedActivity: string;
  setSelectedActivity: Dispatch<SetStateAction<string>>;
  activitiesList: string[];
  warnings: string[];
  setWarningsModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function ListHeader({
  doesListExists,
  hasError,
  filteredListLength,
  appointmentsQuantity,
  singularLabel,
  pluralLabel,
  dateBeingShown,
  handleModalSpecificDateShow,
  handleDateChange,
  dates,
  selectedActivity,
  setSelectedActivity,
  activitiesList,
  warnings,
  setWarningsModalShow,
}: ListHeaderInterface) {
  const hasSomeWarning = warnings.length > 0;

  const isSpecificDateButtonSelected = (
    dateBeingShown !== dates.currentDate
    && dateBeingShown !== dates.tomorrowDate
    && dateBeingShown !== dates.afterTomorrowDate
  );
  const activitiesOptions = activitiesList.map((activity) => (
    {
      value: activity,
      label: activity,
    }
  ));
  activitiesOptions.unshift({
    value: '',
    label: 'Todos',
  });

  const { selectedTheme } = useThemeContext();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.1, type: 'tween', stiffness: 10 }}
    >
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <>
            <strong>
              <span>
                {filteredListLength}
                {' '}
                {filteredListLength === 1 ? singularLabel : pluralLabel}
              </span>
              <span>
                {`(${appointmentsQuantity} ${appointmentsQuantity == 1 ? 'agendamento' : 'agendamentos'})`}
              </span>
            </strong>
          </>
        )}
        {activitiesList.length > 0 && doesListExists && (
          <Select
            value={{ value: selectedActivity || '', label: selectedActivity || 'Filtrar por atividade' }}
            options={activitiesOptions}
            onChange={(opt) => setSelectedActivity(opt!.value)}
            placeholder="Filtrar por atividade"
            styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container-as-filter"
          />
        )}
        <FilterRadioButtonsContainer>
          <FilterRadioButton
            onClick={() => handleDateChange(dates.currentDate)}
            selected={dateBeingShown === dates.currentDate}
          >
            Hoje
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleDateChange(dates.tomorrowDate)}
            selected={dateBeingShown === dates.tomorrowDate}
          >
            Amanhã
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleDateChange(dates.afterTomorrowDate)}
            selected={dateBeingShown === dates.afterTomorrowDate}
          >
            Depois de amanhã
          </FilterRadioButton>
          <FilterRadioButton
            onClick={handleModalSpecificDateShow}
            selected={isSpecificDateButtonSelected}
          >
            Data específica
            {isSpecificDateButtonSelected && (
              ` (${dateBeingShown})`
            )}
          </FilterRadioButton>
        </FilterRadioButtonsContainer>
        {hasSomeWarning && (
          <SecondaryButton type="button" onClick={() => setWarningsModalShow(true)}>
                ⚠️ Avisos ({warnings.length})
          </SecondaryButton>
        )}
      </Header>
    </motion.div>
  );
}
