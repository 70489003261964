import useInputChanges from './functions/useInputChanges';
import useTimetable from './functions/useTimetable';
import useLoadForm from './functions/useLoadForm';
import useCreateActivity from './functions/useCreateActivity';

export default function useActivityForm() {
  const {
    category,
    kindOfActivity,
    name,
    subcategory,
    description,
    maxAge,
    minAge,
    videoFile,
    imageFile,
    errors,
    videoLink,
    setKindOfActivity,
    getErrorMessageByFieldName,
    handleNameChange,
    setCategory,
    setSubcategory,
    setMinAge,
    setMaxAge,
    handleDescriptionChange,
    handleVideoLinkChange,
    handleMethodologyChange,
    methodology,
    handleOrientationsChange,
    orientations,
    handleMinAgeChange,
    handleMaxAgeChange,
    needsCompanion,
    setNeedsCompanion,
    level,
    setLevel,
    handleNecessaryMaterialsChange,
    necessaryMaterials,
    handleNecessaryKnowledgeChange,
    necessaryKnowledge,
    handleClothesChange,
    clothes,
    setImageFile,
    setVideoFile,
    isRecurrent,
    setIsRecurrent,
    handleSpecificPeriodChange,
    specificPeriod,
    setPeriodSelectionModalShow,
    specificPeriodLabel,
    periodSelectionModalShow,
  } = useInputChanges();

  const {
    timetable,
    hasClasses,
    setAddTimetableModalShow,
    quantityOfMondayClasses,
    setDayTimesModalShow,
    setDayInfoBeingShow,
    mondayClasses,
    quantityOfTuesdayClasses,
    tuesdayClasses,
    quantityOfWednesdayClasses,
    wednesdayClasses,
    quantityOfThursdayClasses,
    thursdayClasses,
    quantityOfFridayClasses,
    fridayClasses,
    quantityOfSaturdayClasses,
    saturdayClasses,
    quantityOfSundayClasses,
    sundayClasses,
    addTimetableModalShow,
    addNewTimeAtTimetable,
    startTimeBeingAdded,
    endTimeBeingAdded,
    dayBeingAddedAtTimetable,
    timetableDaysOptions,
    setDayBeingAddedAtTimetable,
    handleStartTimeBeingAddedChange,
    vacanciesQty,
    handleVacanciesQtyChange,
    dayTimesModalShow,
    dayInfoBeingShow,
    removeTimeAtTimetable,
    duration,
    handleDurationChange,
  } = useTimetable();

  const {
    categories,
    isCategoriesSelectLoading,
    subcategoriesOptions,
    companionOptions,
    levelOptions,
    recurrentOptions,
  } = useLoadForm({ category });

  const {
    isSubmitting,
    createActivity,
    isFormValid,
  } = useCreateActivity({
    name,
    description,
    category,
    subcategory,
    minAge,
    maxAge,
    methodology,
    necessaryMaterials,
    needsCompanion,
    orientations,
    clothes,
    isRecurrent,
    level,
    necessaryKnowledge,
    videoLink,
    kindOfActivity,
    timetable,
    imageFile,
    videoFile,
    duration,
    errors,
    hasClasses,
    specificPeriod,
  });

  return {
    isSubmitting,
    setKindOfActivity,
    kindOfActivity,
    getErrorMessageByFieldName,
    name,
    handleNameChange,
    category,
    categories,
    setCategory,
    isCategoriesSelectLoading,
    subcategory,
    subcategoriesOptions,
    setSubcategory,
    setMinAge,
    setMaxAge,
    handleDescriptionChange,
    handleVideoLinkChange,
    videoLink,
    handleMethodologyChange,
    methodology,
    handleOrientationsChange,
    orientations,
    handleMinAgeChange,
    handleMaxAgeChange,
    needsCompanion,
    companionOptions,
    setNeedsCompanion,
    level,
    levelOptions,
    setLevel,
    handleNecessaryMaterialsChange,
    necessaryMaterials,
    handleNecessaryKnowledgeChange,
    necessaryKnowledge,
    handleClothesChange,
    clothes,
    setAddTimetableModalShow,
    quantityOfMondayClasses,
    setDayTimesModalShow,
    setDayInfoBeingShow,
    mondayClasses,
    quantityOfTuesdayClasses,
    tuesdayClasses,
    quantityOfWednesdayClasses,
    wednesdayClasses,
    quantityOfThursdayClasses,
    thursdayClasses,
    quantityOfFridayClasses,
    fridayClasses,
    quantityOfSaturdayClasses,
    saturdayClasses,
    quantityOfSundayClasses,
    sundayClasses,
    hasClasses,
    imageFile,
    setImageFile,
    videoFile,
    setVideoFile,
    addTimetableModalShow,
    addNewTimeAtTimetable,
    startTimeBeingAdded,
    endTimeBeingAdded,
    dayBeingAddedAtTimetable,
    timetableDaysOptions,
    setDayBeingAddedAtTimetable,
    handleStartTimeBeingAddedChange,
    vacanciesQty,
    handleVacanciesQtyChange,
    dayTimesModalShow,
    dayInfoBeingShow,
    removeTimeAtTimetable,
    isFormValid,
    minAge,
    maxAge,
    description,
    isRecurrent,
    setIsRecurrent,
    recurrentOptions,
    createActivity,
    duration,
    handleDurationChange,
    handleSpecificPeriodChange,
    specificPeriod,
    setPeriodSelectionModalShow,
    specificPeriodLabel,
    periodSelectionModalShow,
  };
}
