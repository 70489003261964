import { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import financialService from '../../../../services/financialService';
import { CurrrentMonthBeingShowType, CurrrentYearBeingShowType, LoadFinancialType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

interface UseNfUploadInterface {
  currentMonthBeingShow: CurrrentMonthBeingShowType | '';
  currentYearBeingShow: CurrrentYearBeingShowType;
  loadFinancial: LoadFinancialType;
}

export default function useNfUpload({
  currentMonthBeingShow,
  currentYearBeingShow,
  loadFinancial,
}: UseNfUploadInterface) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<File[]>([]);
  const [nfUploadModalShow, setNfUploadModalShow] = useState(false);
  const [nfFileName, setNfFileName] = useState('');

  const { apiCall } = useApiCall();

  function handleNfFileUpload(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files) {
      setNfFileName(files[0].name);
      setFile([files[0]]);
    }
  }

  const handleNfFileSubmit = useCallback(async () => {
    await apiCall({
      apiToCall: financialService.uploadNf,
      reqBody: [
        { key: 'doc', value: file[0] },
        { key: 'data', value: `${currentMonthBeingShow}_${currentYearBeingShow}` },
      ],
      onStartLoad: () => setIsSubmitting(true),
      onEndLoad: () => setIsSubmitting(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível realizar o upload da nota fiscal. Por favor, tente novamente');
        }
        toast.success('Nota fiscal enviada com sucesso!');
        loadFinancial();
        setNfUploadModalShow(false);
      },
      catchMessage: 'Não foi possível realizar o upload da nota fiscal. Por favor, tente novamente'
    });
  }, [apiCall, currentMonthBeingShow, currentYearBeingShow, file, loadFinancial]);

  return {
    isSubmitting,
    nfUploadModalShow,
    setNfUploadModalShow,
    nfFileName,
    handleNfFileUpload,
    handleNfFileSubmit,
  };
}
