import { motion } from 'framer-motion';
import qs from 'qs';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import googleIcon from '../../../../assets/images/icons/google.svg';
import { SecondaryButton } from '../../../../components/SecondaryButton';
import { isLocal } from '../../../../pathName';
import { Container } from './styles';

export default function Header({ googleEmail }: { googleEmail?: string }) {
  const redirectToGoogleAuth = useCallback(() => {
    const baseURL = 'https://accounts.google.com/o/oauth2/v2/auth';
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    const options = qs.stringify({
      client_id: '208410117769-knf1h11j9e6g1dmemd6l6cb0t6sromhb.apps.googleusercontent.com',
      redirect_uri: isLocal ? 'http://localhost:3000' :`${protocol}//${hostname}`,
      response_type: 'code',
      scope: 'email',
    });

    window.location.href = `${baseURL}?${options}`;
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.1, type: 'tween', stiffness: 10 }}
    >
      <Container>
        <strong>
          Seu Perfil
        </strong>
        <div className="actionButtons">
          {/* <Link to="/registration/edit">
            Editar
          </Link> */}
          <SecondaryButton small className='googleButton' onClick={redirectToGoogleAuth}>
            <img src={googleIcon} alt="" />
            {googleEmail ? googleEmail : 'Vincular conta Google'}
          </SecondaryButton>
          <Link to="/changePassword">
            Alterar Senha
          </Link>
        </div>
      </Container>
    </motion.div>
  );
}
