import qs from 'qs';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/auth';
import useErrors from '../../hooks/useErrors';
import { useQuery } from '../../hooks/useQuery';
import { isLocal } from '../../pathName';
import isEmailValid from '../../utils/isEmailValid';

export default function useLogin() {
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { login, signed, loginWithGoogle } = useAppContext();
  const query = useQuery();
  const googleCode = query.get('code');

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const checkIsLoginValid = useCallback(() => {
    if (!signed) {
      setEmail('');
      setPassword('');
    }
  }, [signed]);

  const isReadyToLogin = ((email && password) && errors.length === 0);

  async function handleLogin() {
    setIsLoading(true);
    await login(email, password);
    setIsLoading(false);
    checkIsLoginValid();
  }

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);

    if (!isEmailValid(event.target.value)) {
      setError({ field: 'email', message: 'E-mail inválido!' });
    } else {
      removeError('email');
    }
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);

    if (event.target.value.length < 6) {
      setError({ field: 'password', message: 'Senha deve ter no mínimo 6 caracteres' });
    } else {
      removeError('password');
    }
  }

  const handleGoogleLogin = useCallback(async () => {
    setIsLoading(true);
    await loginWithGoogle(googleCode);
    setIsLoading(false);
  }, [googleCode, loginWithGoogle]);

  const redirectToGoogleAuth = useCallback(() => {
    const baseURL = 'https://accounts.google.com/o/oauth2/v2/auth';
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    const options = qs.stringify({
      client_id: '208410117769-knf1h11j9e6g1dmemd6l6cb0t6sromhb.apps.googleusercontent.com',
      redirect_uri: isLocal ? 'http://localhost:3000' :`${protocol}//${hostname}`,
      response_type: 'code',
      scope: 'email',
    });

    window.location.href = `${baseURL}?${options}`;
  }, []);

  useEffect(() => {
    if (googleCode) {
      handleGoogleLogin();
    }
  }, [googleCode, handleGoogleLogin]);

  return {
    isLoading,
    getErrorMessageByFieldName,
    email,
    handleEmailChange,
    password,
    handlePasswordChange,
    handleLogin,
    isReadyToLogin,
    redirectToGoogleAuth,
  };
}
