import { Container, Col, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';

import Loader from '../../../../../components/Loader';
import PageHeader from '../../../../../components/PageHeader';
import { Container as StyledContainer } from './styles';

import FilePreview from '../../../../../components/FilePreview';
import Button from '../../../../../components/Button';
import { ButtonContainer } from '../../../../../components/Form';

import useActivityForm from './useActivityForm';

import KindOfActivity from './components/KindOfActivity';
import GeneralDataCard from './components/GeneralDataCard';
import RequirementsDataCard from './components/RequirementsDataCard';
import TimetableCard from './components/TimetableCard';
import Duration from './components/Duration';

interface ActivityFormInterface {
  edit?: boolean;
}

export default function ActivityForm({ edit }: ActivityFormInterface) {
  const {
    isSubmitting,
    setKindOfActivity,
    kindOfActivity,
    getErrorMessageByFieldName,
    name,
    handleNameChange,
    minAge,
    maxAge,
    description,
    category,
    categories,
    setCategory,
    isCategoriesSelectLoading,
    subcategory,
    subcategoriesOptions,
    setSubcategory,
    setMinAge,
    setMaxAge,
    handleDescriptionChange,
    handleVideoLinkChange,
    videoLink,
    handleMethodologyChange,
    methodology,
    handleOrientationsChange,
    orientations,
    handleMinAgeChange,
    handleMaxAgeChange,
    needsCompanion,
    companionOptions,
    setNeedsCompanion,
    level,
    levelOptions,
    setLevel,
    handleNecessaryMaterialsChange,
    necessaryMaterials,
    handleNecessaryKnowledgeChange,
    necessaryKnowledge,
    handleClothesChange,
    clothes,
    setAddTimetableModalShow,
    quantityOfMondayClasses,
    setDayTimesModalShow,
    setDayInfoBeingShow,
    mondayClasses,
    quantityOfTuesdayClasses,
    tuesdayClasses,
    quantityOfWednesdayClasses,
    wednesdayClasses,
    quantityOfThursdayClasses,
    thursdayClasses,
    quantityOfFridayClasses,
    fridayClasses,
    quantityOfSaturdayClasses,
    saturdayClasses,
    quantityOfSundayClasses,
    sundayClasses,
    hasClasses,
    imageFile,
    setImageFile,
    videoFile,
    setVideoFile,
    addTimetableModalShow,
    addNewTimeAtTimetable,
    startTimeBeingAdded,
    endTimeBeingAdded,
    dayBeingAddedAtTimetable,
    timetableDaysOptions,
    setDayBeingAddedAtTimetable,
    handleStartTimeBeingAddedChange,
    vacanciesQty,
    handleVacanciesQtyChange,
    dayTimesModalShow,
    dayInfoBeingShow,
    removeTimeAtTimetable,
    isFormValid,
    isRecurrent,
    setIsRecurrent,
    recurrentOptions,
    createActivity,
    duration,
    handleDurationChange,
    handleSpecificPeriodChange,
    specificPeriod,
    setPeriodSelectionModalShow,
    specificPeriodLabel,
    periodSelectionModalShow,
  } = useActivityForm();

  return (
    <>
      <Loader isLoading={isSubmitting} />
      {edit && (
        <PageHeader
          title={`Editar Atividade ${name}`}
          link="/activities"
        />
      )}
      <KindOfActivity setKindOfActivity={setKindOfActivity} kindOfActivity={kindOfActivity} />
      {kindOfActivity === 'online' && (
        <Duration handleDurationChange={handleDurationChange} duration={duration} />
      )}
      <Container>
        <Row xs={1} md={1} lg={1}>
          <Col>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
            >
              <GeneralDataCard
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                name={name}
                handleNameChange={handleNameChange}
                category={category}
                categories={categories}
                setCategory={setCategory}
                isCategoriesSelectLoading={isCategoriesSelectLoading}
                subcategory={subcategory}
                subcategoriesOptions={subcategoriesOptions}
                setSubcategory={setSubcategory}
                isRecurrent={isRecurrent}
                setIsRecurrent={setIsRecurrent}
                recurrentOptions={recurrentOptions}
                setMinAge={setMinAge}
                setMaxAge={setMaxAge}
                handleDescriptionChange={handleDescriptionChange}
                description={description}
                kindOfActivity={kindOfActivity}
                handleVideoLinkChange={handleVideoLinkChange}
                videoLink={videoLink}
                handleMethodologyChange={handleMethodologyChange}
                methodology={methodology}
                handleOrientationsChange={handleOrientationsChange}
                orientations={orientations}
                handleSpecificPeriodChange={handleSpecificPeriodChange}
                specificPeriod={specificPeriod}
                setPeriodSelectionModalShow={setPeriodSelectionModalShow}
                specificPeriodLabel={specificPeriodLabel}
                periodSelectionModalShow={periodSelectionModalShow}
              />
            </motion.div>
          </Col>

          <Col>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.6, type: 'tween', stiffness: 10000 }}
            >
              <RequirementsDataCard
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                minAge={minAge}
                handleMinAgeChange={handleMinAgeChange}
                subcategory={subcategory}
                maxAge={maxAge}
                handleMaxAgeChange={handleMaxAgeChange}
                needsCompanion={needsCompanion}
                companionOptions={companionOptions}
                setNeedsCompanion={setNeedsCompanion}
                level={level}
                levelOptions={levelOptions}
                setLevel={setLevel}
                handleNecessaryMaterialsChange={handleNecessaryMaterialsChange}
                necessaryMaterials={necessaryMaterials}
                handleNecessaryKnowledgeChange={handleNecessaryKnowledgeChange}
                necessaryKnowledge={necessaryKnowledge}
                handleClothesChange={handleClothesChange}
                clothes={clothes}
              />
            </motion.div>
          </Col>
        </Row>

        <Row xs={1} md={1} lg={2}>
          {kindOfActivity === 'online' && (
            <Col>
              <TimetableCard
                setAddTimetableModalShow={setAddTimetableModalShow}
                quantityOfMondayClasses={quantityOfMondayClasses}
                setDayTimesModalShow={setDayTimesModalShow}
                setDayInfoBeingShow={setDayInfoBeingShow}
                mondayClasses={mondayClasses}
                quantityOfTuesdayClasses={quantityOfTuesdayClasses}
                tuesdayClasses={tuesdayClasses}
                quantityOfWednesdayClasses={quantityOfWednesdayClasses}
                wednesdayClasses={wednesdayClasses}
                quantityOfThursdayClasses={quantityOfThursdayClasses}
                thursdayClasses={thursdayClasses}
                quantityOfFridayClasses={quantityOfFridayClasses}
                fridayClasses={fridayClasses}
                quantityOfSaturdayClasses={quantityOfSaturdayClasses}
                saturdayClasses={saturdayClasses}
                quantityOfSundayClasses={quantityOfSundayClasses}
                sundayClasses={sundayClasses}
                addTimetableModalShow={addTimetableModalShow}
                addNewTimeAtTimetable={addNewTimeAtTimetable}
                startTimeBeingAdded={startTimeBeingAdded}
                endTimeBeingAdded={endTimeBeingAdded}
                dayBeingAddedAtTimetable={dayBeingAddedAtTimetable}
                timetableDaysOptions={timetableDaysOptions}
                setDayBeingAddedAtTimetable={setDayBeingAddedAtTimetable}
                handleStartTimeBeingAddedChange={handleStartTimeBeingAddedChange}
                vacanciesQty={vacanciesQty}
                handleVacanciesQtyChange={handleVacanciesQtyChange}
                dayTimesModalShow={dayTimesModalShow}
                dayInfoBeingShow={dayInfoBeingShow}
                removeTimeAtTimetable={removeTimeAtTimetable}
                hasClasses={hasClasses}
              />
            </Col>
          )}
          <Col>
            <StyledContainer>
              <div className="card-title">
                Foto *
              </div>
              <FilePreview
                selectedFile={imageFile}
                setSelectedFile={setImageFile}
                fileType="image"
                noDataLabel="Por favor, escolha uma imagem para ser a capa (thumbnail) de sua atividade. Essa será a imagem mostrada no aplicativo. OBRIGATÓRIO"
              />
            </StyledContainer>
          </Col>
          {kindOfActivity === 'video' && (
            <Col>
              <StyledContainer>
                <div className="card-title">
                  Vídeo *
                </div>
                <FilePreview
                  selectedFile={videoFile}
                  setSelectedFile={setVideoFile}
                  fileType="video"
                  noDataLabel="Por favor, realize o upload do vídeo da atividade clicando no botão ESCOLHER VÍDEO acima. Caso sua aula seja ao-vivo, altere o campo selecionado no começo do formulário"
                />
              </StyledContainer>
            </Col>
          )}
        </Row>
        <ButtonContainer>
          {!edit && (
            <Button disabled={!isFormValid} onClick={createActivity}>
              {`Adicionar atividade ${name ?? ''}`}
            </Button>
          )}

          {edit && (
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            <Button disabled={!isFormValid} onClick={() => {}}>
              {`Editar atividade ${name ?? ''}`}
            </Button>
          )}
        </ButtonContainer>
      </Container>
    </>
  );
}
