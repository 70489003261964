import { useCallback, useState } from 'react';
import useApiCall from '../../../hooks/useApiCall';
import givenClassesService from '../../../services/givenClassesService';
import { CurrrentMonthBeingShowType } from '../types';
import useLoadFinancial from './functions/useLoadFinancial';
import useNfUpload from './functions/useNfUpload';
import useRemoveNf from './functions/useRemoveNf';

export default function useFinancial() {
  const currentDate = new Date().toJSON().slice(0, 10);
  const currentYear = Number(currentDate.split('-')[0]);
  const currentMonth = currentDate.split('-')[1];
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const startDate = '01/04/2023';
  const startYear = Number(startDate.split('/')[2]);
  const startMonth = Number(startDate.split('/')[1]);
  const yearsArraySinceStart = [];
  for (let i = startYear; i <= currentYear; i++) {
    yearsArraySinceStart.push(i);
  }

  const [dateModalShow, setDateModalShow] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(Number(currentMonth));
  const [selectedYear, setSelectedYear] = useState(Number(currentYear));
  const [currentMonthBeingShow, setCurrentMonthBeingShow] = useState<CurrrentMonthBeingShowType | ''>
  (Number(currentMonth) as CurrrentMonthBeingShowType);
  const [currentYearBeingShow, setCurrentYearBeingShow] = useState(Number(currentYear));

  const [givenClassesModalShow, setGivenClassesModalShow] = useState(false);

  const {
    loadFinancial,
    isLoadLoading,
    financialData,
    financialDataError,
    setIsLoadLoading,
  } = useLoadFinancial();

  const {
    isSubmitting,
    nfUploadModalShow,
    setNfUploadModalShow,
    nfFileName,
    handleNfFileUpload,
    handleNfFileSubmit,
  } = useNfUpload({
    currentMonthBeingShow,
    currentYearBeingShow,
    loadFinancial,
  });

  const { removeNf } = useRemoveNf({
    setIsLoading: setIsLoadLoading,
    loadFinancialData: loadFinancial,
  });

  const { apiCall } = useApiCall();

  const getFinancialRelatoryPreview = useCallback(async () => {
    await apiCall({
      apiToCall: givenClassesService.downloadPdfRelatoryByPartner,
      queryParams: {
        month: currentMonthBeingShow,
        year: currentYearBeingShow,
      },
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: async (response: Blob) => {
        console.log(response);
        const url = window.URL.createObjectURL(response);
        window.open(url, '_blank');
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', `Relatorio_Repasse_Financeiro_${currentMonthBeingShow}_${currentYearBeingShow}.pdf`);
        // document.body.appendChild(link);
        // link.click();

        // Revoga o URL após o download
        window.URL.revokeObjectURL(url);
      }
    });
  }, [apiCall, currentMonthBeingShow, currentYearBeingShow, setIsLoadLoading]);

  const isLoading = isSubmitting || isLoadLoading;

  function handleTryAgain() {
    loadFinancial();
  }

  return {
    isLoading,
    months,
    currentYear,
    yearsArraySinceStart,
    currentMonth,
    dateModalShow,
    setDateModalShow,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    currentMonthBeingShow,
    setCurrentMonthBeingShow,
    currentYearBeingShow,
    setCurrentYearBeingShow,
    nfUploadModalShow,
    setNfUploadModalShow,
    nfFileName,
    handleNfFileUpload,
    handleNfFileSubmit,
    startDate,
    startYear,
    startMonth,
    financialData,
    financialDataError,
    handleTryAgain,
    givenClassesModalShow,
    setGivenClassesModalShow,
    getFinancialRelatoryPreview,
    removeNf,
  };
}
