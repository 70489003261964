import HttpClient from './utils/HttpClient';
import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';

interface SchedulesServiceInterface {
  date?: string;
  token: string;
  reqBody?: ReqBodyType;
}

class SchedulesService {
  private readonly httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getScheduleList = async({
    date,
    token,
  }: SchedulesServiceInterface) => {
    return this.httpClient.get({
      path: `/appointments?date=${date}`,
      token,
    });
  };
}

export default new SchedulesService();
