import { format } from 'date-fns';
import { Col, Container, Row } from 'react-bootstrap';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import floatToCurrency from '../../../../../utils/floatToCurrency';
import { GivenClassType } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: GivenClassType[];
}

export default function List({
  filteredList,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((gvCl) => (
          <Col key={gvCl.id}>
            <OpacityAnimation delay={0.1}>
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{gvCl.activity.nome}</strong>
                    <small>
                      {`${format(new Date(gvCl.date), 'dd/MM/yyyy')} | ${gvCl.startTime} - ${gvCl.endTime}`}
                    </small>
                  </div>
                  <span>
                    Valor:
                    {' '}
                    {floatToCurrency(gvCl.activity.classValue)}
                  </span>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
