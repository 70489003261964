import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import activitiesService from '../../../../../../../services/activitiesService';
import isDateBeforeToday from '../../../../../../../utils/isDateBeforeToday';
import { TimetableFromApiType } from '../../../../../types/TimetableFromApiType';
import toPersistenceTimetable from '../../../../../utils/toPersistenceTimetable';
import formatDate from '../../../../../../../utils/formatDate';
import { getDay, parse } from 'date-fns';
import formatDateToUsa from '../../../../../../../utils/formatDateToUsa';
import useApiCall from '../../../../../../../hooks/useApiCall';

interface UseDisableDateInterface {
  activityId: string;
  activityName: string;
  reloadActivity: () => void;
  timetable: TimetableFromApiType;
  datesUnderDisableAprovement?: string[];
  formattedDates: string[];
  hasFutureDisabledDates: boolean;
  hasUnderDisableAprovementDates: boolean;
}

type WeekdaysType = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export default function useDisableDate({
  activityId,
  activityName,
  reloadActivity,
  timetable,
  datesUnderDisableAprovement,
  formattedDates,
  hasFutureDisabledDates,
  hasUnderDisableAprovementDates,
}: UseDisableDateInterface) {
  const [isDisabling, setIsDisabling] = useState(false);
  const [selectedDisableDate, setSelectedDisableDate] = useState('');
  const [disableDateModalShow, setDisableDateModalShow] = useState(false);

  const [isSpecificTime, setIsSpecificTime] = useState(false);
  const [timesOptions, setTimesOptions] = useState<{ value: string, label: string }[]>([]);
  const [selectedDisableTime, setSelectedDisableTime] = useState<{ value: string,  label: string }>({} as { value: string,  label: string });

  const isFutureDateChoosed = useMemo(
    () => !isDateBeforeToday(selectedDisableDate),
    [selectedDisableDate],
  );

  const { apiCall } = useApiCall();

  const translatedTimetable = toPersistenceTimetable(timetable);

  const disableDate = useCallback(async () => {
    const formattedDisableDate = formatDateToUsa(selectedDisableDate);

    const disabledDates = hasFutureDisabledDates ? formattedDates?.map((date) => formatDateToUsa(date)) : [];
    const underDisableApprovement = hasUnderDisableAprovementDates ? datesUnderDisableAprovement?.map((date) => formatDateToUsa(date)) : [];

    const isOptionAlreadyDisabled = isSpecificTime
      ? disabledDates?.includes(`${formattedDisableDate}_${selectedDisableTime.value}`)
        || disabledDates?.includes(formattedDisableDate!)
        || underDisableApprovement?.includes(`${formattedDisableDate}_${selectedDisableTime.value}`)
        || underDisableApprovement?.includes(formattedDisableDate!)
      : disabledDates?.includes(formattedDisableDate!)
      || underDisableApprovement?.includes(formattedDisableDate!);

    if (isOptionAlreadyDisabled) {
      toast.error('Essa opção já está desabilitada, ou aguardando aprovação para ser desabilitada');
      return;
    }

    await apiCall({
      apiToCall: activitiesService.updateActivity,
      queryParams: { activityId },
      reqBody: JSON.stringify({
        nome: activityName,
        datasDesabilitadas: {
          data: isSpecificTime ? `${formattedDisableDate}_${selectedDisableTime.value}` : formattedDisableDate,
          acao: 'desabilitar',
        },
      }),
      onStartLoad: () => setIsDisabling(true),
      onEndLoad: () => setIsDisabling(false),
      actionAfterResponse: (response) => {
        if (!response.success) {
          toast.error('Não foi possível desabilitar a data');
          setIsDisabling(false);
          return;
        }
        setDisableDateModalShow(false);
        reloadActivity();
        toast.success('A desabilitação da data foi enviada para análise!');
      },
      catchMessage: 'Não foi possível desabilitar a data',
    });
  }, [selectedDisableDate, hasFutureDisabledDates, datesUnderDisableAprovement, hasUnderDisableAprovementDates, formattedDates, isSpecificTime, selectedDisableTime.value, apiCall, activityId, activityName, reloadActivity]);

  function handleDisableDateChange(event: ChangeEvent<HTMLInputElement>) {
    setSelectedDisableDate(event.target.value);
    setSelectedDisableTime({ value: '', label: '' });
    const formattedDate = formatDate(event.target.value);
    if (!formattedDate) {
      return;
    }
    const parsedDate = parse(formattedDate, 'dd/MM/yyyy', new Date());
    const weekDaysLiterals: { [key: number]: WeekdaysType } = {
      0: 'sunday',
      1: 'monday',
      2: 'tuesday',
      3: 'wednesday',
      4: 'thursday',
      5: 'friday',
      6: 'saturday'
    };
    const weekday: WeekdaysType = weekDaysLiterals[getDay(parsedDate)];
    const hasTimesAtSelectedDay = translatedTimetable[weekday].length !== 0;
    if (!hasTimesAtSelectedDay) {
      toast.warn(`Não há nenhuma aula agendada para o dia ${formattedDate}`);
      setSelectedDisableDate('');
      return;
    }
    const timesAtSelectedDay = translatedTimetable[weekday].map((time) => `${time.startTime}-${time.endTime}`);
    const disableTimesOptions = timesAtSelectedDay.map((time) => ({ value: time, label: time }));
    setTimesOptions(disableTimesOptions);
  }

  return {
    isDisabling,
    setDisableDateModalShow,
    disableDateModalShow,
    selectedDisableDate,
    handleDisableDateChange,
    disableDate,
    isFutureDateChoosed,
    isSpecificTime,
    setIsSpecificTime,
    timesOptions,
    selectedDisableTime,
    setSelectedDisableTime,
  };
}
