import FormGroup from '../../../../../../components/FormGroup';
import MyModal from '../../../../../../components/Modal';
import Textarea from '../../../../../../components/Textarea';
import { GetErrorMessageByFieldNameType } from '../../../../../../hooks/useErrors';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

interface DescriptionModalInterface {
  descriptionModalShow: boolean;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  description: string;
  handleDescriptionChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  setDescriptionModalShow: Dispatch<SetStateAction<boolean>>;
  changeDesciption: () => void;
}

export default function DescriptionModal({
  descriptionModalShow,
  getErrorMessageByFieldName,
  description,
  handleDescriptionChange,
  setDescriptionModalShow,
  changeDesciption,
}: DescriptionModalInterface) {
  return (
    <MyModal
      show={descriptionModalShow}
      title="Alterar descrição"
      modalBody={(
        <FormGroup error={getErrorMessageByFieldName('description')}>
          <label htmlFor="contactName">Descrição</label>
          <Textarea
            placeholder="Conte um pouco sobre a atividade..."
            value={description}
            onChange={handleDescriptionChange}
            darkBorder
          />
        </FormGroup>
      )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Alterar descrição"
      onClose={() => setDescriptionModalShow(false)}
      onAction={() => changeDesciption()}
      isActionButtonDisabled={!description}
      type="action"
    />
  );
}
