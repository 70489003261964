import Sidebar from '../../../components/Sidebar';
import Transitions from '../../../components/Transition';
import PageHeader from '../../../components/PageHeader';
import ActivityForm from './components/ActivityForm';

export default function NewActivity() {
  return (
    <>
      <Sidebar active="Activities" />
      <Transitions>
        <PageHeader
          title="Nova Atividade"
          link="/activities"
        />
        <ActivityForm />
      </Transitions>
    </>
  );
}
