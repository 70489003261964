import { Card } from '../../../styles';
import clock from '../../../../../../../../assets/images/icons/clock.svg';
import { Dispatch, SetStateAction } from 'react';
import { ClassesInfoType, DayInfoBeingShowType } from '../../../../../../types/TimetableTypes';

interface DayCardInterface {
  day: string;
  dayEn: string;
  quantityOfClasses: number;
  classes: ClassesInfoType[];
  setDayTimesModalShow: Dispatch<SetStateAction<boolean>>;
  setDayInfoBeingShow: Dispatch<SetStateAction<DayInfoBeingShowType>>;
}

export default function DayCard({
  day,
  dayEn,
  quantityOfClasses,
  classes,
  setDayTimesModalShow,
  setDayInfoBeingShow,
}: DayCardInterface) {
  return (
    <Card>
      <div className="info">
        <div className="card-title">
          <strong>{day}</strong>
          <small>
            {
              quantityOfClasses > 1
                ? `${quantityOfClasses} horários`
                : `${quantityOfClasses} horário`
            }
          </small>
        </div>
      </div>
      <div className="actions">
        <button
          type="button"
          onClick={() => {
            setDayTimesModalShow(true);
            setDayInfoBeingShow({
              day,
              dayEn,
              list: classes,
            });
          }}
        >
          <img src={clock} alt="horarios" title="Visualizar horários das aulas" className="primaryColor" />
        </button>
      </div>
    </Card>
  );
}
