import { useCallback, useEffect, useState } from 'react';
import useApiCall from '../../../../hooks/useApiCall';
import financialService from '../../../../services/financialService';
import { FinancialDataType } from '../../types';

export default function useLoadFinancial() {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [financialData, setFinancialData] = useState<FinancialDataType>({});
  const [financialDataError, setFinancialDataError] = useState(false);

  const { apiCall } = useApiCall();

  const loadFinancial = useCallback(async () => {
    await apiCall({
      apiToCall: financialService.getFinancialData,
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        setFinancialData(apiResponse.details);
        setFinancialDataError(false);
      },
      catchMessage: 'Não foi possível carregar os dados financeiros',
      catchAction: () => setFinancialDataError(true),
    });
  }, [apiCall]);

  useEffect(() => {
    loadFinancial();
  }, [loadFinancial]);

  return {
    loadFinancial,
    isLoadLoading,
    financialData,
    financialDataError,
    setIsLoadLoading,
  };
}
