import intervalToDuration from 'date-fns/intervalToDuration';
import { Card } from '../../styles';

interface PaymentStatusCardInterface {
  paymentDate?: string;
}

export default function PaymentStatusCard({ paymentDate }: PaymentStatusCardInterface) {
  const currentDate = new Date().toJSON().slice(0, 10);
  const currentDay = Number(currentDate.split('-')[2]);
  const currentMonth = Number(currentDate.split('-')[1]);
  const currentYear = Number(currentDate.split('-')[0]);
  const currentDateNumber = Number(`${currentYear}${currentMonth}${currentDay}`);

  const paymentDay = Number(paymentDate?.split('/')[0]);
  const paymentMonth = Number(paymentDate?.split('/')[1]);
  const paymentYear = Number(paymentDate?.split('/')[2]);
  const paymentDateNumber = Number(`${paymentYear}${paymentMonth}${paymentDay}`);

  const differenceBetweenDates = paymentDate
    ? (intervalToDuration({
      end: new Date(currentYear, Number(currentMonth - 1), currentDay),
      start: new Date(paymentYear, Number(paymentMonth - 1), paymentDay),
    }))
    : null;

  const isAfterCurrentDate = currentDateNumber > paymentDateNumber;
  const isBeforeCurrentDate = paymentDateNumber > currentDateNumber;
  const differenceBetweenDatesInDays = differenceBetweenDates?.days;
  const differenceBetweenDatesInMonths = differenceBetweenDates?.months;
  const differenceBetweenDatesInYears = differenceBetweenDates?.years;

  return (
    <Card>
      <div className="card-title">
        <div>Status do pagamento</div>
      </div>

      {!!paymentDate && (
        <div className="card-main">
          <div>
          Pago em
            {' '}
            {paymentDate}
          </div>
          <small>
            {(isAfterCurrentDate && differenceBetweenDatesInDays != 0) && (
              `${differenceBetweenDatesInYears
                ? `${differenceBetweenDatesInYears} ${differenceBetweenDatesInYears != 1 ? 'anos' : 'ano'}, `
                : ''}
            ${differenceBetweenDatesInMonths
                ? `${differenceBetweenDatesInMonths} ${differenceBetweenDatesInMonths != 1 ? 'meses' : 'mês'}, e `
                : ''}
             ${differenceBetweenDatesInDays} dias atrás`
            )}
            {(isBeforeCurrentDate && differenceBetweenDatesInDays != 0) && (
              `Daqui a ${differenceBetweenDatesInDays} dia(s)`
            )}
            {differenceBetweenDatesInDays == 0 && (
              'Hoje'
            )}
          </small>
        </div>
      )}

      {(!paymentDate) && (
        <div className="card-main">
          <div>
          Pendente
          </div>
          <small>
          Caso já tenha sido pago, verifique novamente mais tarde.
          </small>
        </div>
      )}
    </Card>
  );
}
