import { FilterRadioButtonsContainer } from '../../../../../../../components/FilterRadioButtonsContainer';
import FilterRadioButton from '../../../../../../../components/FilterRadioButtons';
import { Container } from './styles';

interface DurationInterface {
  handleDurationChange: (duration: number) => void;
  duration: number;
}

export default function Duration({ handleDurationChange, duration }: DurationInterface) {
  return (
    <Container>
      <h2>Duração da aula:</h2>
      <FilterRadioButtonsContainer>
        <FilterRadioButton
          onClick={() => handleDurationChange(30)}
          selected={duration === 30}
        >
          30 minutos
        </FilterRadioButton>
        <FilterRadioButton
          onClick={() => handleDurationChange(40)}
          selected={duration === 40}
        >
          40 minutos
        </FilterRadioButton>
        <FilterRadioButton
          onClick={() => handleDurationChange(60)}
          selected={duration === 60}
        >
          1 hora
        </FilterRadioButton>
        <FilterRadioButton
          onClick={() => handleDurationChange(90)}
          selected={duration === 90}
        >
          1 hora e 30 minutos
        </FilterRadioButton>
      </FilterRadioButtonsContainer>
    </Container>
  );
}
