import { format } from 'date-fns';
import {
  ChangeEvent,
  useCallback, useEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useAppContext } from '../../contexts/auth';
import useApiCall from '../../hooks/useApiCall';
import activitiesService from '../../services/activitiesService';
import ExportXlsx from '../../utils/ExportXlsx';
import getCurrentDateWithHyphen from '../../utils/getCurrentDateWithHyphen';

type ActivitiesType = {
    id: string;
    name: string;
    status: string;
    hasRatings: boolean;
    generalData: {
      category: string,
      subcategory: string,
      kind: 'Aula em vídeo' | 'Aula ao vivo';
    };
};

type ApiResponseType = {
  list?: {
    id: string,
    nome: string,
    status: string,
    categoria: string,
    subcategoria: string,
    tipo: 'live' | 'record',
    hasRatings: boolean,
  }[];
  error?: string;
};

type RatingsFromApi = {
  ratings: {
    userName: string;
    userPhone: string;
    userEmail: string;
    ratedAt: Date;
    activity: string;
    partner: string;
    teacherRate: number;
    contentRate: number;
    durationRate?: string;
    comment?: string;
  }[],
  success: boolean;
}

export default function useActivities() {
  const [isLoading, setIsLoading] = useState(true);
  const [activities, setActivities] = useState<ActivitiesType[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const { signOut } = useAppContext();
  const { apiCall } = useApiCall();

  const filteredActivities = useMemo(() => activities?.filter((activity) => (
    activity.name.toLowerCase().startsWith(searchTerm.toLowerCase())
  )), [activities, searchTerm]);

  const loadActivities = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getActivitiesList,
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (bodyGetActivities: ApiResponseType) => {
        if (typeof bodyGetActivities === 'string') {
          signOut();
          return;
        }
        if (!bodyGetActivities.list) {
          toast.error(`Não foi possível carregar a lista de atividades (${bodyGetActivities.error})`, {
            toastId: 'loadActivitiesFail',
          });
          setDoesListApiHasError(true);
          setIsLoading(false);
          return;
        }
        const activitiesList = bodyGetActivities.list;
        const mappedActivitiesList = activitiesList?.map((activity) => ({
          id: activity.id,
          name: activity.nome,
          status: activity.status,
          hasRatings: activity.hasRatings,
          generalData: {
            category: activity.categoria,
            subcategory: activity.subcategoria,
            kind: (activity.tipo === 'live' ? 'Aula ao vivo' : 'Aula em vídeo') as 'Aula em vídeo' | 'Aula ao vivo',
          },
        }));
        setActivities(mappedActivitiesList!);
        setDoesListApiHasError(false);
      },
      catchMessage: 'Não foi possível carregar a lista de atividades',
      catchAction: () => setDoesListApiHasError(true),
    });
  }, [apiCall, signOut]);

  const downloadRatings = useCallback(async (activityId: string) => {
    await toast.promise(apiCall({
      apiToCall: activitiesService.getActivityRatings,
      queryParams: { activityId },
      actionAfterResponse: (apiResponse: RatingsFromApi) => {
        const ratings = apiResponse.ratings;
        if (!ratings) {
          toast.error('Não foi possível baixar a lista de avaliações dessa atividade', {
            toastId: 'downloadActivitiesRatingsFail',
          });
          return;
        }

        const mappedRatingsList = ratings.map((rt) => ({
          'Data Avaliação': format(new Date(rt.ratedAt), 'dd/MM/yyyy \'às\' HH\'h\'mm\'m\''),
          'Nota Professor (1 a 5)': rt.teacherRate,
          'Nota Conteúdo (1 a 5)': rt.contentRate,
          'Duração da aula': rt.durationRate ?? 'Não Informado',
          'Comentário Adicional': rt.comment ?? '',
        }));

        const { activity, partner } = ratings[0];

        const currentDate = getCurrentDateWithHyphen();
        ExportXlsx({
          filename: `Avaliações ${activity} (${partner}) - ${currentDate}`,
          data: mappedRatingsList,
        });
      },
      catchMessage: 'Não foi possível baixar a lista de avaliações'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    });
  }, [apiCall]);

  const downloadAllRatingsExcel = useCallback(async () => {
    await toast.promise(apiCall({
      apiToCall: activitiesService.getAllActivitiesRatings,
      actionAfterResponse: (apiResponse: RatingsFromApi) => {
        const ratings = apiResponse.ratings;
        if (!ratings) {
          toast.error('Não foi possível baixar a lista de avaliações das atividades', {
            toastId: 'downloadActivitiesRatingsFail',
          });
          return;
        }

        if (ratings.length === 0) {
          toast.error('Não há nenhuma atividade com avaliação', {
            toastId: 'downloadActivitiesRatingsFail',
          });
          return;
        }

        const mappedRatingsList = ratings.map((rt) => ({
          Atividade: rt.activity,
          'Data Avaliação': format(new Date(rt.ratedAt), 'dd/MM/yyyy \'às\' HH\'h\'mm\'m\''),
          'Nota Professor (1 a 5)': rt.teacherRate,
          'Nota Conteúdo (1 a 5)': rt.contentRate,
          'Duração da aula': rt.durationRate ?? 'Não informado',
          'Comentário Adicional': rt.comment ?? '',
        }));

        const { partner } = ratings[0];

        const currentDate = getCurrentDateWithHyphen();
        ExportXlsx({
          filename: `Avaliações todas as atividades ${partner} - ${currentDate}`,
          data: mappedRatingsList,
        });
      },
      catchMessage: 'Não foi possível baixar a lista de avaliações'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    });
  }, [apiCall]);

  useEffect(() => {
    loadActivities();
  }, [loadActivities]);

  function handleTryAgain() {
    loadActivities();
  }

  function handleChangeSearchTerm(event: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }

  return {
    isLoading,
    setIsLoading,
    activities,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredActivities,
    handleTryAgain,
    loadActivities,
    downloadRatings,
    downloadRatingsExcel: downloadAllRatingsExcel,
  };
}
