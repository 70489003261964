import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../../hooks/useApiCall';
import forumService from '../../../../../../services/forumService';
import { CommentType } from '../../../../types';
import { ViewType } from './types';

interface IUseCommentActions {
  comment: CommentType;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadForum: () => Promise<void>;
}

export default function useCommentActions({ comment, setIsLoading, loadForum }: IUseCommentActions) {
  const [view, setView] = useState<ViewType>('default');
  const [commentText, setCommentText] = useState(comment.text);

  const { apiCall } = useApiCall();

  function handleCommentTextChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setCommentText(event.target.value);
  }

  const editComment = useCallback(async () => {
    await apiCall({
      apiToCall: forumService.updateComment,
      queryParams: { id: comment.id },
      reqBody: JSON.stringify({
        text: commentText,
      }),
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: async (response) => {
        if (!response.success) {
          toast.error('Não foi possível editar o comentário. Por favor, tente novamente');
          return;
        }
        setCommentText('');
        setView('default');
        await loadForum();
        toast.success('Comentário editado com sucesso!');
      }
    });
  }, [apiCall, comment.id, commentText, loadForum, setIsLoading]);

  const deleteComment  = useCallback(async () => {
    await apiCall({
      apiToCall: forumService.deleteComment,
      queryParams: { id: comment.id },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: async (response) => {
        if (!response.success) {
          toast.error('Não foi possível remover o comentário. Por favor, tente novamente');
          return;
        }
        setView('default');
        await loadForum();
        toast.success('Comentário removido com sucesso!');
      }
    });
  }, [apiCall, comment.id, loadForum, setIsLoading]);

  return {
    view,
    setView,
    editComment,
    deleteComment,
    handleCommentTextChange,
    commentText,
  };
}
