import { Dispatch, SetStateAction } from 'react';
import formatDate from '../../../../../../utils/formatDate';
import { Card } from '../styles';

interface DatesInterface {
  date: string;
  setRehabilitateDate: Dispatch<SetStateAction<string>>;
  setRehabilitateDateModalShow: Dispatch<SetStateAction<boolean>>;
  disableImage: string;
}

export default function Dates({
  date,
  // setRehabilitateDate,
  // setRehabilitateDateModalShow,
  // disableImage,
}: DatesInterface) {
  return (
    <Card>
      <div className="info">
        <div className="card-title">
          <strong>{formatDate(date?.split('_')[0])}</strong>
          <small>
            {date?.includes('_') ? date?.split('_')[1] : 'Dia inteiro'}
          </small>
        </div>
      </div>
      {/* <div className="actions">
        <button
          type="button"
          onClick={() => {
            setRehabilitateDate(date);
            setRehabilitateDateModalShow(true);
          }}
        >
          <img
            src={disableImage}
            alt="delete"
            title="Reabilitar data"
          />
        </button>
      </div> */}
    </Card>
  );
}
