import Select from 'react-select';
import { Container as StyledContainer, Group, AsideContainer } from '../styles';

import Input from '../../../../../../components/Input';
import FormGroup from '../../../../../../components/FormGroup';
import Textarea from '../../../../../../components/Textarea';

import { CustomStyle } from '../../../../../../components/CustomSelectStyle';
import info from '../../../../../../assets/images/icons/info.svg';
import { GetErrorMessageByFieldNameType } from '../../../../../../hooks/useErrors';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Value } from '../../../../types/CalendarTypes';
import PeriodSelectionModal from './PeriodSelectionModal';

interface GeneralDataCardInterface {
  specificPeriod: Value;
  handleSpecificPeriodChange: (value: Value, event: React.MouseEvent<HTMLButtonElement>) => void;
  setPeriodSelectionModalShow: Dispatch<SetStateAction<boolean>>;
  specificPeriodLabel: string;
  periodSelectionModalShow: boolean;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  name: string;
  handleNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  category: {
    value: string,
    label: string,
  };
  categories: {
    value: string;
    label: string;
  }[];
  setCategory: Dispatch<SetStateAction<{
    value: string;
    label: string;
  }>>;
  isCategoriesSelectLoading: boolean;
  subcategory: {
    value: string;
    label: string;
  };
  subcategoriesOptions: {
    value: string;
    label: string;
  }[];
  setSubcategory: Dispatch<SetStateAction<{
    value: string;
    label: string;
  }>>;
  setMinAge: Dispatch<SetStateAction<number | string>>;
  setMaxAge: Dispatch<SetStateAction<number | string>>;
  handleDescriptionChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  description: string;
  kindOfActivity: string;
  handleVideoLinkChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  videoLink: string;
  handleMethodologyChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  methodology: string;
  handleOrientationsChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  orientations: string;
  isRecurrent: {
    value: boolean;
    label: string;
  };
  setIsRecurrent: Dispatch<SetStateAction<{
    value: boolean;
    label: string;
  }>>;
  recurrentOptions: {
    value: boolean;
    label: string;
  }[];
}

export default function GeneralDataCard({
  getErrorMessageByFieldName,
  name,
  handleNameChange,
  category,
  categories,
  setCategory,
  isCategoriesSelectLoading,
  subcategory,
  subcategoriesOptions,
  setSubcategory,
  setMinAge,
  setMaxAge,
  handleDescriptionChange,
  description,
  kindOfActivity,
  handleVideoLinkChange,
  videoLink,
  handleMethodologyChange,
  methodology,
  handleOrientationsChange,
  orientations,
  isRecurrent,
  setIsRecurrent,
  recurrentOptions,
  handleSpecificPeriodChange,
  specificPeriod,
  setPeriodSelectionModalShow,
  specificPeriodLabel,
  periodSelectionModalShow,
}: GeneralDataCardInterface) {
  return (
    <StyledContainer>
      <div className="card-title">
        Dados Gerais
      </div>
      <AsideContainer>
        <Group>
          <div className="title">
            Nome *
          </div>
          <FormGroup error={getErrorMessageByFieldName('name')}>
            <Input
              value={name}
              onChange={handleNameChange}
              error={getErrorMessageByFieldName('name')}
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Categoria *
          </div>
          <Select
            value={{ value: category?.value, label: category?.label }}
            options={categories}
            onChange={(opt) => {
              setCategory({ value: opt!.value, label: opt!.label });
            }}
            placeholder="Selecione uma categoria"
            styles={CustomStyle}
            isDisabled={isCategoriesSelectLoading}
            isLoading={isCategoriesSelectLoading}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        <Group>
          <div className="title">
            Subcategoria *
          </div>
          <Select
            value={{ value: subcategory?.value, label: subcategory?.label }}
            options={subcategoriesOptions}
            onChange={(opt) => {
              setSubcategory({ value: opt!.value, label: opt!.label });
              if (opt!.value === 'Educação parental') {
                setMinAge(18);
                setMaxAge('Não possui limite de idade');
              }
            }}
            placeholder="Selecione uma subcategoria"
            styles={CustomStyle}
            isDisabled={isCategoriesSelectLoading || !category.value}
            isLoading={isCategoriesSelectLoading}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        <Group>
          <div className="title">
            <div>
              Atividade recorrente?
              {' '}
              <img src={info} alt="interrogation" title="Permite o agendamento automático desta atividade pelos alunos para todo o mês" />
            </div>
          </div>
          <Select
            value={{ value: isRecurrent?.value, label: isRecurrent?.label }}
            options={recurrentOptions}
            onChange={(opt) => {
              setIsRecurrent({ value: opt!.value, label: opt!.label });
            }}
            placeholder="Selecione uma opção"
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        {!isRecurrent.value && (
          <Group>
            <div className="title">
              <div>
                  Período em que ocorrerá
              </div>
            </div>
            <FormGroup error={getErrorMessageByFieldName('specificPeriod')}>
              <Input
                value={specificPeriodLabel}
                periodInput
                onClick={() => setPeriodSelectionModalShow(true)}
                error={getErrorMessageByFieldName('specificPeriod')}
                readOnly
              />
            </FormGroup>
          </Group>
        )}
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            <div>
              Descrição *
            </div>
          </div>
          <FormGroup error={getErrorMessageByFieldName('description')}>
            <Textarea
              onChange={handleDescriptionChange}
              placeholder="Descreva com mais detalhes sua atividade"
              value={description}
              height={100}
            />
          </FormGroup>
        </Group>
        {kindOfActivity === 'online' && (
          <Group link>
            <div className="title">
            Link da aula *
            </div>
            <FormGroup error={getErrorMessageByFieldName('videoLink')}>
              <Textarea
                onChange={handleVideoLinkChange}
                placeholder="Insira o link da sua aula online"
                value={videoLink}
                height={100}
              />
            </FormGroup>
          </Group>
        )}
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            Metodologia
          </div>
          <Textarea
            onChange={handleMethodologyChange}
            placeholder="Explique sobre a metodologia de ensino desta atividade"
            value={methodology}
            height={100}
          />
        </Group>
        <Group>
          <div className="title">
            Orientações
          </div>
          <Textarea
            onChange={handleOrientationsChange}
            placeholder="Insira aqui orientações adicionais para sua atividade"
            value={orientations}
            height={100}
          />
        </Group>
      </AsideContainer>

      <PeriodSelectionModal
        periodSelectionModalShow={periodSelectionModalShow}
        setPeriodSelectionModalShow={setPeriodSelectionModalShow}
        handleSpecificPeriodChange={handleSpecificPeriodChange}
        specificPeriod={specificPeriod}
      />
    </StyledContainer>
  );
}
