import { Col, Container, Row } from 'react-bootstrap';
import MyModal from '../../../../../../../../components/Modal';
import { TimesContainer, TimesGroup } from '../../../styles';
import trash from '../../../../../../../../assets/images/icons/trash.svg';
import { Dispatch, SetStateAction } from 'react';
import { ClassesInfoType, DayInfoBeingShowType } from '../../../../../../types/TimetableTypes';

interface TimesModalInterface {
  dayTimesModalShow: boolean;
  setDayTimesModalShow: Dispatch<SetStateAction<boolean>>;
  dayInfoBeingShow: DayInfoBeingShowType;
  removeTimeAtTimetable: (time: ClassesInfoType, dayEn: string) => void;
}

export default function TimesModal({
  dayTimesModalShow,
  setDayTimesModalShow,
  dayInfoBeingShow,
  removeTimeAtTimetable,
}: TimesModalInterface) {
  return (
    <MyModal
      show={dayTimesModalShow}
      onClose={() => setDayTimesModalShow(false)}
      title={`Horários de ${dayInfoBeingShow.day}`}
      closeButtonLabel="Fechar"
      modalBody={(
        <TimesContainer>
          <Container>
            <Row xs={1} md={2} lg={2}>
              {dayInfoBeingShow?.list?.map((time) => (
                <Col key={`${time.startTime}${time.endTime}${time.vacanciesQty}`}>
                  <TimesGroup>
                    <div className="info">
                      <div className="card-title">
                        <strong>{`Das ${time.startTime} às ${time.endTime}`}</strong>
                        <small>{`${time.vacanciesQty} vagas`}</small>
                      </div>
                    </div>
                    <div className="actions">
                      <button
                        type="button"
                        onClick={() => removeTimeAtTimetable(time, dayInfoBeingShow.dayEn)}
                      >
                        <img src={trash} alt="delete" title="Remover horário" />
                      </button>
                    </div>
                  </TimesGroup>
                </Col>
              ))}
            </Row>
          </Container>
        </TimesContainer>
      )}
      size='lg'
    />
  );
}
