import { Col, Container, Row } from 'react-bootstrap';
import { Container as StyledContainer } from '../../styles';

import Loader from '../../../../../components/Loader';
import NoData from '../../../../../components/NoData';

import clock from '../../../../../assets/images/icons/clock.svg';
// import plus from '../../../../../assets/images/icons/plus.svg';
import trash from '../../../../../assets/images/icons/trash.svg';
import useDisabledDatesCard from './useDisabledDatesCard';
// import Title from './components/Title';
import { DatesUnderAprovementType } from '../../../types/DetailsTypes';
import { TimetableFromApiType } from '../../../types/TimetableFromApiType';
import Dates from './components/Dates';
import DisableModal from './components/DisableModal';
import RehabilitateModal from './components/RehabilitateModal';
import WaitingApprovement from './components/WaitingApprovement';

interface DisabledDatesCardInterface {
  dates: string[];
  datesUnderAprovement?: DatesUnderAprovementType;
  activityId: string;
  activityName: string;
  reloadActivity: () => void;
  timetable: TimetableFromApiType;
}

export default function DisabledDatesCard({
  dates,
  datesUnderAprovement,
  activityId,
  activityName,
  reloadActivity,
  timetable,
}: DisabledDatesCardInterface) {
  const {
    isLoading,
    setDisableDateModalShow,
    hasDatesToBeRendered,
    datesUnderDisableAprovement,
    formattedUnderRehabilitateAprovementDates,
    formattedDates,
    setRehabilitateDate,
    setRehabilitateDateModalShow,
    selectedRehabilitateDate,
    rehabilitateDate,
    disableDateModalShow,
    selectedDisableDate,
    handleDisableDateChange,
    disableDate,
    isFutureDateChoosed,
    rehabilitateDateModalShow,
    isSpecificTime,
    setIsSpecificTime,
    timesOptions,
    selectedDisableTime,
    setSelectedDisableTime,
  } = useDisabledDatesCard({
    dates,
    datesUnderAprovement,
    activityId,
    activityName,
    reloadActivity,
    timetable,
  });

  return (
    <>
      <Loader isLoading={isLoading} />
      <StyledContainer>

        {/* <Title addImage={plus} setDisableDateModalShow={setDisableDateModalShow} /> */}

        {hasDatesToBeRendered && (
          <Container>
            <Row xs={1} md={1} lg={1}>
              {datesUnderDisableAprovement?.map((date) => (
                <Col key={date}>
                  <WaitingApprovement
                    date={date}
                    waitImage={clock}
                    imageTitle="Aguardando aprovação para desabilitação da data"
                  />
                </Col>
              ))}
              {formattedUnderRehabilitateAprovementDates?.map((date) => (
                <Col key={date}>
                  <WaitingApprovement
                    date={date}
                    waitImage={clock}
                    imageTitle="Aguardando aprovação para reabilitação da data"
                  />
                </Col>
              ))}
              {formattedDates?.map((date) => (
                <Col key={date}>
                  <Dates
                    date={date}
                    setRehabilitateDate={setRehabilitateDate}
                    setRehabilitateDateModalShow={setRehabilitateDateModalShow}
                    disableImage={trash}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        )}

        {!hasDatesToBeRendered && (
          <NoData
            icon="emptyBox"
            label={(
              <>
              Não há nenhuma data desabilitada. Para desabilitar uma data, entre em contato conosco.
                {' '}
              </>
            )}
          />
        )}
      </StyledContainer>

      <RehabilitateModal
        rehabilitateDateModalShow={rehabilitateDateModalShow}
        selectedRehabilitateDate={selectedRehabilitateDate}
        setRehabilitateDateModalShow={setRehabilitateDateModalShow}
        rehabilitateDate={rehabilitateDate}
      />

      <DisableModal
        disableDateModalShow={disableDateModalShow}
        selectedDisableDate={selectedDisableDate}
        handleDisableDateChange={handleDisableDateChange}
        setDisableDateModalShow={setDisableDateModalShow}
        disableDate={disableDate}
        isFutureDateChoosed={isFutureDateChoosed}
        isSpecificTime={isSpecificTime}
        setIsSpecificTime={setIsSpecificTime}
        timesOptions={timesOptions}
        selectedDisableTime={selectedDisableTime}
        setSelectedDisableTime={setSelectedDisableTime}
      />
    </>
  );
}
