import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface FinancialServiceInterface {
  reqBody?: ReqBodyType;
  token: string;
}

class FinancialService {
  private readonly httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getFinancialData = async ({
    token,
  }: FinancialServiceInterface) => {
    return this.httpClient.get({
      path: '/financial',
      token,
    });
  };

  uploadNf = async ({
    reqBody,
    token,
  }: FinancialServiceInterface) => {
    return this.httpClient.post({
      path: '/financial',
      reqBody,
      token,
      contentType: 'multipart/form-data',
    });
  };

  removeNf = async ({
    reqBody,
    token,
  }: FinancialServiceInterface) => {
    return this.httpClient.patch({
      path: '/financial',
      reqBody,
      token,
    });
  };
}

export default new FinancialService();
