import Select from 'react-select';
import { CustomStyle } from '../../../../../../../../components/CustomSelectStyle';
import FormGroup from '../../../../../../../../components/FormGroup';
import Input from '../../../../../../../../components/Input';
import MyModal from '../../../../../../../../components/Modal';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

interface AddTimeModalInterface {
  addTimetableModalShow: boolean;
  setAddTimetableModalShow: Dispatch<SetStateAction<boolean>>;
  addNewTimeAtTimetable: () => void;
  startTimeBeingAdded: string;
  endTimeBeingAdded: string;
  dayBeingAddedAtTimetable: {
    value: string;
    label: string;
  };
  timetableDaysOptions: {
    value: string;
    label: string
  }[];
  setDayBeingAddedAtTimetable: Dispatch<SetStateAction<{
    value: string;
    label: string;
  }>>;
  handleStartTimeBeingAddedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  vacanciesQty: number | string;
  handleVacanciesQtyChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function AddTimeModal({
  addTimetableModalShow,
  setAddTimetableModalShow,
  addNewTimeAtTimetable,
  startTimeBeingAdded,
  endTimeBeingAdded,
  dayBeingAddedAtTimetable,
  timetableDaysOptions,
  setDayBeingAddedAtTimetable,
  handleStartTimeBeingAddedChange,
  vacanciesQty,
  handleVacanciesQtyChange,
}: AddTimeModalInterface) {
  return (
    <MyModal
      show={addTimetableModalShow}
      onClose={() => setAddTimetableModalShow(false)}
      onAction={addNewTimeAtTimetable}
      isActionButtonDisabled={startTimeBeingAdded > endTimeBeingAdded}
      title="Adicionar novo horário"
      type="action"
      closeButtonLabel="Fechar"
      actionButtonLabel="Adicionar"
      modalBody={(
        <>
          <FormGroup>
            <label htmlFor="contactName">Dia</label>
            <Select
              value={
                {
                  value: dayBeingAddedAtTimetable.value,
                  label: dayBeingAddedAtTimetable.label,
                }
              }
              options={timetableDaysOptions}
              onChange={(opt) => {
                setDayBeingAddedAtTimetable({ value: opt!.value, label: opt!.label });
              }}
              styles={CustomStyle}
              classNamePrefix="react-select"
              className="react-select-container"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="contactName">Horário de início</label>
            <Input
              type="time"
              min="08:00"
              max="20:00"
              value={startTimeBeingAdded}
              onChange={handleStartTimeBeingAddedChange}
              autoComplete="new-password"
              darkBorder
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="contactName">Horário de término (calculado a partir da duração)</label>
            <Input
              type="time"
              disabled
              value={endTimeBeingAdded}
              autoComplete="new-password"
              darkBorder
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="contactEmail">Quantidade de vagas</label>
            <Input
              placeholder="Mínimo de 10"
              value={vacanciesQty}
              onChange={handleVacanciesQtyChange}
              autoComplete="new-password"
              darkBorder
            />
          </FormGroup>
        </>
      )}
    />
  );
}
