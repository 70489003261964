import { RefObject } from 'react';
import styled, { css, keyframes } from 'styled-components';

interface StyledLoaderInterface {
  isLeaving: boolean;
  ref: RefObject<HTMLDivElement>
}

const fadeIn = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

const fadeOut = keyframes`
  0% {opacity: 1;}
  100% {opacity: 0;}
`;

export const Overlay = styled.div<StyledLoaderInterface>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.backgroundWithOpacity};
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.3s;
  z-index: 999999999999;

  ${({ isLeaving }) => isLeaving && css`
    animation: ${fadeOut} 0.2s forwards;
  `}
`;

const round = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
/*
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  } */

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const ImagesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 10%;
  height: 35%; */

  img {
    position: absolute;
  }

  .earth {
    z-index: 1;
  }

  .rocket {
    z-index: 2;
    -webkit-animation: ${round} 1.7s infinite linear;
    animation: ${round} 1.7s infinite linear;
  }
`;
