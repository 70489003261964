import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import useErrors from '../../../../../hooks/useErrors';
import useApiCall from '../../../../../hooks/useApiCall';
import { toast } from 'react-toastify';
import { ProgramBeingEditedType } from '../../types';
import activitiesService from '../../../../../services/activitiesService';

interface UseEditModalI {
  loadPrograms: () => Promise<void>;
  setEditModalShow: Dispatch<SetStateAction<boolean>>;
  editModalShow: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  programBeingEditted: ProgramBeingEditedType;
}

export default function useEditModal({ setIsLoading, loadPrograms, setEditModalShow, programBeingEditted, editModalShow }: UseEditModalI) {
  const [specificProgram, setSpecificProgram] = useState('');

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const { apiCall } = useApiCall();

  function handleSpecificProgramChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setSpecificProgram(event.target.value);
    if (!event.target.value) {
      setError({ field: 'specificProgram', message: 'Descrição da programação é obrigatória!' });
    } else {
      removeError('specificProgram');
    }
  }

  const editProgram = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.updateActivityProgram,
      onStartLoad: () => { setIsLoading(true); },
      queryParams: { id: programBeingEditted.id },
      reqBody: JSON.stringify({ specificOrientation: specificProgram }),
      actionAfterResponse: (response) => {
        if (response.success) {
          toast.success('Edição da programação enviada para aprovação com sucesso!');
          setEditModalShow(false);
          setSpecificProgram('');
          loadPrograms();
          return;
        }
        toast.error('Não foi possível editar a programação. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível editar a programação. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    });
  }, [apiCall, loadPrograms, programBeingEditted.id, setEditModalShow, setIsLoading, specificProgram]);

  const isEditFormValid = !!specificProgram && errors.length === 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setSpecificProgram(programBeingEditted.specificProgram); }, [editModalShow]);

  return {
    editProgram,
    specificProgram,
    handleSpecificProgramChange,
    getErrorMessageByFieldName,
    isEditFormValid,
  };
}
