/* eslint-disable max-len */
import styled from 'styled-components';

interface GroupInterface {
  aside?: boolean;
  link?: boolean;
}

export const FileContainer = styled.div`
  background: ${({ theme }) => theme.colors.lighterBackground};
  box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  img.file {
    width: 100%;
    height: auto;
    padding: 8px 16px 16px 16px;
  }

  video {
    width: 100%;
    height: auto;
    padding: 8px 16px 16px 16px;
  }

  div.card-title {
    font-weight: bold;
    font-size: 24px;
    margin-top: 8px;
    margin-left: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      font-weight: bold;
      font-size: 24px;
    }

    img {
      width: 24px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      filter: ${({ theme }) => theme.filters.primary};
      &:hover {
        opacity: 0.7
      }
    }
  }
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.lighterBackground};
  box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.04);
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: hidden;

  @media(max-width: 500px) {
    height: 100%;
    max-height: unset;
  }

  img {
    width: 50px;
  }

  img.file {
    width: inherit;
    margin: 15px;
  }

  div.card-title {
    font-weight: bold;
    font-size: 24px;
    margin-top: -8px;
    margin-left: -4px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      font-weight: bold;
      font-size: 24px;
    }

    img {
      width: 24px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      filter: ${({ theme }) => theme.filters.primary};
      &:hover {
        opacity: 0.7
      }
    }
  }
`;

export const Group = styled.div<GroupInterface>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  /* margin: 4px 0px; */
  margin-right: 16px;
  border-bottom: ${({ aside, theme }) => (aside ? 'none' : `2px solid ${theme.colors.darkerBackground}`)} !important;

  @media(max-width: 500px) {
    flex-direction: column;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkerBackground}`} !important;
  }

  div.title {
    margin: 4px 0px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 16px;
    }
    img {
      &:hover {
        opacity: 0.6
      }
      cursor: pointer;
      filter: ${({ theme }) => theme.filters.primary};
      width: 21px;
      transition: all 0.2s ease-in-out;
    }
  }

  span {
    max-height: 66px;
    overflow: auto;
    margin-bottom: 4px;
    word-break: ${({ link }) => (link ? 'break-all' : 'keep-all')};
  }
`;

export const AsideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  & + & {
    border-top: 2px solid ${({ theme }) => theme.colors.darkerBackground};
  }

  @media(max-width: 500px) {
    flex-direction: column;
    border-top: none !important;
  }
`;

// export const CarouselContainer = styled.div`
//   background: ${({ theme }) => theme.colors.lighterBackground};
//   box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.04);
//   border-radius: 4px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;
//   width: 100%;
//   padding-top: 16px;

//   .carousel {
//     padding: 8px 16px 16px 16px;
//     border-radius: 4px;
//     .carousel-item {
//       border-radius: 4px;
//       img {
//         border-radius: 4px;
//         /* mask-image: linear-gradient(90deg, transparent 5%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, transparent 95%); */
//       }
//     }
//   }

//   div.card-title {
//     font-weight: bold;
//     font-size: 24px;
//     margin-top: -12px;
//     margin-left: 12px;
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;

//     img {
//       width: 24px;
//       transition: all 0.2s ease-in-out;
//       cursor: pointer;
//       filter: ${({ theme }) => theme.filters.primary};
//       &:hover {
//         opacity: 0.7
//       }
//     }
//   }
// `;
