import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin: 16px 0px;
  width: 100%;
`;

export const MediaContainer = styled.div`
  overflow: hidden;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;
  height: auto; /* Alteração: Adicione esta linha */

  @media(max-width: 600px) {
    width: 100%;
  }

  img {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
  }

  video {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
  }
`;
