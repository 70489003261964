/* eslint-disable @typescript-eslint/no-empty-function */
import Loader from '../../../components/Loader';
import NoData from '../../../components/NoData';
import PageHeader from '../../../components/PageHeader';
import Sidebar from '../../../components/Sidebar';
import Transitions from '../../../components/Transition';
import Filters from './components/Filters';
import ListHeader from './components/Header';
import List from './components/list';
import useGivenClasses from './useGivenClasses';

export default function GivenClasses() {
  const {
    isLoading,
    doesListApiHasError,
    givenClasses,
    filteredGivenClasses,
    activitiesList,
    selectedActivity,
    setSelectedActivity,
    currentPage,
    pagesQuantity,
    handlePageChange,
    handleTryAgain,
    totalValue,
    month,
    year,
  } = useGivenClasses();

  const listLength = givenClasses?.length || 0;
  const filteredListLength = filteredGivenClasses?.length || 0;
  const hasGivenClasses = listLength !== 0 && !!givenClasses;
  const isListEmpty = !doesListApiHasError && (!isLoading && givenClasses.length === 0);
  const anyResultsByFilterSelection = !filteredGivenClasses && !isLoading && !isListEmpty;

  const monthsMap: { [key: number]: string } = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  return (
    <>
      <Sidebar active="Financial" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {!doesListApiHasError && (
          <>
            <PageHeader
              title={`Aulas Realizadas em ${monthsMap[Number(month)]} de ${year}`}
              link='/financial'
            />

            {!isListEmpty && (
              <Filters
                activitiesList={activitiesList}
                selectedActivity={selectedActivity}
                setSelectedActivity={setSelectedActivity}
                doesListExists={hasGivenClasses}
              />
            )}

            <ListHeader
              doesListExists={hasGivenClasses}
              hasError={doesListApiHasError}
              filteredListLength={filteredListLength}
              singularLabel="aula"
              pluralLabel="aulas"
              currentPage={currentPage}
              pagesQuantity={pagesQuantity}
              onPageChange={handlePageChange}
              totalValue={totalValue}
            />

            {hasGivenClasses && (
              <List
                filteredList={filteredGivenClasses}
              />
            )}
          </>
        )}

        {anyResultsByFilterSelection && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                  Não encontramos nenhum resultado conforme os filtros selecionados.
                  Por favor, tente alterar os critérios.
              </>
            )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter as aulas dadas.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
            )}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhuma aula realizada no período selecionado
              </>
            )}
          />
        )}
      </Transitions>
    </>
  );
}
