import { AsideContainer, Container, Group } from '../../styles';

interface ResponsibleDataCardInterface {
  responsibleData: {
    name?: string;
    role?: string;
    phone?: string;
    email?: string;
  }
}

export default function ResponsibleDataCard({ responsibleData }: ResponsibleDataCardInterface) {
  return (
    <Container>
      <div className="card-title">
        Dados do Responsável
      </div>
      <AsideContainer>
        <Group aside>
          <div className="title">
            Nome
          </div>
          <span>
            {responsibleData.name || 'Não informado'}
          </span>
        </Group>
        <Group aside>
          <div className="title">
            Cargo
          </div>
          <span>
            {responsibleData.role || 'Não informado'}
          </span>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group aside>
          <div className="title">
            Telefone
          </div>
          <span>
            {responsibleData.phone || 'Não informado'}
          </span>
        </Group>
        <Group aside>
          <div className="title">
            E-mail
          </div>
          <span>
            {responsibleData.email || 'Não informado'}
          </span>
        </Group>
      </AsideContainer>
    </Container>
  );
}
