import { DatesUnderAprovementType } from '../../../../types/DetailsTypes';
import { TimetableFromApiType } from '../../../../types/TimetableFromApiType';
import useDates from './functions/useDates';
import useDisableDate from './functions/useDisableDate';
import useRehabilitateDate from './functions/useRehabilitateDate';

interface UseDisabledDatesCardInterface {
  dates: string[];
  datesUnderAprovement?: DatesUnderAprovementType;
  activityId: string;
  activityName: string;
  reloadActivity: () => void;
  timetable: TimetableFromApiType;
}

export default function useDisabledDatesCard({
  dates,
  datesUnderAprovement,
  activityId,
  activityName,
  reloadActivity,
  timetable,
}: UseDisabledDatesCardInterface) {
  const {
    hasDatesToBeRendered,
    datesUnderDisableAprovement,
    formattedUnderRehabilitateAprovementDates,
    formattedDates,
    hasFutureDisabledDates,
    hasUnderDisableAprovementDates,
  } = useDates({
    dates,
    datesUnderAprovement,
  });

  const {
    isDisabling,
    setDisableDateModalShow,
    disableDateModalShow,
    selectedDisableDate,
    handleDisableDateChange,
    disableDate,
    isFutureDateChoosed,
    isSpecificTime,
    setIsSpecificTime,
    timesOptions,
    selectedDisableTime,
    setSelectedDisableTime,
  } = useDisableDate({
    activityId,
    activityName,
    reloadActivity,
    timetable,
    datesUnderDisableAprovement,
    formattedDates,
    hasFutureDisabledDates,
    hasUnderDisableAprovementDates,
  });

  const {
    isRehabilitating,
    setRehabilitateDate,
    setRehabilitateDateModalShow,
    selectedRehabilitateDate,
    rehabilitateDate,
    rehabilitateDateModalShow,
  } = useRehabilitateDate({
    activityId,
    activityName,
    reloadActivity,
  });

  const isLoading = isDisabling || isRehabilitating;

  return {
    isLoading,
    setDisableDateModalShow,
    hasDatesToBeRendered,
    datesUnderDisableAprovement,
    formattedUnderRehabilitateAprovementDates,
    formattedDates,
    setRehabilitateDate,
    setRehabilitateDateModalShow,
    selectedRehabilitateDate,
    rehabilitateDate,
    disableDateModalShow,
    selectedDisableDate,
    handleDisableDateChange,
    disableDate,
    isFutureDateChoosed,
    rehabilitateDateModalShow,
    isSpecificTime,
    setIsSpecificTime,
    timesOptions,
    selectedDisableTime,
    setSelectedDisableTime,
  };
}
