import OpacityAnimation from '../../../../../components/OpacityAnimation';
import Pagination from '../../../../../components/Pagination';
import floatToCurrency from '../../../../../utils/floatToCurrency';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  pagesQuantity: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  totalValue: number;
}

export default function ListHeader({
  doesListExists,
  hasError,
  filteredListLength,
  singularLabel,
  pluralLabel,
  pagesQuantity = 0,
  currentPage,
  onPageChange,
  totalValue,
}: ListHeaderInterface) {
  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <div className="leftAlignment">
            <strong>
              {filteredListLength}
              {' '}
              {filteredListLength === 1 ? singularLabel : pluralLabel}
              {' '}
              ({floatToCurrency(totalValue)})
            </strong>
            <Pagination
              pagesQuantity={pagesQuantity}
              handlePageChange={onPageChange}
              currentPage={currentPage}
            />
          </div>
        )}
      </Header>
    </OpacityAnimation>
  );
}
