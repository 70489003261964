import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import { useQuery } from '../../../../hooks/useQuery';
import givenClassesService from '../../../../services/givenClassesService';
import removeDuplicates from '../../../../utils/removeDuplicates';
import splitArray from '../../../../utils/splitArray';
import { ActivityOptionType, GivenClassType } from '../types';

export default function useGivenClasses() {
  const [isLoading, setIsLoading] = useState(false);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const [selectedActivity, setSelectedActivity] = useState({} as ActivityOptionType);
  const [activitiesList, setActivitiesList] = useState<ActivityOptionType[]>([]);

  const [givenClasses, setGivenClasses] = useState<GivenClassType[]>([]);
  const [filteredGivenClasses, setFilteredGivenClasses] = useState<GivenClassType[]>([]);
  const [givenClassesSplited, setGivenClassesSplited] = useState<GivenClassType[][]>([]);
  const [totalValue, setTotalValue] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const pagesQuantity = useMemo(() => givenClassesSplited.length, [givenClassesSplited.length]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
    setFilteredGivenClasses(givenClassesSplited[page]);
  }

  const { apiCall } = useApiCall();
  const query = useQuery();
  const month = query.get('month') || new Date().getMonth() + 1;
  const year = query.get('year') || new Date().getFullYear();

  const getGivenClasses = useCallback(async () => {
    await apiCall({
      apiToCall: givenClassesService.getGivenClassesByPeriod,
      queryParams: {
        month,
        year,
      },
      actionAfterResponse: (response: { success: boolean, givenClasses: GivenClassType[] }) => {
        if (!response.success) {
          setDoesListApiHasError(true);
          toast.error('Não foi possível carregar as aulas dadas. Por favor, tente novamente');
          return;
        }
        setDoesListApiHasError(false);
        setGivenClasses(response.givenClasses);
        const splitedArray = splitArray(response.givenClasses);
        setGivenClassesSplited(splitedArray);
        setFilteredGivenClasses(splitedArray[0]);
        setCurrentPage(0);
        setSelectedActivity({ value: null, label: 'Filtrar por Atividade' });

        const givenActivities = response.givenClasses.map((gvCl) => gvCl.activity);
        const removedDuplicatedActivities = removeDuplicates(givenActivities);
        const mappedActivities = removedDuplicatedActivities.map((activity) => ({ value: activity.id, label: activity.nome }));
        setActivitiesList([{ value: null, label: 'Filtrar por Atividade' }, ...mappedActivities]);

        const total = response.givenClasses.reduce((acc, gvCl) => acc + gvCl.activity.classValue, 0);
        setTotalValue(total);
      },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
    });
  }, [apiCall, month, year]);

  function handleTryAgain() {
    getGivenClasses();
  }

  const filterGivenClasses = useCallback(() => {
    const filteredGvClasses = givenClasses.filter((gvCl) => {
      const activityFilter = gvCl.activity.id === selectedActivity.value || !selectedActivity.value;

      return activityFilter;
    });

    const givenClassesSplittedByFilters = splitArray(filteredGvClasses);
    setGivenClassesSplited(givenClassesSplittedByFilters);
    setFilteredGivenClasses(givenClassesSplittedByFilters[0]);
  }, [givenClasses, selectedActivity.value]);

  useEffect(() => {
    filterGivenClasses();
  }, [filterGivenClasses]);

  useEffect(() => {
    getGivenClasses();
  }, [getGivenClasses]);

  return {
    isLoading,
    doesListApiHasError,
    givenClasses,
    filteredGivenClasses,
    activitiesList,
    selectedActivity,
    setSelectedActivity,
    currentPage,
    pagesQuantity,
    handlePageChange,
    handleTryAgain,
    totalValue,
    month,
    year,
  };
}
