import { Col, Container, Row } from 'react-bootstrap';

import Sidebar from '../../../components/Sidebar';
import Transitions from '../../../components/Transition';
import PageHeader from '../../../components/PageHeader';

import GeneralDataCard from './components/GeneralDataCard';
import RequirementsCard from './components/RequirementsCard';
import PicturesCard from './components/PicturesCard';
import DisabledDatesCard from './components/DisabledDatesCard';
import TimetableCard from './components/TimetableCard';
import VideoCard from './components/VideoCard';
import Loader from '../../../components/Loader';
import NoData from '../../../components/NoData';
import useActivityDetails from './useActivityDetails';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import OpacityAnimation from '../../../components/OpacityAnimation';

export default function ActivityDetail() {
  const {
    isLoading,
    loadActivity,
    apiHasError,
    activity,
    handleTryAgain,
    underAprovement,
    isDescriptionUnderAprovement,
    isAgeUnderAprovement,
    isParentsActivity,
    isRecordedClass,
  } = useActivityDetails();

  const isActivityLoadedSuccessfully = !apiHasError && !isLoading;
  const hasTimetable = !!(activity?.timetable);
  const isRecordedActivity = activity?.generalData?.kindOfClass === 'record';

  const navigate = useNavigate();

  if (!activity) {
    toast.error('Não foi possível carregar os detalhes da atividade');
    navigate('/activities');
    return null;
  }

  return (
    <>
      <Sidebar active="Activities" />
      <Loader isLoading={isLoading} />
      <Transitions>
        <PageHeader
          title={`Atividade ${activity.name || ''}`}
          link="/activities"
        />
        {isActivityLoadedSuccessfully && (
          <Container>
            <Row xs={1} md={1} lg={1}>
              <Col>
                <OpacityAnimation delay={0.3}>
                  <GeneralDataCard
                    generalData={activity.generalData}
                    activityId={activity.id}
                    activityName={activity.name}
                    isDescriptionUnderAprovement={isDescriptionUnderAprovement}
                    reloadActivity={loadActivity}
                  />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={0.6}>
                  <RequirementsCard
                    requirementsData={activity.requirementsData}
                    activityId={activity.id}
                    activityName={activity.name}
                    reloadActivity={loadActivity}
                    isAgeUnderAprovement={isAgeUnderAprovement}
                    isParentsActivity={isParentsActivity}
                    isRecordedClass={isRecordedClass}
                  />
                </OpacityAnimation>
              </Col>
              {hasTimetable && (
                <Col>
                  <TimetableCard timetable={activity.timetable} />
                </Col>
              )}
            </Row>
            <Row xs={1} md={1} lg={2}>
              <Col>
                <PicturesCard imagePath={activity.imagePath} />
              </Col>
              {isRecordedActivity ? (
                <Col>
                  <VideoCard src={activity.generalData?.link} />
                </Col>
              ) : (
                <Col>
                  <DisabledDatesCard
                    dates={activity.disabledDates}
                    activityId={activity.id}
                    activityName={activity.name}
                    reloadActivity={loadActivity}
                    datesUnderAprovement={underAprovement.datasDesabilitadas}
                    timetable={activity.timetable}
                  />
                </Col>
              )}
            </Row>
            {isRecordedActivity && (
              <Row xs={1} md={1} lg={1}>
                <Col>
                  <DisabledDatesCard
                    dates={activity.disabledDates}
                    activityId={activity.id}
                    activityName={activity.name}
                    reloadActivity={loadActivity}
                    datesUnderAprovement={underAprovement.datasDesabilitadas}
                    timetable={activity.timetable}
                  />
                </Col>
              </Row>
            )}
          </Container>
        )}
      </Transitions>

      {apiHasError && (
        <NoData
          icon="sad"
          label={(
            <>
            Ocorreu um erro ao carregar a atividade.
              <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
            .
            </>
          )}
        />
      )}
    </>
  );
}
