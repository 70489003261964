import { AnimatePresence } from 'framer-motion';
import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

// pages
import NotFound from '../pages/NotFound';
import RegistrationData from '../pages/RegistrationData';
import ChangePassword from '../pages/RegistrationData/ChangePassword';

import Activities from '../pages/Activities';
import ActivityDetails from '../pages/Activities/ActivityDetails';
import NewActivity from '../pages/Activities/NewActivity';

import Schedules from '../pages/Schedules';

import ActivityPedagogicalProgram from '../pages/Activities/PedagogicalProgram';
import ActivityProgram from '../pages/Activities/Program';
import Files from '../pages/Files';
import Financial from '../pages/Financial';
import GivenClasses from '../pages/Financial/GivenClasses';
import Forum from '../pages/Forum';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes
        location={location}
        key={location.pathname}
      >
        <Route path="/" element={<RegistrationData />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/activities/new" element={<NewActivity />} />
        <Route path="/activities/:id" element={<ActivityDetails />} />
        <Route path="/activities/:id/program" element={<ActivityProgram />} />
        <Route path="/activities/:id/pedagogicalProgram" element={<ActivityPedagogicalProgram />} />
        <Route path="/schedules" element={<Schedules />} />
        <Route path="/financial" element={<Financial />} />
        <Route path="/financial/givenClasses" element={<GivenClasses />} />
        <Route path="/files" element={<Files />} />

        <Route path="/" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
