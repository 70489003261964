import FilterRadioButton from '../../../components/FilterRadioButtons';
import MyModal from '../../../components/Modal';
import { MonthsGrid } from '../styles';
import { MonthsNumbersType } from '../types';
import { Dispatch, SetStateAction } from 'react';

interface ChangeMonthModalInterface {
  dateModalShow: boolean;
  yearsArraySinceStart: number[];
  setSelectedYear: Dispatch<SetStateAction<number>>;
  setSelectedMonth: Dispatch<SetStateAction<number>>;
  selectedYear: number;
  months: string[];
  selectedMonth: number;
  currentYear: number;
  currentMonth: number | string;
  startYear: number;
  startMonth: number;
  monthsMap: { [key: number]: string };
  setCurrentMonthBeingShow: Dispatch<SetStateAction<MonthsNumbersType | ''>>;
  setCurrentYearBeingShow: Dispatch<SetStateAction<number>>;
  setDateModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function ChangeMonthModal({
  dateModalShow,
  yearsArraySinceStart,
  setSelectedYear,
  setSelectedMonth,
  selectedYear,
  months,
  selectedMonth,
  currentYear,
  currentMonth,
  startYear,
  startMonth,
  monthsMap,
  setCurrentMonthBeingShow,
  setCurrentYearBeingShow,
  setDateModalShow,
}: ChangeMonthModalInterface) {
  return (
    <MyModal
      show={dateModalShow}
      type="action"
      title="Alterar mês de visualização dos dados"
      modalBody={(
        <div className="modal-body-centered">
          <div className="year-selection">
            {yearsArraySinceStart.map((year) => (
              <FilterRadioButton
                key={year}
                onClick={() => {
                  setSelectedYear(year);
                  setSelectedMonth(0);
                }}
                selected={selectedYear === year}
              >
                {year}
              </FilterRadioButton>
            ))}
          </div>
          <MonthsGrid>
            {months.map((month) => (
              <FilterRadioButton
                key={month}
                onClick={() => setSelectedMonth(Number(month))}
                selected={Number(month) == selectedMonth}
                invisible={
                  (Number(`${selectedYear}${(month)}`)
                      > Number(`${currentYear}${currentMonth}`))
                      || (Number(`${startYear}${startMonth}`)
                      > Number(`${selectedYear}${Number(month)}`))
                }
              >
                {monthsMap[Number(month)]}
              </FilterRadioButton>
            ))}
          </MonthsGrid>
        </div>
      )}
      size="lg"
      onAction={() => {
        setCurrentMonthBeingShow(Number(selectedMonth) as MonthsNumbersType);
        setCurrentYearBeingShow(selectedYear);
        setDateModalShow(false);
      }}
      onClose={() => setDateModalShow(false)}
      actionButtonLabel="Alterar mês"
      closeButtonLabel="Cancelar"
    />
  );
}
