import FormGroup from '../../../../../../components/FormGroup';
import Input from '../../../../../../components/Input';
import MyModal from '../../../../../../components/Modal';
import { GetErrorMessageByFieldNameType } from '../../../../../../hooks/useErrors';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

interface AgeModalInterface {
  ageModalShow: boolean;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  isRecordedClass: boolean;
  minAge: number;
  handleMinAgeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  maxAge: number;
  handleMaxAgeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setAgeModalShow: Dispatch<SetStateAction<boolean>>;
  changeAge: () => void;
  isReadyToSubmit: boolean;
}

export default function AgeModal({
  ageModalShow,
  getErrorMessageByFieldName,
  isRecordedClass,
  minAge,
  handleMinAgeChange,
  maxAge,
  handleMaxAgeChange,
  setAgeModalShow,
  changeAge,
  isReadyToSubmit,
}: AgeModalInterface) {
  return (
    <MyModal
      show={ageModalShow}
      title="Alterar faixa etária"
      modalBody={(
        <>
          <FormGroup error={getErrorMessageByFieldName('minAge')}>
            <label htmlFor="contactName">Idade mínima</label>
            <Input
              maxLength={2}
              placeholder={isRecordedClass ? '0-12' : '3-12'}
              value={minAge}
              onChange={handleMinAgeChange}
              autoComplete="new-password"
              darkBorder
              error={getErrorMessageByFieldName('minAge')}
            />
          </FormGroup>
          <FormGroup error={getErrorMessageByFieldName('maxAge')}>
            <label htmlFor="contactName">Idade máxima</label>
            <Input
              maxLength={2}
              placeholder={isRecordedClass ? '0-12' : '3-12'}
              value={maxAge}
              onChange={handleMaxAgeChange}
              autoComplete="new-password"
              darkBorder
              error={getErrorMessageByFieldName('maxAge')}
            />
          </FormGroup>
        </>
      )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Alterar faixa etária"
      onClose={() => setAgeModalShow(false)}
      onAction={() => changeAge()}
      isActionButtonDisabled={!isReadyToSubmit}
      type="action"
    />
  );
}
