import { ChangeEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import userService from '../../../services/userService';
import useErrors from '../../../hooks/useErrors';
import { useAppContext } from '../../../contexts/auth';
import useApiCall from '../../../hooks/useApiCall';

export default function useChangePassowrd() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

  const { user } = useAppContext();
  const navigate = useNavigate();
  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();
  const { apiCall } = useApiCall();

  const isFormValid = (errors.length === 0
    && (currentPassword && newPassword && newPasswordConfirmation));

  function handleCurrentPasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setCurrentPassword(event.target.value);

    if (!event.target.value) {
      setError({ field: 'currentPassword', message: 'Senha atual é obrigatória!' });
    } else {
      removeError('currentPassword');
    }
  }

  function handleNewPasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setNewPassword(event.target.value);

    if (event.target.value.length < 6) {
      setError({ field: 'newPassword', message: 'Senha seve conter no mínimo 6 caracteres!' });
    } else if (event.target.value.length >= 6) {
      removeError('newPassword');
    }

    if (event.target.value !== newPasswordConfirmation) {
      setError({ field: 'newPasswordConfirmation', message: 'Senhas divergentes' });
    } else if (event.target.value === newPasswordConfirmation) {
      removeError('newPasswordConfirmation');
    }
  }

  function handleNewPasswordConfirmationChange(event: ChangeEvent<HTMLInputElement>) {
    setNewPasswordConfirmation(event.target.value);

    if (event.target.value !== newPassword) {
      setError({ field: 'newPasswordConfirmation', message: 'Senhas divergentes' });
    } else {
      removeError('newPasswordConfirmation');
    }
  }

  const handleSubmit = useCallback(async () => {
    await apiCall({
      apiToCall: userService.changePassword,
      reqBody: JSON.stringify({
        email: user.email,
        currentPassword,
        newPassword,
      }),
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível alterar a senha. Por favor, tente novamente');
          setIsLoading(false);
          return;
        }
        toast.success('Senha alterada com sucesso!');
        navigate('/');
      },
      catchMessage: 'Não foi possível alterar a senha. Por favor, tente novamente'
    });
  }, [apiCall, currentPassword, navigate, newPassword, user.email]);

  return {
    handleSubmit,
    getErrorMessageByFieldName,
    currentPassword,
    handleCurrentPasswordChange,
    newPassword,
    handleNewPasswordChange,
    newPasswordConfirmation,
    handleNewPasswordConfirmationChange,
    isFormValid,
    isLoading,
  };
}
