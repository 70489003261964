import Select from 'react-select';
import { Container as StyledContainer, Group, AsideContainer } from '../styles';

import Input from '../../../../../../components/Input';
import FormGroup from '../../../../../../components/FormGroup';
import Textarea from '../../../../../../components/Textarea';

import { CustomStyle } from '../../../../../../components/CustomSelectStyle';
import { GetErrorMessageByFieldNameType } from '../../../../../../hooks/useErrors';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

interface RequirementsDataCardInterface {
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  minAge: number | string;
  handleMinAgeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  subcategory: {
    value: string;
    label: string;
  };
  maxAge: number | string;
  handleMaxAgeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  needsCompanion: {
    value: boolean | null;
    label: string;
  };
  companionOptions: {
    value: boolean | null;
    label: string;
  }[];
  setNeedsCompanion: Dispatch<SetStateAction<{
    value: boolean | null;
    label: string;
  }>>;
  level: {
    value: string | null;
    label: string;
  };
  levelOptions: {
    value: string | null;
    label: string;
  }[];
  setLevel: Dispatch<SetStateAction<{
    value: string | null;
    label: string;
  }>>;
  handleNecessaryMaterialsChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  necessaryMaterials: string;
  handleNecessaryKnowledgeChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  necessaryKnowledge: string;
  handleClothesChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  clothes: string;
}

export default function RequirementsDataCard({
  getErrorMessageByFieldName,
  minAge,
  handleMinAgeChange,
  subcategory,
  maxAge,
  handleMaxAgeChange,
  needsCompanion,
  companionOptions,
  setNeedsCompanion,
  level,
  levelOptions,
  setLevel,
  handleNecessaryMaterialsChange,
  necessaryMaterials,
  handleNecessaryKnowledgeChange,
  necessaryKnowledge,
  handleClothesChange,
  clothes,
}: RequirementsDataCardInterface) {
  return (
    <StyledContainer>
      <div className="card-title">
        Requisitos
      </div>
      <AsideContainer>
        <Group>
          <div className="title">
            Idade mínima *
          </div>
          <FormGroup error={getErrorMessageByFieldName('minAge')}>
            <Input
              value={minAge}
              onChange={handleMinAgeChange}
              error={getErrorMessageByFieldName('minAge')}
              disabled={subcategory.value === 'Educação parental'}
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Idade máxima *
          </div>
          <FormGroup error={getErrorMessageByFieldName('maxAge')}>
            <Input
              value={maxAge}
              onChange={handleMaxAgeChange}
              error={getErrorMessageByFieldName('maxAge')}
              disabled={subcategory.value === 'Educação parental'}
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Necessário acompanhante?
          </div>
          <Select
            value={{ value: needsCompanion?.value, label: needsCompanion?.label }}
            options={companionOptions}
            onChange={(opt) => {
              setNeedsCompanion({ value: opt!.value, label: opt!.label });
            }}
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        <Group>
          <div className="title">
            Nível técnico
          </div>
          <Select
            value={{ value: level?.value, label: level?.label }}
            options={levelOptions}
            onChange={(opt) => {
              setLevel({ value: opt!.value, label: opt!.label });
            }}
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            <div>
              Materiais necessários
            </div>
          </div>
          <Textarea
            onChange={handleNecessaryMaterialsChange}
            placeholder="Caso seja necessário algum material para a realização da atividade, especifique-os aqui"
            value={necessaryMaterials}
            height={100}
          />
        </Group>
        <Group link>
          <div className="title">
            Conhecimentos necessários
          </div>
          <Textarea
            onChange={handleNecessaryKnowledgeChange}
            placeholder="Caso seja necessário ter algum conhecimento prévio para a realização da atividade, especifique-os aqui"
            value={necessaryKnowledge}
            height={100}
          />
        </Group>
        <Group link>
          <div className="title">
            Vestuário
          </div>
          <Textarea
            onChange={handleClothesChange}
            placeholder="Caso seja necessário o uso de algum vestuário específico para a realização da atividade, especifique-o aqui"
            value={clothes}
            height={100}
          />
        </Group>
      </AsideContainer>
    </StyledContainer>
  );
}
