import clock from '../../../../../../assets/images/icons/clock.svg';
import { Card } from '../styles';
import { Dispatch, SetStateAction } from 'react';

type ClassesInfoType = {
  startTime: string;
  endTime: string;
  vacanciesQty: number;
};

type DayInfoBeingShowType = {
  day?: string;
  dayEn?: string;
  list?: ClassesInfoType[];
}

interface DayCardInterface {
  day: string;
  quantityOfClasses: number;
  setDayTimesModalShow: Dispatch<SetStateAction<boolean>>;
  setDayInfoBeingShow: Dispatch<SetStateAction<DayInfoBeingShowType>>;
  classes: ClassesInfoType[];
}

export default function DayCard({
  day,
  quantityOfClasses,
  setDayTimesModalShow,
  setDayInfoBeingShow,
  classes,
}: DayCardInterface) {
  return (
    <Card>
      <div className="info">
        <div className="card-title">
          <strong>{day}</strong>
          <small>
            {
              quantityOfClasses > 1
                ? `${quantityOfClasses} horários`
                : `${quantityOfClasses} horário`
            }
          </small>
        </div>
      </div>
      <div className="actions">
        <button
          type="button"
          onClick={() => {
            setDayTimesModalShow(true);
            setDayInfoBeingShow({
              day,
              list: classes,
            });
          }}
        >
          <img src={clock} alt="horarios" title="Visualizar horários das aulas" className="primaryColor" />
        </button>
      </div>
    </Card>
  );
}
