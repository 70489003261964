import { Container, Group, AsideContainer } from '../../styles';

interface RegistrationDataCardInterface {
  registrationData: {
    cnpj?: string;
    name?: string;
    razaoSocial?: string;
    description?: string;
  }
}

export default function RegistrationDataCard({ registrationData }: RegistrationDataCardInterface) {
  return (
    <Container>
      <div className="card-title">
        Dados Cadastrais
      </div>
      <AsideContainer>
        <Group aside>
          <div className="title">
            CNPJ
          </div>
          <span>
            {registrationData.cnpj || 'Não informado'}
          </span>
        </Group>
        <Group aside>
          <div className="title">
            Nome Fantasia
          </div>
          <span>
            {registrationData.name || 'Não informado'}
          </span>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group aside>
          <div className="title">
            Razão Social
          </div>
          <span>
            {registrationData.razaoSocial || 'Não informado'}
          </span>
        </Group>
        <Group aside>
          <div className="title">
            Descrição
          </div>
          <span>
            {registrationData.description || 'Não informado'}
          </span>
        </Group>
      </AsideContainer>
    </Container>
  );
}
