import { Dispatch, SetStateAction, useCallback } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import financialService from '../../../../services/financialService';

interface IUseRemoveNf {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadFinancialData: () => Promise<void>;
}

export default function useRemoveNf({ setIsLoading, loadFinancialData }: IUseRemoveNf) {
  const { apiCall } = useApiCall();

  const removeNf = useCallback(async (nfUrl?: string) => {
    await apiCall({
      apiToCall: financialService.removeNf,
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: async (response) => {
        if (!response.success) {
          toast.error('Não foi possível remover a nota fiscal. Por favor, tente novamente');
          return;
        }

        await loadFinancialData();
        toast.success('Nota fiscal removida com sucesso!');
      },
      reqBody: JSON.stringify({ nfUrl }),
    });
  }, [apiCall, loadFinancialData, setIsLoading]);

  return { removeNf };
}
