import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 4px 0px;
  margin-right: 16px;

  div.title {
    margin: 4px 0px;
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 16px;
    }
    img {
      &:hover {
        opacity: 0.6
      }
      cursor: pointer;
      filter: ${({ theme }) => theme.filters.primary};
      width: 21px;
      transition: all 0.2s ease-in-out;
    }
  }

  span {
    max-height: 66px;
    overflow: auto;
    margin-bottom: 2px;
  }
`;
