/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction } from 'react';
import FormGroup from '../../../../../components/FormGroup';
import MyModal from '../../../../../components/Modal';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import Textarea from '../../../../../components/Textarea';
import { ProgramBeingEditedType } from '../../types';
import { Group } from '../AddModal/styles';
import { ModalContainer } from './styles';
import useEditModal from './useEditModal';

interface IEditModal {
  editModalShow: boolean;
  setEditModalShow: Dispatch<SetStateAction<boolean>>;
  programBeingEditted: ProgramBeingEditedType;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadPrograms: () => Promise<void>;
}

export default function EditModal({
  editModalShow,
  setEditModalShow,
  setIsLoading,
  loadPrograms,
  programBeingEditted,
}: IEditModal) {
  const {
    editProgram,
    specificProgram,
    handleSpecificProgramChange,
    getErrorMessageByFieldName,
    isEditFormValid,
  } = useEditModal({ setIsLoading, loadPrograms, setEditModalShow, programBeingEditted, editModalShow });

  return (
    <MyModal
      show={editModalShow}
      onClose={() => setEditModalShow(false)}
      title={`Editar programação de ${programBeingEditted.date} | ${programBeingEditted.startTime} - ${programBeingEditted.endTime}`}
      modalBody={(
        <ModalContainer>
          <OpacityAnimation delay={0.1}>
            <Group>
              <div className="title">
              Descreva a programação
              </div>
              <FormGroup error={getErrorMessageByFieldName('specificProgram')}>
                <Textarea
                  value={specificProgram}
                  onChange={handleSpecificProgramChange}
                  placeholder="Descreva qual a programação específica para essa data, como materiais necessários para sua realização.  (máx. 310 caracteres)"
                  maxLength={310}
                />
              </FormGroup>
            </Group>
          </OpacityAnimation>
        </ModalContainer>
      )}
      closeButtonLabel="Fechar"
      size="lg"
      type='action'
      actionButtonLabel="Confirmar (requererá aprovação)"
      isActionButtonDisabled={!isEditFormValid}
      onAction={() => editProgram()}
    />
  );
}
