import { motion } from 'framer-motion';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CurrrentMonthBeingShowType, CurrrentYearBeingShowType } from '../../types';
import { Container } from './styles';

interface HeaderInterface {
  currentMonthBeingShow: CurrrentMonthBeingShowType;
  currentYearBeingShow: CurrrentYearBeingShowType;
  handleDateModalShow: Dispatch<SetStateAction<boolean>>;
  isNfUploaded: boolean;
  nfDownloadLink?: string;
  isPaymentVoucherUploaded: boolean;
  paymentVoucherLink?: string;
  handleNfModalShow: Dispatch<SetStateAction<boolean>>;
  setGivenClassesModalShow: Dispatch<SetStateAction<boolean>>;
  hasFinancialRelatoryAllowedForDownload: boolean;
  getFinancialRelatoryPreview: () => Promise<void>;
  removeNf: (nfUrl?: string) => Promise<void>;
}

export default function Header({
  currentMonthBeingShow,
  currentYearBeingShow,
  handleDateModalShow,
  isNfUploaded,
  nfDownloadLink,
  isPaymentVoucherUploaded,
  paymentVoucherLink,
  handleNfModalShow,
  setGivenClassesModalShow,
  hasFinancialRelatoryAllowedForDownload,
  getFinancialRelatoryPreview,
  removeNf,
}: HeaderInterface) {
  const [willRemoveNf, setWillRemoveNf] = useState(false);

  useEffect(() => {
    setWillRemoveNf(false);
  }, [currentMonthBeingShow]);

  const monthsMap = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  const currentMonth = new Date().getMonth() + 1;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.1, type: 'tween', stiffness: 10 }}
    >
      <Container>
        <strong>
          {monthsMap[currentMonthBeingShow]}
          {' '}
          de
          {' '}
          {currentYearBeingShow}
        </strong>
        <div className="actionButtons">
          <button type="button" onClick={() => handleDateModalShow(true)}>
            Alterar mês
          </button>
          {hasFinancialRelatoryAllowedForDownload && (
            <button type="button" onClick={() => getFinancialRelatoryPreview()}>
              Visualizar Relatório Financeiro
            </button>
          )}
          {!isNfUploaded && (
            <button type="button" onClick={() => handleNfModalShow(true)} disabled={currentMonth === currentMonthBeingShow}>
              Upload NF-e
            </button>
          )}
          {isNfUploaded && (
            <>
              <a href={nfDownloadLink} target="_blank" download rel="noreferrer">
                Baixar NF-e enviada
              </a>
              <button type="button" onClick={willRemoveNf ? () => removeNf(nfDownloadLink) : () => setWillRemoveNf(true)}>
                {willRemoveNf ? 'Confirmar remoção' : 'Remover NF-e' }
              </button>
            </>
          )}
          {isPaymentVoucherUploaded && (
            <a href={paymentVoucherLink} target="_blank" download rel="noreferrer">
              Baixar comprovante de pagamento
            </a>
          )}
          <button type="button" onClick={() => setGivenClassesModalShow(true)}>
            Visualizar aulas realizadas
          </button>
        </div>
      </Container>
    </motion.div>
  );
}
