import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import schedulesService from '../../../../services/schedulesService';
import dateWithout0 from '../../../../utils/dateWithout0';
import renderArray from '../../../../utils/renderArrayWithComa';
import { DatesType, SchedulesApiResponseType, SchedulesType } from '../../types';

interface UseLoadSchedulesInterface {
  dates: DatesType;
}

export default function useLoadSchedules({
  dates,
}: UseLoadSchedulesInterface) {
  const [isLoading, setIsLoading] = useState(false);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);
  const [schedules, setSchedules] = useState<SchedulesType>([]);
  const [activitiesList, setActivitiesList] = useState<string[]>([]);
  const [dateBeingShown, setDateBeingShown] = useState(dates.currentDate);
  const [warnings, setWarnings] = useState<string[]>([]);

  const { apiCall } = useApiCall();
  const {
    currentDate,
  } = dates;

  const getScheduleList = useCallback(async (date = dates.currentDate) => {
    await apiCall({
      apiToCall: schedulesService.getScheduleList,
      queryParams: { date: dateWithout0(date) },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (apiResponse: SchedulesApiResponseType) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar os agendamentos (${apiResponse.error})`, {
            toastId: 'getScheduleListFail',
          });
          setDoesListApiHasError(true);
          setIsLoading(false);
          return;
        }
        const schedulesList = apiResponse?.appointments;
        const activities = apiResponse?.activities?.map((activity) => activity.nome);
        if (schedulesList) {
          setSchedules(schedulesList);
        }
        if (activities) {
          setActivitiesList(activities);
        }
        setDoesListApiHasError(false);
        setDateBeingShown(date);

        const warningsArray: string[] = [];
        const dateStringToShow = date === currentDate ? 'Hoje' : `Em ${date}`;

        const childrensBirthdayToday = schedulesList?.map((sch) => {
          const onlyChildsWithTodayBirthday = sch.criancas.filter(
            (child) => child.birthDate.slice(0, 5) === currentDate.slice(0, 5),
          );
          return {
            atividade: sch.atividade,
            horario: sch.horario,
            criancas: onlyChildsWithTodayBirthday,
          };
        }).filter((sch) => sch.criancas.length > 0);

        if (childrensBirthdayToday && childrensBirthdayToday.length > 0) {
          childrensBirthdayToday.forEach((childBirthday) => {
            const childsNamesString = renderArray(childBirthday.criancas.map((child) => child.name));

            warningsArray.push(`🎂 ${dateStringToShow} é o aniversário de ${childsNamesString}, que participará(ão) da aula de ${childBirthday.atividade}, as ${childBirthday.horario.split('-')[0]}!`);
          });
        }

        const schedulesWithChildrensWithDeficiency = schedulesList?.filter((sch) => sch.criancas.some((kid) => !!kid.hasDeficiency))?.map((sch) => ({
          atividade: sch.atividade,
          horario: sch.horario,
          criancas: sch.criancas,
        }));

        if (!!schedulesWithChildrensWithDeficiency && schedulesWithChildrensWithDeficiency.length > 0) {
          schedulesWithChildrensWithDeficiency.forEach((kidObs) => {
            kidObs.criancas.forEach((kid) => {
              if (kid.observation && kid.hasDeficiency) {
                warningsArray.push(`⚠️ A criança ${kid.name}, que participará da aula de ${kidObs.atividade} ${dateStringToShow.toLowerCase()} ás ${kidObs.horario.split('-')[0]}, possui uma observação: ${kid.observation}`);
              } else if (!kid.observation && kid.hasDeficiency) {
                warningsArray.push(`⚠️ A criança ${kid.name}, que participará da aula de ${kidObs.atividade} ${dateStringToShow.toLowerCase()} ás ${kidObs.horario.split('-')[0]}, possui uma deficiência não especificada`);
              }
            });
          });
        }

        if (warningsArray.length > 0) {
          setWarnings(warningsArray);

          const message = warningsArray.length > 1 ? `Existem ${warningsArray.length} avisos para os agendamentos da data selecionada. Clique no botão avisos para visualizá-los!` : `Existe ${warningsArray.length} aviso para os agendamentos da data selecionada. Clique no botão avisos para visualizá-los`;
          toast.warn(message, {
            toastId: 'warnings'
          });
        }
      },
      catchMessage: 'Não foi possível carregar os agendamentos',
      catchAction: () => setDoesListApiHasError(true),
    });
  }, [apiCall, currentDate, dates.currentDate]);

  useEffect(() => {
    getScheduleList();
  }, [getScheduleList]);

  return {
    schedules,
    getScheduleList,
    isLoading,
    doesListApiHasError,
    dateBeingShown,
    activitiesList,
    warnings,
  };
}
