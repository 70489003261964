import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
    border-bottom: ${({ theme }) => theme.colors.gray[100]};
  }

  @media(max-width: 500px){
      flex-direction: column;
      align-items: center;
      gap: 2px;
    }

  div.selectionButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;

    @media(max-width: 500px){
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }
  }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 24px;
    margin: 24px;
    background: ${({ theme }) => theme.colors.lighterBackground};
    box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    gap: 24px;

    @media(max-width: 500px){
      img {
        display: none;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      span {
        font-size: 14px;
        margin-bottom: 24px;
      }

      a {
        color: ${({ theme }) => theme.colors.primary.main};
        text-decoration: none;
        font-weight: bold;
        border: 2px solid ${({ theme }) => theme.colors.primary.main};
        padding: 8px 16px;
        border-radius: 4px;
        transition: all 0.2s ease-in;
        margin: 0px 4px;
        &:hover {
          background: ${({ theme }) => theme.colors.primary.main};
          color: ${({ theme }) => theme.colors.lighterBackground};
        }
      }
  }

`;
