import { motion } from 'framer-motion';
import { Col, Row } from 'react-bootstrap';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import ChangeMonthModal from './components/ChangeMonthModal';
import DueDateCard from './components/DueDateCard';
import Header from './components/Header';
import NfDateCard from './components/NfDateCard';
import NfUploadModal from './components/NfUploadModal';
import PaymentStatusCard from './components/PaymentStatusCard';
import SeeGivenClassesModal from './components/SeeGivenClassesModal';
import StartDateCard from './components/StartDateCard';
import { CurrrentMonthBeingShowType } from './types';
import useFinancial from './useFinancial';

export default function Financial() {
  const monthsMap = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  const {
    isLoading,
    months,
    currentYear,
    yearsArraySinceStart,
    currentMonth,
    dateModalShow,
    setDateModalShow,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    currentMonthBeingShow,
    setCurrentMonthBeingShow,
    currentYearBeingShow,
    setCurrentYearBeingShow,
    nfUploadModalShow,
    setNfUploadModalShow,
    nfFileName,
    handleNfFileUpload,
    handleNfFileSubmit,
    startYear,
    startMonth,
    financialDataError,
    handleTryAgain,
    financialData,
    givenClassesModalShow,
    setGivenClassesModalShow,
    getFinancialRelatoryPreview,
    removeNf,
  } = useFinancial();

  const startDate = '01/04/2023';

  const paymentInfoAtSelectedDate = financialData?.comps?.find((prt) => {
    const month = Number(prt.data.split('_')[0]);
    const year = Number(prt.data.split('_')[1]);

    return (month === currentMonthBeingShow && year === currentYearBeingShow);
  });
  const paymentDateInJsFormat = (paymentInfoAtSelectedDate
    ? new Date(paymentInfoAtSelectedDate?.data_envio) : null);
  const paymentDate = paymentDateInJsFormat?.toLocaleDateString('pt-BR');

  const nfInfoAtSelectedDate = financialData?.nfs?.find((prt) => {
    const month = Number(prt.data.split('_')[0]);
    const year = Number(prt.data.split('_')[1]);

    return (month === currentMonthBeingShow && year === currentYearBeingShow);
  });
  const nfDateInJsFormat = (nfInfoAtSelectedDate
    ? new Date(nfInfoAtSelectedDate?.data_envio) : null);
  const nfDate = nfDateInJsFormat?.toLocaleDateString('pt-BR');

  const dueDate = `08/${String(currentMonthBeingShow).length === 2 ? currentMonthBeingShow : `0${currentMonthBeingShow}`}/${currentYearBeingShow}`;

  const hasFinancialRelatoryAllowedForDownload = financialData.partner?.allowedRelatoryPeriodsDownload.includes(`${currentMonthBeingShow}/${currentYearBeingShow}`) ?? false;

  return (
    <>
      <Sidebar active="Financial" />
      <Loader isLoading={isLoading} />
      <Transitions>
        {!financialDataError && !isLoading && (
          <>
            <Header
              currentMonthBeingShow={currentMonthBeingShow as CurrrentMonthBeingShowType}
              currentYearBeingShow={currentYearBeingShow}
              handleDateModalShow={setDateModalShow}
              isNfUploaded={!!nfInfoAtSelectedDate}
              nfDownloadLink={nfInfoAtSelectedDate?.link}
              isPaymentVoucherUploaded={!!paymentInfoAtSelectedDate}
              paymentVoucherLink={paymentInfoAtSelectedDate?.link}
              handleNfModalShow={setNfUploadModalShow}
              setGivenClassesModalShow={setGivenClassesModalShow}
              hasFinancialRelatoryAllowedForDownload={hasFinancialRelatoryAllowedForDownload}
              getFinancialRelatoryPreview={getFinancialRelatoryPreview}
              removeNf={removeNf}
            />

            <Row xs={1} md={2} lg={2}>
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.15, type: 'tween', stiffness: 10000 }}
                >
                  <StartDateCard
                    startDate={startDate}
                  />
                </motion.div>
              </Col>
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                >
                  <DueDateCard
                    dueDate={dueDate}
                  />
                </motion.div>
              </Col>
            </Row>

            <Row xs={1} md={2} lg={2}>
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.45, type: 'tween', stiffness: 10000 }}
                >
                  <PaymentStatusCard
                    paymentDate={paymentDate}
                  />
                </motion.div>
              </Col>
              <Col>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.6, type: 'tween', stiffness: 10000 }}
                >

                  <NfDateCard
                    nfDate={nfDate}
                  />
                </motion.div>
              </Col>
            </Row>

            <ChangeMonthModal
              dateModalShow={dateModalShow}
              yearsArraySinceStart={yearsArraySinceStart}
              setSelectedYear={setSelectedYear}
              setSelectedMonth={setSelectedMonth}
              selectedYear={selectedYear}
              months={months}
              selectedMonth={selectedMonth}
              currentYear={currentYear}
              currentMonth={currentMonth}
              startYear={startYear}
              startMonth={startMonth}
              monthsMap={monthsMap}
              setCurrentMonthBeingShow={setCurrentMonthBeingShow}
              setCurrentYearBeingShow={setCurrentYearBeingShow}
              setDateModalShow={setDateModalShow}
            />

            <NfUploadModal
              nfUploadModalShow={nfUploadModalShow}
              nfFileName={nfFileName}
              handleNfFileUpload={handleNfFileUpload}
              handleNfFileSubmit={handleNfFileSubmit}
              setNfUploadModalShow={setNfUploadModalShow}
            />

            <SeeGivenClassesModal
              givenClassesModalShow={givenClassesModalShow}
              yearsArraySinceStart={yearsArraySinceStart}
              setSelectedYear={setSelectedYear}
              setSelectedMonth={setSelectedMonth}
              selectedYear={selectedYear}
              months={months}
              selectedMonth={selectedMonth}
              currentYear={currentYear}
              currentMonth={currentMonth}
              startYear={startYear}
              startMonth={startMonth}
              monthsMap={monthsMap}
              setSeeGivenClassesModalShow={setGivenClassesModalShow}
            />
          </>
        )}

        {financialDataError && !isLoading && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter os dados financeiros .
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
              </>
            )}
          />
        )}
      </Transitions>
    </>
  );
}
