import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterRadioButton from '../../../components/FilterRadioButtons';
import MyModal from '../../../components/Modal';
import { MonthsGrid } from '../styles';

interface ISeeGivenClassesModal {
  givenClassesModalShow: boolean;
  yearsArraySinceStart: number[];
  setSelectedYear: Dispatch<SetStateAction<number>>;
  setSelectedMonth: Dispatch<SetStateAction<number>>;
  selectedYear: number;
  months: string[];
  selectedMonth: number;
  currentYear: number;
  currentMonth: number | string;
  startYear: number;
  startMonth: number;
  monthsMap: { [key: number]: string };
  setSeeGivenClassesModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function SeeGivenClassesModal({
  givenClassesModalShow,
  yearsArraySinceStart,
  setSelectedYear,
  setSelectedMonth,
  selectedYear,
  months,
  selectedMonth,
  currentYear,
  currentMonth,
  startYear,
  startMonth,
  monthsMap,
  setSeeGivenClassesModalShow,
}: ISeeGivenClassesModal) {
  const navigate = useNavigate();
  return (
    <MyModal
      show={givenClassesModalShow}
      type="action"
      title="Selecione o período que deseja visualizar suas aulas realizadas."
      modalBody={(
        <>
          <div className="modal-body-centered">
            <div className="year-selection">
              {yearsArraySinceStart.map((year) => (
                <FilterRadioButton
                  key={year}
                  onClick={() => {
                    setSelectedYear(year);
                    setSelectedMonth(0);
                  }}
                  selected={selectedYear === year}
                >
                  {year}
                </FilterRadioButton>
              ))}
            </div>
            <MonthsGrid>
              {months.map((month) => (
                <FilterRadioButton
                  key={month}
                  onClick={() => setSelectedMonth(Number(month))}
                  selected={Number(month) == selectedMonth}
                  invisible={
                    (Number(`${selectedYear}${(month)}`)
                        > Number(`${currentYear}${currentMonth}`))
                        || (Number(`${startYear}${startMonth}`)
                        > Number(`${selectedYear}${Number(month)}`))
                  }
                >
                  {monthsMap[Number(month)]}
                </FilterRadioButton>
              ))}
            </MonthsGrid>
          </div>
        </>
      )}
      size="lg"
      onAction={() => {
        navigate(`/financial/givenClasses?year=${selectedYear}&month=${selectedMonth}`);
      }}
      onClose={() => setSeeGivenClassesModalShow(false)}
      actionButtonLabel={`Visualizar Aulas Realizadas em ${monthsMap[selectedMonth]} de ${selectedYear}`}
      closeButtonLabel="Cancelar"
    />
  );
}
