import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import edit from '../../../../../../../../assets/images/icons/edit.svg';
import kiddleLogo from '../../../../../../../../assets/images/icons/kiddleLogo.svg';
import trash from '../../../../../../../../assets/images/icons/trash.svg';
import useLocalState from '../../../../../../../../hooks/useLocalState';
import { UserType } from '../../../../../../types';
import { ViewType } from '../../types';
import { HeaderContainer } from './styles';

interface IHeader {
  user: UserType;
  createdAt: Date;
  setView: Dispatch<SetStateAction<ViewType>>;
}

export default function Header({ user, createdAt, setView }: IHeader) {
  const [userAtContext] = useLocalState('userData');
  const userId = userAtContext.partnerId;
  const { profileImage, name } = user;
  const formattedDate = format(new Date(createdAt), 'dd/MM/yyyy \'às\' HH\'h\'mm\'m\'');

  return (
    <HeaderContainer>
      <header>
        <div>
          <img src={profileImage ?? kiddleLogo} alt="userProfileImage" />
        </div>
        <strong>{name}</strong>
        <span>{formattedDate}</span>
      </header>

      {userId === user.id && (
        <div className="actions">
          <button
            type="button"
            style={{ marginLeft: '16px' }}
            onClick={() => setView('edit')}
          >
            <img src={edit} alt="" title="Editar publicação" className="primaryColor" />
          </button>
          <button
            type="button"
            onClick={() => setView('delete')}
          >
            <img src={trash} alt="" title="Remover publicação" />
          </button>
        </div>
      )}
    </HeaderContainer>
  );
}
