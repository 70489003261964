import { Col, Container, Row } from 'react-bootstrap';
import { TimesContainer, TimesGroup } from './styles';

interface TimesListInterface {
  list: {
    startTime: string;
    endTime: string;
    vacanciesQty: number;
  }[]
}

export default function TimesList({ list }: TimesListInterface) {
  return (
    <TimesContainer>
      <Container>
        <Row xs={1} md={2} lg={2}>
          {list.map((time) => (
            <Col key={`${time.startTime}${time.endTime}`}>
              <TimesGroup>
                <div className="info">
                  <div className="card-title">
                    <strong>{`Das ${time.startTime} às ${time.endTime}`}</strong>
                    {time.vacanciesQty && (
                      <small>{`${time.vacanciesQty} vagas`}</small>
                    )}
                  </div>
                </div>
              </TimesGroup>
            </Col>
          ))}
        </Row>
      </Container>
    </TimesContainer>
  );
}
