import FileInput from '../../../components/FileInput';
import MyModal from '../../../components/Modal';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

interface NfUploadModalInterface {
  nfUploadModalShow: boolean;
  nfFileName: string;
  handleNfFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  handleNfFileSubmit: () => void;
  setNfUploadModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function NfUploadModal({
  nfUploadModalShow,
  nfFileName,
  handleNfFileUpload,
  handleNfFileSubmit,
  setNfUploadModalShow,
}: NfUploadModalInterface) {
  return (
    <MyModal
      show={nfUploadModalShow}
      title="Upload de nota fiscal"
      centeredBody
      modalBody={(
        <FileInput
          buttonLabel="Importar arquivo"
          fileNameChoosed={nfFileName}
          isSubmitButtonDisabled={!nfFileName}
          onFileUpload={handleNfFileUpload}
          onSubmitFile={handleNfFileSubmit}
          acceptedFiles=".pdf"
        />
      )}
      closeButtonLabel="Fechar"
      onClose={() => setNfUploadModalShow(false)}
      type="info"
    />
  );
}
