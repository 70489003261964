import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import activitiesService from '../../../services/activitiesService';
import { TimetableFromApiType } from '../types/TimetableFromApiType';
import { DatesUnderAprovementType } from '../types/DetailsTypes';
import useApiCall from '../../../hooks/useApiCall';


type ActivityType = {
  id: string,
  name: string,
  status: string,
  generalData: {
    category: string,
    subcategory: string,
    description: string,
    link: string,
    orientations: string,
    methodology: string,
    kindOfClass: string,
    duration: number,
  },
  requirementsData: {
    maxAge: number,
    minAge: number,
    companion: boolean,
    requiredMaterials: string,
    requiredKnowledge: string,
    clothes: string,
    level: string,
  },
  imagePath: string,
  timetable: TimetableFromApiType,
  disabledDates: string[],
}

type UnderAprovementType = {
  descricao?: string;
  idade?: string;
  datasDesabilitadas?: DatesUnderAprovementType;
}

export default function useActivityDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [apiHasError, setApiHasError] = useState(false);
  const [activity, setActivity] = useState<ActivityType>({} as ActivityType);
  const [underAprovement, setUnderAprovement] = useState<UnderAprovementType>({});

  const { apiCall } = useApiCall();

  const loadActivity = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getActivityDetails,
      queryParams: { activityId: id },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar a atividade (${apiResponse.error})`, {
            toastId: 'getActivityFail',
          });
          setApiHasError(true);
          setIsLoading(false);
          return;
        }
        const activityDetails = apiResponse.details;
        const mappedActivityDetail = {
          id: activityDetails.id,
          name: activityDetails.nome,
          status: activityDetails.status,
          generalData: {
            category: activityDetails.categoria,
            subcategory: activityDetails.subcategoria,
            description: activityDetails.descricao,
            link: activityDetails.videoUrl,
            orientations: activityDetails.orientacoes,
            methodology: activityDetails.metodologia,
            kindOfClass: activityDetails.tipo,
            duration: activityDetails.duracao,
          },
          requirementsData: {
            maxAge: activityDetails.maxima,
            minAge: activityDetails.minima,
            companion: activityDetails.acompanhante,
            requiredMaterials: activityDetails.levar,
            requiredKnowledge: activityDetails.conhecimento,
            clothes: activityDetails.vestuario,
            level: activityDetails.nivel,
          },
          imagePath: activityDetails.cover,
          timetable: activityDetails.grade,
          disabledDates: activityDetails.datasDesabilitadas,
        };
        setActivity(mappedActivityDetail);
        setUnderAprovement(apiResponse.emAprovacao);
        setApiHasError(false);
      },
      catchMessage: 'Não foi possível carregar a atividade',
      catchAction: () => setApiHasError(true),
    });
  }, [apiCall, id]);

  function handleTryAgain() {
    loadActivity();
  }

  useEffect(() => {
    loadActivity();
  }, [loadActivity]);

  const isDescriptionUnderAprovement = !!underAprovement.descricao;
  const isAgeUnderAprovement = !!underAprovement.idade;
  const isParentsActivity = activity?.generalData?.subcategory === 'Educação parental';
  const isRecordedClass = activity?.generalData?.kindOfClass === 'record';

  return {
    isLoading,
    loadActivity,
    apiHasError,
    activity,
    handleTryAgain,
    underAprovement,
    isDescriptionUnderAprovement,
    isAgeUnderAprovement,
    isParentsActivity,
    isRecordedClass,
  };
}
