import { Container, Group, AsideContainer } from '../../styles';

import Loader from '../../../../../components/Loader';

import edit from '../../../../../assets/images/icons/edit.svg';
import clock from '../../../../../assets/images/icons/clock.svg';
import useRequirementsCard from './useRequirementsCard';
import AgeModal from './components/AgeModal';

interface RequirementsDataCardInterface {
  requirementsData: {
    minAge: number;
    maxAge: number;
    companion: boolean;
    requiredMaterials: string;
    clothes: string;
    level: string;
    requiredKnowledge: string;
  };
  activityId: string;
  activityName: string;
  reloadActivity: () => void;
  isAgeUnderAprovement: boolean;
  isParentsActivity: boolean;
  isRecordedClass: boolean;
}

export default function RequirementsDataCard({
  requirementsData,
  activityId,
  activityName,
  reloadActivity,
  isAgeUnderAprovement,
  isParentsActivity,
  isRecordedClass,
}: RequirementsDataCardInterface) {
  const {
    isLoading,
    setAgeModalShow,
    ageModalShow,
    getErrorMessageByFieldName,
    minAge,
    handleMinAgeChange,
    maxAge,
    handleMaxAgeChange,
    changeAge,
    isReadyToSubmit,
  } = useRequirementsCard({
    activityId,
    activityName,
    reloadActivity,
    isRecordedClass,
    requirementsData,
  });

  return (
    <>
      <Loader isLoading={isLoading} />
      <Container>
        <div className="card-title">
          Requisitos
        </div>
        <AsideContainer>
          <Group aside>
            <div className="title">
              <div>
                Idade
              </div>
              {(!isAgeUnderAprovement && !isParentsActivity) && (
                <img
                  src={edit}
                  alt="edit"
                  title="Alterar faixa etária"
                  onClick={() => setAgeModalShow(true)}
                />
              )}
              {(isAgeUnderAprovement && !isParentsActivity) && (
                <img
                  src={clock}
                  alt="wait"
                  title="Alteração aguardando aprovação"
                  className="primaryColor"
                />
              )}
            </div>
            <span>
              {!isParentsActivity && (
                <>
                  De
                  {' '}
                  {requirementsData.minAge}
                  {' '}
                  a
                  {' '}
                  {requirementsData.maxAge}
                  {' '}
                  anos
                </>
              )}
              {isParentsActivity && (
                <>
                  Atividade direcionada para os pais (18+)
                </>
              )}
            </span>
          </Group>
          <Group aside>
            <div className="title">
              Acompanhante
            </div>
            <span>
              {(!requirementsData.companion ? 'Sem necessidade' : 'Necessário') || 'Não informado'}
            </span>
          </Group>
        </AsideContainer>
        <AsideContainer>
          <Group aside>
            <div className="title">
              Materiais necessários
            </div>
            <span>
              {requirementsData.requiredMaterials || 'Não informado'}
            </span>
          </Group>
          <Group aside>
            <div className="title">
              Vestuário
            </div>
            <span>
              {requirementsData.clothes || 'Não informado'}
            </span>
          </Group>
        </AsideContainer>
        <AsideContainer>
          <Group aside>
            <div className="title">
              Nível técnico
            </div>
            <span>
              {requirementsData.level || 'Não informado'}
            </span>
          </Group>
          <Group aside>
            <div className="title">
              Conhecimentos necessários
            </div>
            <span>
              {requirementsData.requiredKnowledge || 'Não informado'}
            </span>
          </Group>
        </AsideContainer>
      </Container>

      <AgeModal
        ageModalShow={ageModalShow}
        getErrorMessageByFieldName={getErrorMessageByFieldName}
        isRecordedClass={isRecordedClass}
        minAge={minAge}
        handleMinAgeChange={handleMinAgeChange}
        maxAge={maxAge}
        handleMaxAgeChange={handleMaxAgeChange}
        setAgeModalShow={setAgeModalShow}
        changeAge={changeAge}
        isReadyToSubmit={isReadyToSubmit}
      />
    </>
  );
}
