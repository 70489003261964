import MyModal from '../../../components/Modal';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

interface ChangeDateModalInterface {
  specificDateModalShow: boolean;
  selectedDate: string;
  handleDateChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setSpecificDateModalShow: Dispatch<SetStateAction<boolean>>;
  getScheduleList: (date: string) => void;
  formattedSelectedDate: string;
}

export default function ChangeDateModal({
  specificDateModalShow,
  selectedDate,
  handleDateChange,
  setSpecificDateModalShow,
  getScheduleList,
  formattedSelectedDate,
}: ChangeDateModalInterface) {
  return (
    <MyModal
      show={specificDateModalShow}
      title="Selecionar data específica"
      centeredBody
      modalBody={(
        <input type="date" value={selectedDate} onChange={handleDateChange} />
      )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Confirmar"
      onClose={() => setSpecificDateModalShow(false)}
      onAction={() => {
        getScheduleList(formattedSelectedDate);
        setSpecificDateModalShow(false);
      }}
      type="action"
      size="sm"
    />
  );
}
