import styled from 'styled-components';

interface GroupInterface {
  aside?: boolean;
  link?: boolean;
}

export const PageHeader = styled.header`
  margin-top: 24px;
  @media(max-width: 400px){
    margin-top: 2.5em;
  };

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 7%;
    background: ${({ theme }) => theme.colors.background};

    span {
      color: ${({ theme }) => theme.colors.primary.main};
      font-weight: bold;
    }

    img {
      margin-right: 8px;
      transform: rotate(-90deg);
    }
  }

  h1 {
    font-size: 16px;
  }
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.lighterBackground};
  box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.04);
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-height: 253px;
  overflow-x: hidden;
  overflow-y: hidden;

  @media(max-width: 500px) {
    height: 100%;
    max-height: unset;
  }

  div.card-title {
    font-weight: bold;
    font-size: 24px;
    margin-top: -8px;
    margin-left: -4px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      width: 24px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      filter: ${({ theme }) => theme.filters.primary};
      &:hover {
        opacity: 0.7
      }
    }
  }
`;

export const Group = styled.div<GroupInterface>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 4px 0px;
  margin-right: 4px;
  border-bottom: ${({ aside, theme }) => (aside ? 'none' : `2px solid ${theme.colors.darkerBackground}`)} !important;

  @media(max-width: 500px) {
    flex-direction: column;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkerBackground}`} !important;
  }

  div.title {
    margin: 4px 0px;
    font-size: 16px;
    img {
      filter: ${({ theme }) => theme.filters.primary};
      width: 18px;
    }
  }

  span {
    max-height: 66px;
    overflow: auto;
    margin-bottom: 2px;
    word-break: ${({ link }) => (link ? 'break-all' : 'keep-all')};
  }
`;

export const AsideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  & + & {
    border-top: 2px solid ${({ theme }) => theme.colors.darkerBackground};
  }

  @media(max-width: 500px) {
    flex-direction: column;
    border-top: none !important;
  }
`;
