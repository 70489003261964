import { Col, Container, Row } from 'react-bootstrap';
import edit from '../../../../../assets/images/icons/edit.svg';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import { MappedProgramType, ProgramBeingEditedType } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: MappedProgramType[];
  onOpenEditModal: ({ id, specificProgram, date, startTime, endTime }: ProgramBeingEditedType) => void;
}

export default function List({
  filteredList,
  onOpenEditModal,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={3}>
        {filteredList?.map((program) => (
          <Col key={program.id}>
            <OpacityAnimation delay={0.1}>
              <Card title="Expandir detalhes">
                <div className="info">
                  <div className="card-title">
                    <strong>{program.date}</strong>

                    <small className={program.status === 'approved' ? 'green' : 'orange'}>
                      {program.status === 'approved' ? 'Aprovado' : 'Requer revisão'}
                    </small>
                  </div>
                  <span>
                    Horário:
                    {' '}
                    {program.startTime} - {program.endTime}
                  </span>
                </div>
                <div className="actions">
                  <button
                    type="button"
                    onClick={() => onOpenEditModal(program)}
                  >
                    <img src={edit} alt="" title={`Editar programção pedagógica de ${program.date} | ${program.startTime} - ${program.endTime}`} className='primaryColor' />
                  </button>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
