import FormGroup from '../../../../../components/FormGroup';
import Input from '../../../../../components/Input';
import MyModal from '../../../../../components/Modal';
import { GetErrorMessageByFieldNameType } from '../../../../../hooks/useErrors';
import { ContactDetailsType, EditContactParamsType } from '../../../types';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

interface EditContactModalInterface {
  editContactCardModalShow: boolean;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  contactName: string;
  contactDetails: ContactDetailsType;
  handleContactNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  contactCellphone?: string;
  handleContactCellphoneChange: (event: ChangeEvent<HTMLInputElement>) => void ;
  contactEmail: string;
  handleContactEmailChange: (event: ChangeEvent<HTMLInputElement>) => void ;
  setEditContactCardModalShow: Dispatch<SetStateAction<boolean>>;
  editContact: (props: EditContactParamsType) => void;
  isReadyToEdit: boolean;
}

export default function EditContactModal({
  editContactCardModalShow,
  getErrorMessageByFieldName,
  contactName,
  contactDetails,
  handleContactNameChange,
  contactCellphone,
  handleContactCellphoneChange,
  contactEmail,
  handleContactEmailChange,
  setEditContactCardModalShow,
  editContact,
  isReadyToEdit,
}: EditContactModalInterface) {
  return (
    <MyModal
      show={editContactCardModalShow}
      title="Editar dados de contato"
      modalBody={(
        <>
          <FormGroup error={getErrorMessageByFieldName('contactName')}>
            <label htmlFor="contactName">Nome</label>
            <Input
              placeholder="Nome do contato"
              value={contactName ?? contactDetails.name}
              onChange={handleContactNameChange}
              autoComplete="new-password"
              darkBorder
              error={getErrorMessageByFieldName('contactName')}
            />
          </FormGroup>
          <FormGroup error={getErrorMessageByFieldName('contactCellphone')}>
            <label htmlFor="contactCellphone">Celular</label>
            <Input
              placeholder="(XX)XXXX-XXXX"
              value={contactCellphone ?? contactDetails.cellphone}
              onChange={handleContactCellphoneChange}
              autoComplete="new-password"
              error={getErrorMessageByFieldName('contactCellphone')}
              maxLength={15}
              darkBorder
            />
          </FormGroup>
          <FormGroup error={getErrorMessageByFieldName('contactEmail')}>
            <label htmlFor="contactEmail">E-mail</label>
            <Input
              placeholder="email@dominio.com"
              value={contactEmail ?? contactDetails.email}
              onChange={handleContactEmailChange}
              autoComplete="new-password"
              error={getErrorMessageByFieldName('contactEmail')}
              darkBorder
            />
          </FormGroup>
        </>
      )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Editar"
      onClose={() => setEditContactCardModalShow(false)}
      onAction={() => editContact({
        name: contactName,
        cellphone: contactCellphone,
        email: contactEmail,
      })}
      isActionButtonDisabled={!isReadyToEdit}
      type="action"
    />
  );
}
