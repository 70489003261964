import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../../../../../contexts/auth';
import useApiCall from '../../../../../../../hooks/useApiCall';
import { ErrorsInterface } from '../../../../../../../hooks/useErrors';
import activitiesService from '../../../../../../../services/activitiesService';
import replaceSpecialChars from '../../../../../../../utils/replaceSpecialChars';
import uploadAndGetVideoUrlFirebase from '../../../../../../../utils/uploadAndGetVideoUrlFirebase';
import { Value } from '../../../../../types/CalendarTypes';
import { TimetableTranslatedType } from '../../../../../types/TimetableTranslatedType';
import toDomainTimetable from '../../../../../utils/toDomainTimetable';

interface UseCreateActivityInterface {
  name: string;
  description: string;
  category: {
    value: string;
    label: string;
  };
  subcategory: {
    value: string;
    label: string;
  };
  minAge: string | number;
  maxAge: string | number;
  methodology: string;
  necessaryMaterials: string;
  needsCompanion: {
    value: boolean | null;
    label: string;
  };
  orientations: string;
  clothes: string;
  isRecurrent: {
    value: boolean;
    label: string;
  };
  level: {
    value: string | null;
    label: string;
  };
  necessaryKnowledge: string;
  videoLink: string;
  kindOfActivity: string;
  timetable: TimetableTranslatedType;
  imageFile?: File;
  videoFile?: File;
  duration: number;
  errors: ErrorsInterface[];
  hasClasses: boolean;
  specificPeriod: Value;
}

export default function useCreateActivity({
  name,
  description,
  category,
  subcategory,
  minAge,
  maxAge,
  methodology,
  necessaryMaterials,
  needsCompanion,
  orientations,
  clothes,
  isRecurrent,
  level,
  necessaryKnowledge,
  videoLink,
  kindOfActivity,
  timetable,
  imageFile,
  videoFile,
  duration,
  errors,
  hasClasses,
  specificPeriod,
}: UseCreateActivityInterface) {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const { apiCall } = useApiCall();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const createActivity = useCallback(async () => {
    setIsSubmitting(true);
    let videoUrl = videoLink;

    if (kindOfActivity !== 'online' && videoFile && typeof videoFile === 'object') {
      console.log('Caiu condicional para upload video');
      videoUrl = await uploadAndGetVideoUrlFirebase({
        video: videoFile,
        slugAtividade: replaceSpecialChars(name),
        slugParceiro: replaceSpecialChars(user.partnerName),
      });
    }
    await apiCall({
      apiToCall: activitiesService.createActivity,
      reqBody: [
        { key: 'partner_id', value: user.partnerId },
        { key: 'nome', value: name },
        { key: 'descricao', value: description },
        { key: 'categoryId', value: subcategory.value },
        { key: 'minima', value: minAge },
        { key: 'maxima', value: maxAge },
        { key: 'metodologia', value: methodology },
        { key: 'levar', value: necessaryMaterials },
        { key: 'acompanhante', value: needsCompanion.value },
        { key: 'orientacoes', value: orientations },
        { key: 'vestuario', value: clothes },
        { key: 'recorrente', value: isRecurrent.value },
        { key: 'nivel', value: level.value },
        { key: 'conhecimento', value: necessaryKnowledge },
        { key: 'videoUrl', value: videoUrl || videoLink },
        { key: 'tipo', value: kindOfActivity === 'online' ? 'live' : 'record' },
        { key: 'duracao', value: kindOfActivity === 'online' ? Number(duration) : null },
        { key: 'grade', value: kindOfActivity === 'online' ? JSON.stringify(toDomainTimetable(timetable)) : null },
        { key: 'cover', value: imageFile },
        { key: 'specificPeriod', value: isRecurrent.value ? null : JSON.stringify(specificPeriod) },
        { key: 'slugAtividade', value: replaceSpecialChars(name) },
        { key: 'slugParceiro', value: replaceSpecialChars(user.partnerName) },
      ],
      onStartLoad: () => setIsSubmitting(true),
      onEndLoad: () => setIsSubmitting(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível enviar a atividade para aprovação. Por favor, tente novamente');
          setIsSubmitting(false);
          return;
        }
        toast.success(`A atividade ${name} foi enviada para aprovação!`);
        navigate('/activities');
      },
      catchMessage: 'Não foi possível adicionar a atividade. Por favor, tente novamente'
    });
  // eslint-disable-next-line max-len
  }, [apiCall, clothes, description, duration, imageFile, isRecurrent.value, kindOfActivity, level.value, maxAge, methodology, minAge, name, navigate, necessaryKnowledge, necessaryMaterials, needsCompanion.value, orientations, specificPeriod, subcategory.value, timetable, user.partnerId, user.partnerName, videoFile, videoLink]);

  const isRecordedActivity = kindOfActivity === 'video';
  const isFormValid = isRecordedActivity
    ? (
      (name
        && category.value
        && subcategory.value
        && description
        && maxAge
        && minAge
        && videoFile
        && imageFile)
    && errors.length === 0
    )
    : (
      (name
        && category.value
        && subcategory.value
        && description
        && maxAge
        && minAge
        && videoLink
        && imageFile
        && hasClasses)
    && errors.length === 0
    && ((!isRecurrent.value && specificPeriod) || isRecurrent.value)
    );

  return {
    isSubmitting,
    createActivity,
    isFormValid,
  };
}
