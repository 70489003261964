import NoData from '../../../../../components/NoData';
import { Container as NoDataContainer, FileContainer } from '../../styles';

interface VideoCardInterface {
  src?: string;
}

export default function VideoCard({ src }: VideoCardInterface) {
  return (
    <>
      {src && (
        <FileContainer>
          <div className="card-title">
          Vídeo
          </div>
          <video src={src} height="300" controls />
        </FileContainer>
      )}

      {!src && (
        <NoDataContainer>
          <div className="card-title">
            Foto
          </div>
          <NoData
            icon="emptyBox"
            label="Não há nenhuma foto"
          />
        </NoDataContainer>
      )}
    </>
  );
}
