import { FilterRadioButtonsContainer } from '../../../../../../components/FilterRadioButtonsContainer';
import FilterRadioButton from '../../../../../../components/FilterRadioButtons';
import { Dispatch, SetStateAction } from 'react';

interface KindOfActivityInterface {
  setKindOfActivity: Dispatch<SetStateAction<string>>;
  kindOfActivity: string;
}

export default function KindOfActivity({ setKindOfActivity, kindOfActivity }: KindOfActivityInterface) {
  return (
    <FilterRadioButtonsContainer>
      <FilterRadioButton
        onClick={() => setKindOfActivity('online')}
        selected={kindOfActivity === 'online'}
      >
        Atividade ao vivo
      </FilterRadioButton>
      <FilterRadioButton
        onClick={() => setKindOfActivity('video')}
        selected={kindOfActivity === 'video'}
      >
        Atividade gravada (vídeo)
      </FilterRadioButton>
    </FilterRadioButtonsContainer>
  );
}
