import { TimetableTranslatedType } from '../types/TimetableTranslatedType';

export default function toDomainTimetable(timetable: TimetableTranslatedType) {
  const mondayClasses = timetable?.monday;
  const quantityOfMondayClasses = mondayClasses?.length;

  const tuesdayClasses = timetable?.tuesday;
  const quantityOfTuesdayClasses = tuesdayClasses?.length;

  const wednesdayClasses = timetable?.wednesday;
  const quantityOfWednesdayClasses = wednesdayClasses?.length;

  const thursdayClasses = timetable?.thursday;
  const quantityOfThursdayClasses = thursdayClasses?.length;

  const fridayClasses = timetable?.friday;
  const quantityOfFridayClasses = fridayClasses?.length;

  const saturdayClasses = timetable?.saturday;
  const quantityOfSaturdayClasses = saturdayClasses?.length;

  const sundayClasses = timetable?.sunday;
  const quantityOfSundayClasses = sundayClasses?.length;

  return [
    {
      dia: 'seg',
      horarios: quantityOfMondayClasses > 0 ? (mondayClasses.map((classInfo) => ({
        horarioInicio: classInfo.startTime,
        horarioTermino: classInfo.endTime,
        vagas: classInfo.vacanciesQty,
      }))) : [],
    },
    {
      dia: 'ter',
      horarios: quantityOfTuesdayClasses > 0 ? (tuesdayClasses.map((classInfo) => ({
        horarioInicio: classInfo.startTime,
        horarioTermino: classInfo.endTime,
        vagas: classInfo.vacanciesQty,
      }))) : [],
    },
    {
      dia: 'qua',
      horarios: quantityOfWednesdayClasses > 0 ? (wednesdayClasses.map((classInfo) => ({
        horarioInicio: classInfo.startTime,
        horarioTermino: classInfo.endTime,
        vagas: classInfo.vacanciesQty,
      }))) : [],
    },
    {
      dia: 'qui',
      horarios: quantityOfThursdayClasses > 0 ? (thursdayClasses.map((classInfo) => ({
        horarioInicio: classInfo.startTime,
        horarioTermino: classInfo.endTime,
        vagas: classInfo.vacanciesQty,
      }))) : [],
    },
    {
      dia: 'sex',
      horarios: quantityOfFridayClasses > 0 ? (fridayClasses.map((classInfo) => ({
        horarioInicio: classInfo.startTime,
        horarioTermino: classInfo.endTime,
        vagas: classInfo.vacanciesQty,
      }))) : [],
    },
    {
      dia: 'sab',
      horarios: quantityOfSaturdayClasses > 0 ? (saturdayClasses.map((classInfo) => ({
        horarioInicio: classInfo.startTime,
        horarioTermino: classInfo.endTime,
        vagas: classInfo.vacanciesQty,
      }))) : [],
    },
    {
      dia: 'dom',
      horarios: quantityOfSundayClasses > 0 ? (sundayClasses.map((classInfo) => ({
        horarioInicio: classInfo.startTime,
        horarioTermino: classInfo.endTime,
        vagas: classInfo.vacanciesQty,
      }))) : [],
    },
  ];
}
