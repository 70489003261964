import { Container, Group, AsideContainer } from '../../styles';
import Loader from '../../../../../components/Loader';
import edit from '../../../../../assets/images/icons/edit.svg';
import clock from '../../../../../assets/images/icons/clock.svg';
import useGeneralDataCard from './useGeneralDataCard';
import DescriptionModal from './components/DescriptionModal';

interface GeneralDataCardInterface {
  activityId?: string;
  activityName?: string;
  reloadActivity: () => void;
  generalData?: {
    category: string,
    subcategory: string,
    kindOfClass: string,
    link: string,
    description: string,
    methodology: string,
    orientations: string,
    duration: number,
  };
  isDescriptionUnderAprovement: boolean;
}

export default function GeneralDataCard({
  generalData,
  activityId,
  activityName,
  reloadActivity,
  isDescriptionUnderAprovement,
}: GeneralDataCardInterface) {
  const {
    isLoading,
    setDescriptionModalShow,
    descriptionModalShow,
    getErrorMessageByFieldName,
    description,
    handleDescriptionChange,
    changeDesciption,
  } = useGeneralDataCard({
    activityId,
    activityName,
    reloadActivity,
    generalData,
  });

  return (
    <>
      <Loader isLoading={isLoading} />
      <Container>
        <div className="card-title">
          Dados Gerais
        </div>
        <AsideContainer>
          <Group aside>
            <div className="title">
              Duração
            </div>
            <span>
              {generalData!.duration ? `${generalData!.duration} minutos` : 'Não informado'}
            </span>
          </Group>
          <Group aside>
            <div className="title">
              Categoria
            </div>
            <span>
              {generalData!.category}
            </span>
          </Group>
          <Group aside>
            <div className="title">
              Subcategoria
            </div>
            <span>
              {generalData!.subcategory}
            </span>
          </Group>
        </AsideContainer>
        <AsideContainer>
          {generalData!.kindOfClass === 'Externo' && (
            <Group aside link>
              <div className="title">
                Link da aula
              </div>
              <span>
                {generalData!.link}
              </span>
            </Group>
          )}
          <Group aside>
            <div className="title">
              <div>
                Descrição
              </div>
              {!isDescriptionUnderAprovement && (
                <img
                  src={edit}
                  alt="edit"
                  title="Alterar descrição"
                  onClick={() => setDescriptionModalShow(true)}
                />
              )}
              {isDescriptionUnderAprovement && (
                <img
                  src={clock}
                  alt="wait"
                  title="Alteração aguardando aprovação"
                  className="primaryColor"
                />
              )}
            </div>
            <span>
              {generalData!.description}
            </span>
          </Group>
        </AsideContainer>
        <AsideContainer>
          <Group aside>
            <div className="title">
              Metodologia
            </div>
            <span>
              {generalData!.methodology || 'Não informado'}
            </span>
          </Group>
          <Group aside>
            <div className="title">
              Orientações
            </div>
            <span>
              {generalData!.orientations || 'Não informado'}
            </span>
          </Group>
        </AsideContainer>
      </Container>

      <DescriptionModal
        descriptionModalShow={descriptionModalShow}
        getErrorMessageByFieldName={getErrorMessageByFieldName}
        description={description}
        handleDescriptionChange={handleDescriptionChange}
        setDescriptionModalShow={setDescriptionModalShow}
        changeDesciption={changeDesciption}
      />
    </>
  );
}
