import { Container, Group, AsideContainer } from '../../styles';

interface BankDataInterface {
  bankData: {
    bank?: string;
    agency?: string;
    cc?: string;
    holderName?: string;
    cnpj?: string;
    tipoPix?: string;
    chavePix?: string;
  }
}

export default function BankDataCard({ bankData }: BankDataInterface) {
  return (
    <Container>
      <div className="card-title">
        Dados Bancários
      </div>
      <AsideContainer>
        <Group aside>
          <div className="title">
            Banco
          </div>
          <span>
            {bankData.bank || 'Não informado'}
          </span>
        </Group>
        <Group aside>
          <div className="title">
            Agência
          </div>
          <span>
            {bankData.agency || 'Não informado'}
          </span>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group aside>
          <div className="title">
            Conta Corrente
          </div>
          <span>
            {bankData.cc || 'Não informado'}
          </span>
        </Group>
        <Group aside>
          <div className="title">
            Nome do titular
          </div>
          <span>
            {bankData.holderName || 'Não informado'}
          </span>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group aside>
          <div className="title">
            CNPJ
          </div>
          <span>
            {bankData.cnpj || 'Não informado'}
          </span>
        </Group>
        <Group aside>
          <div className="title">
            PIX
          </div>
          <span>
            {(bankData.tipoPix && bankData.chavePix) && (
              <>
                {bankData.tipoPix}
                {' '}
                -
                {' '}
                {bankData.chavePix}
              </>
            )}
            {(!bankData.tipoPix || !bankData.chavePix) && (
              <>
                Não informado
              </>
            )}
          </span>
        </Group>
      </AsideContainer>
    </Container>
  );
}
