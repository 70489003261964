import { useState } from 'react';
import { ProgramBeingEditedType } from '../../types';

export default function useModals() {
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);

  const [programBeingEdited, setProgramBeingEdited] = useState({} as ProgramBeingEditedType);

  function handleOpenEditModal(programObject: ProgramBeingEditedType) {
    setEditModalShow(true);
    setProgramBeingEdited(programObject);
  }

  return {
    setAddModalShow,
    handleOpenEditModal,
    addModalShow,
    editModalShow,
    setEditModalShow,
    programBeingEditted: programBeingEdited,
  };
}
