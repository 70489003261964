import { Dispatch, SetStateAction } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import MyModal from '../../../../../../components/Modal';
import { ReactCalendarCustomStyle } from '../../../../../../components/ReactCalendarCustomStyle';
import useThemeContext from '../../../../../../contexts/theme';
import { Value } from '../../../../types/CalendarTypes';

interface PeriodSelectionModalI {
  specificPeriod: Value;
  handleSpecificPeriodChange: (value: Value, event: React.MouseEvent<HTMLButtonElement>) => void;
  periodSelectionModalShow: boolean;
  setPeriodSelectionModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function PeriodSelectionModal({
  periodSelectionModalShow,
  setPeriodSelectionModalShow,
  handleSpecificPeriodChange,
  specificPeriod,
}: PeriodSelectionModalI) {
  const { selectedTheme } = useThemeContext();

  return (
    <MyModal
      show={periodSelectionModalShow}
      onClose={() => setPeriodSelectionModalShow(false)}
      title="Selecione o período"
      type="info"
      closeButtonLabel="Fechar"
      centeredBody
      modalBody={(
        <>
          {selectedTheme === 'dark' ? (
            <ReactCalendarCustomStyle>
              <Calendar onChange={handleSpecificPeriodChange} value={specificPeriod} selectRange minDate={new Date()} />
            </ReactCalendarCustomStyle>
          ) : (
            <Calendar onChange={handleSpecificPeriodChange} value={specificPeriod} selectRange minDate={new Date()} />
          )}
        </>
      )}
    />
  );
}
