import { AsideContainer, Container, Group } from '../../styles';

import instagramIcon from '../../../../assets/images/icons/instagram.svg';
import facebookIcon from '../../../../assets/images/icons/facebook.svg';
import siteIcon from '../../../../assets/images/icons/site.svg';
import tiktokIcon from '../../../../assets/images/icons/tiktok.svg';

interface SocialMediaCardInterface {
  socialMediaData: {
    site?: string;
    facebook?: string;
    instagram?: string;
    tiktok?: string;
  }
}

export default function SocialMediaCard({ socialMediaData }: SocialMediaCardInterface) {
  return (
    <Container>
      <div className="card-title">
        Links Externos
      </div>
      <AsideContainer>
        <Group aside link>
          <div className="title">
            <img src={siteIcon} alt="Site" />
          </div>
          <span>
            {socialMediaData.site || 'Não informado'}
          </span>
        </Group>
        <Group aside link>
          <div className="title">
            <img src={facebookIcon} alt="Facebook" />
          </div>
          <span>
            {socialMediaData.facebook || 'Não informado'}
          </span>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group aside link>
          <div className="title">
            <img src={instagramIcon} alt="Instagram" />
          </div>
          <span>
            {socialMediaData.instagram || 'Não informado'}
          </span>
        </Group>
        <Group aside link>
          <div className="title">
            <img src={tiktokIcon} alt="Tiktok" />
          </div>
          <span>
            {socialMediaData.tiktok || 'Não informado'}
          </span>
        </Group>
      </AsideContainer>
    </Container>
  );
}
