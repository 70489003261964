import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import { useQuery } from '../../../../hooks/useQuery';
import { isLocal } from '../../../../pathName';
import authService from '../../../../services/authService';
import userService from '../../../../services/userService';

type UserDataType = {
  nome_fantasia: string;
  cnpj: string;
  googleEmail?: string;
  razao_social: string;
  descricao: string;
  banco_nome: string;
  banco_agencia: string;
  banco_conta: string;
  banco_titular: string;
  banco_doc: string;
  tipo_pix: string;
  pix: string;
  contato_celular: string;
  contato_nome: string;
  contato_email: string;
  responsavel_cargo: string;
  responsavel_nome: string;
  responsavel_telefone: string;
  responsavel_email: string;
  facebook?: string;
  instagram?: string;
  site?: string;
  tiktok?: string;
}

export default function useLoadRegistrationData() {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [doesApiHasError, setDoesApiHasError] = useState(false);
  const [userData, setUserData] = useState<UserDataType>({} as UserDataType);

  const { apiCall } = useApiCall();
  const query = useQuery();
  const googleCode = query.get('code');

  const getUserData = useCallback(async () => {
    await apiCall({
      apiToCall: userService.getUserData,
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar os dados cadastrais (${apiResponse.error})`, {
            toastId: 'getUserDataFail',
          });
          setDoesApiHasError(true);
          setIsLoadLoading(false);
          return;
        }
        setUserData(apiResponse.user);
        setDoesApiHasError(false);
      },
      catchMessage: 'Não foi possível carregar os dados cadastrais',
      catchAction: () => setDoesApiHasError(true)
    });
  }, [apiCall]);

  const linkWithGoogle = useCallback(async (code: string) => {
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;

    await apiCall({
      apiToCall: authService.linkWithGoogle,
      reqBody: JSON.stringify({
        code,
        redirectUri: isLocal ? 'http://localhost:3000' :`${protocol}//${hostname}`,
      }),
      actionAfterResponse: async (apiResponse) => {
        const { success } = apiResponse;
        if (success) {
          toast.success('Sua conta do google foi vinculada com sucesso!', { toastId: 'loginSuccess' });
          await getUserData();
          window.history.replaceState(null, '', window.location.pathname);
          return;
        }
        toast.error('Não foi possível vincular a sua conta do google');
      },
      catchMessage: 'Erro, por favor, tente novamente.',
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
    });
  }, [apiCall, getUserData]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    if (googleCode) {
      linkWithGoogle(googleCode);
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [googleCode, linkWithGoogle]);

  return {
    isLoadLoading,
    doesApiHasError,
    userData,
    getUserData,
  };
}
