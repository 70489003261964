import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import ListHeader from './components/header';
import List from './components/list';
import SearchAtPage from './components/searchAtPage';
import useActivities from './useActivities';

export default function Activities() {
  const {
    isLoading,
    activities,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredActivities,
    handleTryAgain,
    downloadRatings,
    downloadRatingsExcel,
  } = useActivities();

  const hasActivities = activities?.length !== 0 && !!activities;
  const filteredListLength = filteredActivities.length;
  const searchNotFound = filteredListLength === 0 && hasActivities;
  const isListEmpty = !doesListApiHasError && (!isLoading && activities.length === 0);

  return (
    <>
      <Sidebar active="Activities" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {hasActivities && (
          <SearchAtPage
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
          />
        )}

        <ListHeader
          doesListExists={hasActivities}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="atividade"
          pluralLabel="atividades"
          downloadRatingsExcel={downloadRatingsExcel}
        />

        {hasActivities && (
          <List
            filteredList={filteredActivities}
            downloadRatings={downloadRatings}
          />
        )}

        {searchNotFound && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Nenhum resultado foi encontrado para
                {' '}
                <strong>{searchTerm || '" "'}</strong>
              </>
            )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter as atividades.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
            )}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhuma atividade cadastrada, clique no botão
                {' '}
                <strong>Nova Atividade</strong>
                {' '}
                acima para cadastrar sua(s) primeira(s)!
              </>
            )}
          />
        )}
      </Transitions>
    </>
  );
}
