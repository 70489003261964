import { Col, Container, Row } from 'react-bootstrap';

import Sidebar from '../../../components/Sidebar';
import Transitions from '../../../components/Transition';
import PageHeader from '../../../components/PageHeader';
import { Form, ButtonContainer } from '../../../components/Form';
import FormGroup from '../../../components/FormGroup';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Loader from '../../../components/Loader';
import useChangePassowrd from './useChangePassword';

export default function ChangePassword() {
  const {
    handleSubmit,
    getErrorMessageByFieldName,
    currentPassword,
    handleCurrentPasswordChange,
    newPassword,
    handleNewPasswordChange,
    newPasswordConfirmation,
    handleNewPasswordConfirmationChange,
    isFormValid,
    isLoading,
  } = useChangePassowrd();

  return (
    <>
      <Sidebar active="RegistrationData" />
      <Loader isLoading={isLoading} />
      <Transitions>
        <PageHeader
          title="Alterar Senha"
          link="/"
        />
        <Form noValidate autoComplete="new-password">
          <Container>
            <Row xs={1} md={1} lg={1}>
              <Col>
                <FormGroup error={getErrorMessageByFieldName('currentPassword')}>
                  <label htmlFor="currentPassword">Senha atual</label>
                  <Input
                    type="password"
                    placeholder="******"
                    value={currentPassword}
                    onChange={handleCurrentPasswordChange}
                    error={getErrorMessageByFieldName('currentPassword')}
                    autoComplete="new-password"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row xs={1} md={1} lg={2}>
              <Col>
                <FormGroup error={getErrorMessageByFieldName('newPassword')}>
                  <label htmlFor="newPassword">Nova senha</label>
                  <Input
                    type="password"
                    placeholder="******"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    error={getErrorMessageByFieldName('newPassword')}
                    autoComplete="new-password"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup error={getErrorMessageByFieldName('newPasswordConfirmation')}>
                  <label htmlFor="newPasswordConfirmation">Confirme a nova senha</label>
                  <Input
                    type="password"
                    placeholder="******"
                    value={newPasswordConfirmation}
                    onChange={handleNewPasswordConfirmationChange}
                    error={getErrorMessageByFieldName('newPasswordConfirmation')}
                    autoComplete="new-password"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>

          <ButtonContainer>
            <Button type="button" disabled={!isFormValid} onClick={handleSubmit}>
              Alterar Senha
            </Button>
          </ButtonContainer>
        </Form>
      </Transitions>
    </>
  );
}
