import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import calendarPlus from '../../../../assets/images/icons/calendarPlus.svg';
import edit from '../../../../assets/images/icons/edit.svg';
import halfStar from '../../../../assets/images/icons/half-star.svg';
import personChalkboard from '../../../../assets/images/icons/person-chalkboard.svg';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: {
    id: string;
    name: string;
    status: string;
    hasRatings: boolean;
    generalData: {
      category: string;
      subcategory: string;
      kind: 'Aula em vídeo' | 'Aula ao vivo';
    }
  }[],
  downloadRatings: (activityId: string) => Promise<void>;
}

export default function List({
  filteredList,
  downloadRatings,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((activity) => (
          <Col key={activity.id}>
            <OpacityAnimation delay={0.1}>
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{activity.name}</strong>
                    <small>{activity.status}</small>
                  </div>
                  <span>
                      Categoria:
                    {' '}
                    {activity.generalData.category}
                  </span>
                  <span>
                      Subcategoria:
                    {' '}
                    {activity.generalData.subcategory}
                  </span>
                </div>

                <div className="actions">
                  {(activity.generalData.kind !== 'Aula em vídeo') && (
                    <>
                      <Link to={`/activities/${activity.id}/program`}>
                        <img src={calendarPlus} alt="program" title="Adicionar programção específica" />
                      </Link>
                      <Link to={`/activities/${activity.id}/pedagogicalProgram`}>
                        <img src={personChalkboard} alt="program" title="Gerenciar programção pedagógica" />
                      </Link>
                    </>
                  )}

                  <Link to={`/activities/${activity.id}`}>
                    <img src={edit} alt="edit" title="Editar atividade" className="primaryColor" />
                  </Link>

                  {activity.hasRatings && (
                    <button
                      type="button"
                      onClick={() => downloadRatings(activity.id)}
                    >
                      <img src={halfStar} alt="trash" title="Baixar XLSX de avaliações" />
                    </button>
                  )}
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
