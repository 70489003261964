import MyModal from '../../../components/Modal';
import KidsList from './kidsList';
import { Dispatch, SetStateAction } from 'react';
import { ScheduleKidsListBeingShowType } from '../types';

interface KidsAtScheduleModalInterface {
  kidsModalShow: boolean;
  setKidsModalShow: Dispatch<SetStateAction<boolean>>;
  scheduleKidsListBeingShow: ScheduleKidsListBeingShowType;
}

export default function KidsAtScheduleModal({
  kidsModalShow,
  setKidsModalShow,
  scheduleKidsListBeingShow,
}: KidsAtScheduleModalInterface) {
  return (
    <MyModal
      show={kidsModalShow}
      onClose={() => setKidsModalShow(false)}
      title={`Crianças agendadas para a aula ${scheduleKidsListBeingShow?.title}`}
      modalBody={(
        <KidsList
          list={scheduleKidsListBeingShow.list}
        />
      )}
      closeButtonLabel="Fechar"
      size='lg'
    />
  );
}
