import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface ActivitiesServiceInterface {
  reqBody?: ReqBodyType;
  token: string;
  activityId?: string;
  id?: string
}

class ActivitiesService {
  private readonly httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getActivitiesList = async({
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: '/activities',
      token,
    });
  };

  getActivityDetails = async({
    activityId,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: `/activities/${activityId}`,
      token,
    });
  };

  updateActivity = async({
    reqBody,
    token,
    activityId
  }: ActivitiesServiceInterface) => {
    return this.httpClient.put({
      path: `/activities/${activityId}`,
      reqBody,
      token,
    });
  };

  getCategories = async({ token }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: '/categories',
      token,
    });
  };

  createActivity = async({
    reqBody,
    token
  }: ActivitiesServiceInterface) => {
    return this.httpClient.post({
      path: '/activities',
      reqBody,
      token,
      contentType: 'multipart/form-data',
    });
  };

  getActivityRatings = async ({
    token,
    activityId,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: `/activities-ratings/${activityId}`,
      token,
    });
  };

  getAllActivitiesRatings = async ({
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: '/activities-ratings',
      token,
    });
  };

  getActivityPrograms = async ({
    token,
    activityId,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: `/activities-program/${activityId}`,
      token,
    });
  };

  updateActivityProgram = async ({
    id,
    reqBody,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.patch({
      path: `/activities-program/${id}`,
      reqBody,
      token,
    });
  };

  createActivityProgram = async({
    reqBody,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.post({
      path: '/activities-program',
      reqBody,
      token,
    });
  };

  deleteActivityProgram = ({
    id,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.delete({
      path: `/activities-program/${id}`,
      token,
    });
  };

  getActivityPedagogicalPrograms = async ({
    token,
    activityId,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: `/activities-pedagogical-program/${activityId}`,
      token,
    });
  };

  updateActivityPedagogicalProgram = async ({
    id,
    reqBody,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.patch({
      path: `/activities-pedagogical-program/${id}`,
      reqBody,
      token,
    });
  };

  createActivityPedagogicalProgram = async({
    reqBody,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.post({
      path: '/activities-pedagogical-program',
      reqBody,
      token,
    });
  };

  deleteActivityPedagogicalProgram = ({
    id,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.delete({
      path: `/activities-pedagogical-program/${id}`,
      token,
    });
  };
}

export default new ActivitiesService();
