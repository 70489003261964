import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import givenClassesService from '../../../../services/givenClassesService';
import { IJoinClass } from '../../types';

export default function useJoinClass() {
  const [isJoining, setIsJoining] = useState(false);

  const { apiCall } = useApiCall();

  const joinClass = useCallback(async ({
    zoomUrl,
    activityId,
    date,
    startTime,
    endTime,
  }: IJoinClass) => {
    await apiCall({
      apiToCall: givenClassesService.createGivenClass,
      reqBody: JSON.stringify({
        activityId,
        date,
        startTime,
        endTime
      }),
      onStartLoad: () => setIsJoining(true),
      onEndLoad: () => setIsJoining(false),
      actionAfterResponse: (response) => {
        if (!response.canJoinClass && !response.success) {
          toast.warning('Essa aula ainda não está disponível para ser acessada, ou já foi finalizada.');
          return;
        }

        window.open(zoomUrl, '_blank');
      }
    });
  }, [apiCall]);

  return { joinClass, isJoining };
}
