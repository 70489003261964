import { useState } from 'react';
import useLoadPrograms from './functions/useLoadPrograms';
import useModals from './functions/useModals';

export default function usePrograms() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    programs,
    doesListApiHasError,
    dateTimeOptions,
    loadPrograms,
    activityName,
  } = useLoadPrograms({ setIsLoading });

  const {
    setAddModalShow,
    handleOpenEditModal,
    addModalShow,
    editModalShow,
    setEditModalShow,
    programBeingEditted,
  } = useModals();

  function handleTryAgain() {
    loadPrograms();
  }

  return {
    programs,
    doesListApiHasError,
    isLoading,
    setAddModalShow,
    handleOpenEditModal,
    handleTryAgain,
    dateTimeOptions,
    addModalShow,
    loadPrograms,
    setIsLoading,
    editModalShow,
    setEditModalShow,
    programBeingEditted,
    activityName,
  };
}
