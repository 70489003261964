/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Select from 'react-select';

import filter from '../../../../../assets/images/icons/filter.svg';
import { CustomStyle } from '../../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../../components/CustomSelectStyleDarkTheme';
import useThemeContext from '../../../../../contexts/theme';
import { Container, SelectContainer } from './styles';

import { Dispatch, SetStateAction } from 'react';
import { ActivityOptionType } from '../../types';

interface FiltersInterface {
  activitiesList: ActivityOptionType[];
  selectedActivity: ActivityOptionType;
  setSelectedActivity: Dispatch<SetStateAction<ActivityOptionType>>;
  doesListExists: boolean;
}

export default function Filters({
  activitiesList,
  selectedActivity,
  setSelectedActivity,
  doesListExists,
}: FiltersInterface) {
  const { selectedTheme } = useThemeContext();

  return (
    <>
      <Container>
        <header>
          <img src={filter} alt="filter" className="primaryColor" />
          <div>Pesquise através dos filtros abaixo</div>
        </header>

        <div>
          {activitiesList.length > 0 && doesListExists && (
            <SelectContainer>
              <Select
                value={{ value: selectedActivity.value, label: selectedActivity.label === 'Todas' ? 'Filtrar por atividade' : selectedActivity.label }}
                options={activitiesList}
                onChange={(opt) => setSelectedActivity(opt!)}
                placeholder="Filtrar por atividade"
                styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
                // menuPortalTarget={document.body}
                classNamePrefix="react-select"
                className="react-select-container-as-filter"
              />
            </SelectContainer>
          )}
        </div>
      </Container>
    </>
  );
}
