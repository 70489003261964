import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import activitiesService from '../../../../../../../services/activitiesService';
import { CategoriesFromApiResponse, CategoriesType, SubcategoriesType } from '../../../../../types/FormTypes';
import useApiCall from '../../../../../../../hooks/useApiCall';

interface UseLoadFormInterface {
  category: {
    value: string;
    label: string;
  };
}

export default function useLoadForm({
  category,
}: UseLoadFormInterface) {
  const [categories, setCategories] = useState<CategoriesType[]>([]);
  const [subcategories, setSubcategories] = useState<SubcategoriesType[]>([]);
  const [isCategoriesSelectLoading, setIsCategoriesSelectLoading] = useState(true);

  const { apiCall } = useApiCall();

  const navigate = useNavigate();

  const subcategoriesOptions = useMemo(() => {
    const filteredSubcategoriesByCategory = subcategories.filter((sbctg) => (
      sbctg.category === category.label
    ));
    const mappedSubcategories = filteredSubcategoriesByCategory.map((sbctg) => (
      { label: sbctg.subcategory, value: sbctg.id }
    ));

    return mappedSubcategories;
  }, [category.label, subcategories]);

  const companionOptions = [
    { value: true, label: 'Necessário' },
    { value: false, label: 'Não necessário' },
  ];

  const recurrentOptions = [
    { value: true, label: 'Recorrente' },
    { value: false, label: 'Não recorrente' },
  ];

  const levelOptions = [
    { value: 'Iniciante', label: 'Iniciante' },
    { value: 'Intermediário', label: 'Intermediário' },
    { value: 'Avançado', label: 'Avançado' },
    { value: null, label: 'Não especificado' },
  ];

  const getCategories = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getCategories,
      onStartLoad: () => setIsCategoriesSelectLoading(true),
      onEndLoad: () => setIsCategoriesSelectLoading(false),
      actionAfterResponse: (apiResponse) => {
        const categoriesListFromApi: CategoriesFromApiResponse = apiResponse.categories;
        const categoriesOptions = [{ value: 'Atividades Educacionais', label: 'Atividades Educacionais'}, { value: 'Atividades Físicas', label: 'Atividades Físicas'}, { value: 'Entretenimento', label: 'Entretenimento' }];

        const subcategoriesMap = categoriesListFromApi.map((ctg) => ({
          category: ctg.type, subcategory: ctg.name, id: ctg.id
        }));
        const subcategoriesFlatted = subcategoriesMap.flat();

        setCategories(categoriesOptions);
        setSubcategories(subcategoriesFlatted);
      },
      catchMessage: 'Não foi possível abrir o formulário de inclusão de atividade. Por favor, tente novamente',
      catchAction: () => navigate('/activities'),
    });
  }, [apiCall, navigate]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return {
    categories,
    isCategoriesSelectLoading,
    subcategoriesOptions,
    companionOptions,
    levelOptions,
    recurrentOptions,
  };
}
