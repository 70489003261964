import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  downloadRatingsExcel: () => Promise<void>;
}

export default function ListHeader({
  doesListExists,
  hasError,
  filteredListLength,
  singularLabel,
  pluralLabel,
  downloadRatingsExcel,
}: ListHeaderInterface) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.1, type: 'tween', stiffness: 10 }}
    >
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <strong>
            {filteredListLength}
            {' '}
            {filteredListLength === 1 ? singularLabel : pluralLabel}
          </strong>
        )}
        <div className="actionButtons">
          <Link to="/activities/new">
            Nova atividade
          </Link>
          {doesListExists && (
            <>
              <button type="button" onClick={downloadRatingsExcel}>
              Download XLSX (Avaliações)
              </button>
            </>
          )}
        </div>
      </Header>
    </motion.div>
  );
}
