import formatDate from '../../../../../../../utils/formatDate';
import isDateBeforeToday from '../../../../../../../utils/isDateBeforeToday';
import { DatesUnderAprovementType } from '../../../../../types/DetailsTypes';

interface UseDatesInterface {
  dates: string[];
  datesUnderAprovement?: DatesUnderAprovementType;
}

export default function useDates({
  dates,
  datesUnderAprovement,
}: UseDatesInterface) {
  const onlyDatesAfterTodayOrToday = dates?.filter((date) => (
    !isDateBeforeToday(date.split('_')[0])
  ));

  const datesUnderDisableAprovement = datesUnderAprovement?.filter((date) => (
    date.acao === 'desabilitar'
  ))?.map((obj) => obj.data.includes('_') ? `${formatDate(obj.data.split('_')[0])}_${obj.data.split('_')[1]}` : formatDate(obj.data) );

  const datesUnderRehabilitateAprovement = datesUnderAprovement?.filter(
    (date) => date.acao === 'reabilitar',
  )?.map((obj) => obj.data.includes('_') ? `${formatDate(obj.data.split('_')[0])}_${obj.data.split('_')[1]}` : formatDate(obj.data) );

  const onlyNotUnderAprovementDates = onlyDatesAfterTodayOrToday?.filter((date) => (
    !datesUnderRehabilitateAprovement?.includes(formatDate(date))
  ));

  console.log({ onlyNotUnderAprovementDates});

  const formattedDates = onlyNotUnderAprovementDates;
  const formattedUnderRehabilitateAprovementDates = datesUnderRehabilitateAprovement;

  const hasFutureDisabledDates = onlyDatesAfterTodayOrToday?.length > 0;
  const hasUnderDisableAprovementDates = datesUnderDisableAprovement ? datesUnderDisableAprovement?.length > 0 : false;
  const hasDatesToBeRendered = (hasFutureDisabledDates || hasUnderDisableAprovementDates);

  return {
    hasDatesToBeRendered,
    datesUnderDisableAprovement,
    formattedUnderRehabilitateAprovementDates,
    formattedDates,
    hasFutureDisabledDates,
    hasUnderDisableAprovementDates,
  };
}
